<template>
  <div>
    <HeaderTop></HeaderTop>
    <Background>
      <section class="view-size">
        <!-- Pogress Bar -->
        <div>
          <ProgressBar width="90"></ProgressBar>
        </div>

        <!-- Central Block -->
        <div class="container central_block">
          <h2 class="d-flex align-items-center" style="gap: 5px">
            When was the first deposit date?
            <img
              src="../assets/img/question-fill.svg"
              data-toggle="tooltip"
              data-placement="top"
              title="Please enter the date you made your first booking for this trip. This could be when you booked your flight, hotel or any trip activity. If you have not yet booked, please use today's date."
            />
          </h2>

          <form class="mb-5">
            <div class="form-row">
              <div class="col-12">
                <functional-calendar
                  :is-modal="true"
                  :is-date-picker="true"
                  v-model="calendarData"
                  placeholder="Please select a date"
                  :date-format="'mm/dd/yyyy'"
                  :sundayStart="true"
                  @choseDay="noDaysDepo"
                  :disabledDates="['afterToday']"
                  :isAutoCloseable="true"
                >
                </functional-calendar>
                <div class="error" v-if="!$v.depositDate.required">
                  Deposit Date must be selected
                </div>
              </div>
            </div>
          </form>

          <!-- Left Payment Div -->
          <h2 class="d-flex align-items-center" style="gap: 5px">
            Is your trip paid for, or do you have outstanding payments?
            <img
              src="../assets/img/question-fill.svg"
              data-toggle="tooltip"
              data-placement="top"
              title="Please select Yes if you have any significant payments to be made towards your trip. Select No if your trip is paid in full."
            />
          </h2>

          <div class="grid_container_2">
            <div
              class="btn-group btn-group-toggle grid_radioButtons grid_radioButtons_Text2"
              data-toggle="buttons"
            >
              <label
                :class="[
                  'btn btn-secondary radio_btn radio_btn_txt2',
                  radioBtnPicked === false && 'focus active',
                ]"
              >
                <input
                  type="radio"
                  name="options"
                  id="option1"
                  value="false"
                  v-model="radioBtnPicked"
                  @input="btnPicked"
                />
                Yes, trip is paid for
              </label>

              <label
                :class="[
                  'btn btn-secondary radio_btn radio_btn_txt2',
                  radioBtnPicked === true && 'focus active',
                ]"
              >
                <input
                  type="radio"
                  name="options"
                  id="option2"
                  value="true"
                  v-model="radioBtnPicked"
                  @input="btnPicked"
                />
                No, I still owe
              </label>
            </div>
            <div class="error" v-if="!$v.radioBtnPicked.required">
              You must select your deposit options
            </div>
            <br />
          </div>

          <!-- Navigation Panel -->
          <div
            class="d-flex flex-nowrap justify-content-between align-items-center mt-5 mb-3"
          >
            <ButtonMain variant="outline2" @btnClicked="prevStep()">
              Back
            </ButtonMain>

            <ButtonMain :variant="continueBtnStatus" @btnClicked="nextStep()">
              Continue
            </ButtonMain>
          </div>
        </div>
      </section>
    </Background>
  </div>
</template>

<script>
import HeaderTop from "../components/HeaderTop.vue";
import Background from "../components/Background.vue";
import ProgressBar from "../components/ProgressBar.vue";
import ButtonMain from "../components/ButtonMain.vue";
import ButtonRadioText from "../components/ButtonRadioText.vue";
import { FunctionalCalendar } from "vue-functional-calendar";
import { required, requiredIf } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import { mapState } from "vuex";
import { AFFILIATE_ID } from "../app.config";

export default {
  name: "TripDetails6",
  components: {
    HeaderTop,
    Background,
    ProgressBar,
    ButtonMain,
    ButtonRadioText,
    FunctionalCalendar,
  },
  data() {
    const tripDetails = this.$store.state.tripDetails;
    const depositDate = tripDetails.depositDate || null;
    const depositDateSplit = depositDate ? depositDate.split("-") : [];
    const selectedDate = depositDate
      ? `${depositDateSplit[1]}/${depositDateSplit[2]}/${depositDateSplit[0]}`
      : false;
    return {
      radioBtnPicked: "",
      depositDate,
      calendarData: {
        selectedDate,
        dateRange: {
          start: "",
          end: "",
        },
      },
      userActivated: false,
      continueBtnStatus: "",
    };
  },

  validations: {
    depositDate: {
      required: requiredIf(function () {
        return this.userActivated;
      }),
    },
    radioBtnPicked: {
      required: requiredIf(function () {
        return this.userActivated;
      }),
    },
  },
  // created: {
  //   funt() {
  //     var element = document.querySelector(".vfc-popover-container");
  //     console.log(element);
  //     element.classList.add(".extraClass");
  //   },
  // },

  mounted() {
    var element = document.querySelector(".vfc-main-container");
    element.classList.add("extraClass6");

    if (!this.$store.state.tripDetails.depositDate) {
      // console.log("no date selected, cannot go further...")
      this.continueBtnStatus = "disabled";
    } else if (this.$store.state.tripDetails.depositDate) {
      // console.log("date selected, can go further...")
      this.continueBtnStatus = "primary";
    }

    if (
      this.$store.state.tripDetails.hasPaymentsLeft === true ||
      this.$store.state.tripDetails.hasPaymentsLeft === "true" ||
      this.$store.state.tripDetails.hasPaymentsLeft === false ||
      this.$store.state.tripDetails.hasPaymentsLeft === "false"
    ) {
      // console.log("Yes it is in store...")
      if (
        this.$store.state.tripDetails.hasPaymentsLeft === true ||
        this.$store.state.tripDetails.hasPaymentsLeft === "true"
      ) {
        // console.log("true value selected")
        this.radioBtnPicked = true;
      } else if (
        this.$store.state.tripDetails.hasPaymentsLeft === false ||
        this.$store.state.tripDetails.hasPaymentsLeft === "false"
      ) {
        // console.log("false value selected")
        this.radioBtnPicked = false;
      }
    } else if (
      this.$store.state.tripDetails.hasPaymentsLeft !== true ||
      this.$store.state.tripDetails.hasPaymentsLeft !== "true" ||
      this.$store.state.tripDetails.hasPaymentsLeft !== false ||
      this.$store.state.tripDetails.hasPaymentsLeft !== "false"
    ) {
      this.radioBtnPicked = false;
      // console.log("Nopes not in store...")
    }
  },

  methods: {
    ...mapState(["quotes", "quoteNo", "loadingQuotes"]),
    ...mapActions(["getQoutes", "getCardsForCompare", "getQoutesAgain"]),

    noDaysDepo(e) {
      this.depositDate = dayjs(this.calendarData.selectedDate).format(
        "YYYY-MM-DD"
      );
      // console.log("CalenderData(v-model)...", this.calendarData.selectedDate)
      // console.log(this.calendarData.selectedDate)
      // console.log(this.depositDate)
      this.userActivated = true;
      this.continueBtnStatus = "primary";
    },

    datesTyping(e) {
      console.log("dates typing function: ", e.selectedDate);
    },

    nextStep() {
      if (this.calendarData.selectedDate == false) {
        // console.log("can not proceed further, need to select date and show error")
        this.userActivated = true;
      } else if (this.calendarData.selectedDate != false) {
        // console.log("all good to go...")

        var isTrueSet;
        if (this.radioBtnPicked === "true" || this.radioBtnPicked === true) {
          // console.log("true value selected")
          isTrueSet = true;
        } else if (
          this.radioBtnPicked === "false" ||
          this.radioBtnPicked === false
        ) {
          // console.log("false value selected")
          isTrueSet = false;
        }
        this.$store.commit("setTripDetails", {
          hasPaymentsLeft: isTrueSet,
          depositDate: this.depositDate,
          sortBy: "SALES_DESC",
          affiliateReferralCode: AFFILIATE_ID,
        });

        this.$store.commit("incrementStep");
        this.getQoutes().then((res) => {
          this.$router.push({
            path: `/quotes/${this.$store.state.quoteNo.quote}`,
          });
        });

        // if(this.loadingQuotes == false){
        //   console.log("quote no is there...", this.quoteNo)
        //   this.$router.push({ path: `/quotes/:${this.$store.state.quoteNo.quote}` });
        // }

        // this.$router.push({ name: "Quotes1" });
      }
    },

    prevStep() {
      // var isTrueSet = (this.radioBtnPicked === "true");
      var isTrueSet;
      if (this.radioBtnPicked === "true" || this.radioBtnPicked === true) {
        // console.log("true value selected")
        isTrueSet = true;
      } else if (
        this.radioBtnPicked === "false" ||
        this.radioBtnPicked === false
      ) {
        // console.log("false value selected")
        isTrueSet = false;
      }
      this.$store.commit("setTripDetails", {
        hasPaymentsLeft: isTrueSet,
        depositDate: this.depositDate,
        sortBy: "SALES_DESC",
      });

      this.$router.push({ name: "TripDetails5" });
    },

    btnPicked() {
      // this.userActivated = true
    },
  },
};

// :isDisabled="!(this.radioBtnPicked !==null && this.depositDate)"
</script>

<style>
.view-size {
  width: 100%;
  min-height: 100vh;
  background-color: rgba(234, 234, 252, 1);
  padding: 1% 0;
}
.central_block {
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 24px;
  padding: 3%; /*this property was commented: Reason?*/
  margin: 50px auto;
}

@media only screen and (max-width: 470px) {
  /* .vfc-main-container.vfc-modal {
    width: 92%;
  } */
}

@media only screen and (max-width: 600px) {
  .central_block {
    max-width: 92%;
  }
}

@media only screen and (min-width: 600px) {
  /* .vfc-main-container.vfc-modal {
    width: 80%;
  } */
}

@media only screen and (min-width: 600px) {
  .central_block {
    max-width: 65%;
  }

  /* .vfc-main-container.vfc-modal {
    width: 80%;
  } */
}

@media only screen and (min-width: 767px) {
  /* .vfc-main-container.vfc-modal {
    width: 70%;
  } */
}

@media only screen and (min-width: 992px) {
  /* .vfc-main-container.vfc-modal {
    width: 44%;
  } */
}

.central_block h2 {
  font-family: "Inter", sans-serif;
  /* font-size: 24px; */
  font-weight: 600;
  line-height: 30px;
  color: rgba(26, 26, 81, 1);
  padding-top: 2%;
  padding-bottom: 3%;
}

.manual_select_class {
  border: 2px solid rgba(26, 26, 81, 0.07);
  border-radius: 10px;
  padding: 15px 15px;
  height: 60px;

  font-family: "Inter", sans-serif;
  /* font-size: 18px; */
  font-weight: 400;
  line-height: 24px;
  color: rgba(26, 26, 81, 1);

  background: rgba(250, 250, 255, 1) url("../assets/img/search_icon.svg") right
    0.75rem center/30px 18px no-repeat;
}

.manual_select_class:focus,
.manual_select_class:active,
.manual_select_class:hover {
  box-shadow: none !important;
  border: 2px solid rgba(26, 26, 81, 0.07);
}

.manual_input_class_3 {
  border: 2px solid rgba(26, 26, 81, 0.07);
  border-radius: 10px;
  padding: 10px 10px 10px 15px;
  height: 55px;

  font-family: "Inter", sans-serif;
  /* font-size: 18px; */
  font-weight: 400;
  line-height: 24px;
  color: rgba(26, 26, 81, 1);

  background: rgba(250, 250, 255, 1) url("../assets/img/calender_icon.svg")
    right 0.75rem center/30px 18px no-repeat;
}

.manual_input_class_3:focus,
.manual_input_class_3:active,
.manual_input_class_3:hover {
  box-shadow: none !important;
  border: 2px solid rgba(26, 26, 81, 0.07);
  background-color: rgba(250, 250, 255, 1) !important;
}

.label_age {
  font-family: "Inter", sans-serif !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 18px !important;
  color: rgba(26, 26, 81, 1);
  margin-bottom: 0.5rem !important;
}

.manual_input_class {
  border: 2px solid rgba(26, 26, 81, 0.07);
  border-radius: 10px;
  padding: 15px 15px;
  height: 55px;

  font-family: "Inter", sans-serif;
  /* font-size: 18px; */
  font-weight: 400;
  line-height: 24px;
  color: rgba(26, 26, 81, 1);

  background: rgba(250, 250, 255, 1) url("../assets/img/search_icon.svg") right
    0.75rem center/30px 18px no-repeat;
}

.manual_input_class:focus,
.manual_input_class:active,
.manual_input_class:hover {
  box-shadow: none !important;
  border: 2px solid rgba(26, 26, 81, 0.07);
  background-color: rgba(250, 250, 255, 1) !important;
}

.no_icon {
  background: rgba(250, 250, 255, 1) !important;
}

.grid_radioButtons {
  display: grid;
  /* grid-template-columns: repeat(5, auto); */
  /* grid-row-gap: 7%; */
}

.radio_btn {
  font-family: "BasierCircle-Regular", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 60px;
  width: 55px;
  height: 55px;
  color: rgba(26, 26, 81, 1);
  background-color: #fafaff;
  border: 2px solid rgba(26, 26, 81, 0.07);
  border-radius: 15px 15px 15px 15px !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.radio_btn:hover {
  color: #fff;
  background-color: rgba(110, 110, 223, 1);
  border-color: rgba(110, 110, 223, 1);
  box-shadow: none;
}
.radio_btn:active {
  color: #fff;
  background-color: rgba(110, 110, 223, 1);
  border-color: rgba(110, 110, 223, 1);
  box-shadow: none;
}
.radio_btn:not(:disabled):not(.disabled).active:focus,
.radio_btn:not(:disabled):not(.disabled):active:focus,
.show > .radio_btn.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.radio_btn:not(:disabled):not(.disabled).active,
.radio_btn:not(:disabled):not(.disabled):active,
.show > .radio_btn.dropdown-toggle {
  color: #fff;
  background-color: rgba(110, 110, 223, 1);
  border-color: rgba(110, 110, 223, 1);
  box-shadow: none;
}

@media only screen and (max-width: 600px) {
  .central_block {
    padding: 4% 6%;
  }
  .central_block h2 {
    font-size: 18px;
  }
  .form-group {
    margin-bottom: 2rem;
  }
  .age_div {
    margin-bottom: 1.5rem;
  }
  .form-group label {
    font-size: 16px;
  }
  .grid_container {
    width: 100%;
    height: 230px;
  }
  .grid_radioButtons {
    grid-template-columns: repeat(4, auto);
    grid-row-gap: 10%;
    grid-column-gap: 5%;
  }
  .radio_btn {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
  }
  .grid_radioButtons_Text {
    grid-template-columns: repeat(2, 135px) !important;
  }
  .grid_radioButtons_Text2 {
    grid-template-columns: repeat(1, 250px) !important;
    grid-row-gap: 10% !important;
  }
  .radio_btn_txt2 {
    max-width: 210px;
    width: 210px !important;
  }
  .manual_input_class {
    font-size: 16px;
  }
  .manual_input_class_3 {
    font-size: 16px;
  }
  .manual_select_class {
    font-size: 16px;
  }
}

@media only screen and (min-width: 600px) {
  .central_block {
    padding: 4% 4%;
  }
  .central_block h2 {
    font-size: 24px;
  }
  .form-group {
    margin-bottom: 3.5rem;
  }
  .age_div {
    margin-bottom: 2rem;
  }
  .form-group label {
    font-size: 24px;
  }
  .grid_container {
    width: 75%;
    height: 180px;
  }
  .grid_radioButtons {
    grid-template-columns: repeat(5, 50px);
    grid-row-gap: 25%;
    grid-column-gap: 7%;
  }
  .radio_btn {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
  }
  .grid_radioButtons_Text {
    grid-template-columns: repeat(2, 146px) !important;
  }
  .grid_radioButtons_Text2 {
    grid-template-columns: repeat(1, 250px) !important;
    grid-row-gap: 10% !important;
  }
  .radio_btn_txt2 {
    max-width: 210px;
    width: 210px !important;
  }
  .manual_input_class {
    font-size: 18px;
  }
  .manual_input_class_3 {
    font-size: 18px;
  }
  .manual_select_class {
    font-size: 18px;
  }
}

@media only screen and (min-width: 768px) {
  .grid_radioButtons_Text2 {
    grid-template-columns: repeat(1, 250px) !important;
    grid-row-gap: 10% !important;
  }
  .radio_btn_txt2 {
    max-width: 230px;
    width: 230px !important;
  }
}

@media only screen and (min-width: 992px) {
  .grid_radioButtons_Text2 {
    grid-template-columns: repeat(2, 250px) !important;
    grid-row-gap: 0% !important;
  }
  .radio_btn_txt2 {
    max-width: 230px;
    width: 230px !important;
  }
}

@media only screen and (min-width: 1200px) {
}

.vc-popover-content-wrapper {
  width: 99%;
}
.vc-popover-content {
  width: 60%;
}
.vc-arrows-container {
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 700;
}
.vc-title {
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
}
.vc-weekday[data-v-74ad501d] {
  text-align: center;
  color: rgba(51, 204, 155, 1);
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 14px;
  padding-top: 4px;
  padding-bottom: 8px;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  margin-bottom: 10px;
}
.vc-highlight {
  background-color: rgba(110, 110, 223, 1);
  border-radius: 8px;
  /* width: 28px;
        height: 28px; */
}

.vfc-single-input {
  width: 100%;
  text-align: left;
}

/* .vfc-popover-container {
  width: 100%
} */

/* .vfc-main-container.vfc-modal {
  width: 42%;
} */

/* .extraClass6 {
  width: 42% ;
} */

@media only screen and (max-width: 500px) {
  .extraClass6 {
    width: 97% !important;
  }
}
@media only screen and (min-width: 500px) {
  .extraClass6 {
    width: 72% !important;
  }
}
@media only screen and (min-width: 950px) {
  .extraClass6 {
    width: 57% !important;
  }
}
@media only screen and (min-width: 992px) {
  .extraClass6 {
    width: 45% !important;
  }
}
@media only screen and (min-width: 1500px) {
  .extraClass6 {
    width: 35% !important;
  }
}
@media only screen and (min-width: 2000px) {
  .extraClass6 {
    width: 30% !important;
  }
}
@media only screen and (min-width: 2300px) {
  .extraClass6 {
    width: 27% !important;
  }
}
@media only screen and (min-width: 2500px) {
  .extraClass6 {
    width: 23% !important;
  }
}
@media only screen and (min-width: 3000px) {
  .extraClass6 {
    width: 20% !important;
  }
}
@media only screen and (min-width: 3500px) {
  .extraClass6 {
    width: 17% !important;
  }
}
@media only screen and (min-width: 4000px) {
  .extraClass6 {
    width: 15% !important;
  }
}
</style>
