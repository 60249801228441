<template>
  <div>
    <HeaderTop></HeaderTop>
    <Background>
      <div class="container">
        <!-- Back Button -->
        <div class="d-flex justify-content-start align-items-center w-100 my-4">
          <router-link :to="{ name: 'Quotes1' }">
            <ButtonMain variant="back">
              <img src="../assets/img/SVGs/back_to_search_icon_btn.svg" />
            </ButtonMain>
          </router-link>
        </div>
        <!-- Subheader -->
        <Subheader></Subheader>

        <!-- Main Body -->
        <div class="row flex-nowrap justify-content-center">
          <!-- Right Side : Offer Card -->
          <div class="col-10">
            <!-- <div v-if="this.$store.state.quotes">
                <div 
                  v-for="(card, index) in this.$store.state.quotes.data.quotes.productQuotes" 
                  :key="index"
                  v-if="card.productCode.includes(cardSelectedPolicy)"
                  >
                    <ExpandedCard v-bind="card"></ExpandedCard>
                </div>
            </div> -->
          </div>
        </div>
      </div>
    </Background>
  </div>
</template>

<script>
import HeaderTop from '../components/HeaderTop.vue';
import Background from '../components/Background.vue';
import ButtonMain from '../components/ButtonMain.vue';
import Subheader from '../components/Subheader.vue';
import FilterSearch from '../components/FilterSearch.vue';
import OfferCard from '../components/OfferCard.vue';
import Container from '../components/Container.vue';
import ExpandedCard from '../components/ExpandedCard.vue';

export default {
  components: {
    HeaderTop,
    Background,
    Subheader,
    ButtonMain,
    FilterSearch,
    OfferCard,
    ExpandedCard,
    Container,
  },
  data() {
    return {
      containerStyle: {
        margin: '20px auto',
        borderRadius: '20px',
        padding: '1.5rem',
      },
      cardSelectedPolicy : [this.$store.state.selectedCardID],
            
    };
  },
};
</script>

<style scoped>
.subheading-title h1 {
  font-family: 'BasierCircle-Regular', sans-serif;
  font-weight: 600;
  color: rgba(26, 26, 81, 1);
}

@font-face {
  font-family: 'BasierCircle-Regular';
  src: url('../assets/fonts/basiercircle-regular.otf') format('woff2'),
    url('../assets/fonts/BasierCircle-Regular.ttf') format('woff'),
    url('../assets/fonts/BasierCircle-Regular.woff') format('otf'),
    url('../assets/fonts/BasierCircle-Regular.woff2') format('ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "BasierCircle-Regular";
  src: url("../assets/fonts/BasierCircle-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

.checkboxes-left {
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 20px;
  /* max-width: 1170px; */
  margin-top: 0px;
  height: 850px;
}
.left-side-heading {
  font-family: 'BasierCircle-Regular', sans-serif;
  color: rgba(26, 26, 81, 1);
}
hr {
  border: 2px solid rgba(250, 250, 255, 1);
  width: 100%;
}
.custom-checkbox .custom-control-label::before {
  border-radius: 1.25rem;
}
.checkbox-box {
  padding-top: 10px;
  padding-bottom: 10px;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}
.custom_input:checked {
  color: #fff;
  border-color: rgba(51, 204, 155, 1);
  background-color: rgba(51, 204, 155, 1);
  box-shadow: none !important;
}
.custom_input:focus {
  color: #fff;
  border-color: rgba(51, 204, 155, 1);
  background-color: rgba(51, 204, 155, 1);
  box-shadow: none !important;
}
.custom_label {
  font-family: 'BasierCircle-Regular', sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: rgba(26, 26, 81, 1);
}
.custom_label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: '';
  background-color: #fff;
  border: rgba(51, 204, 155, 1) solid 1px;
}
.custom-control-input:checked ~ .custom_label::before {
  color: #fff;
  border-color: rgba(51, 204, 155, 1);
  background-color: rgba(51, 204, 155, 1);
  box-shadow: none;
}
.custom_label::after {
  background-image: none !important;
}

.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::before {
  border-color: #00ff34;
  background-color: #fff;
}
.left-side-heading h1 {
  font-family: 'BasierCircle-Regular', sans-serif;
  font-weight: 600;
  color: rgba(26, 26, 81, 1);
}
