<template>
  <section class="container">
    <div class="progress_bar_div">
      <div class="progress">
        <div
          class="progress-bar"
          role="progressbar"
          v-bind:style="{ width: prgBar + '%' }"
          aria-valuenow="25"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ['width'],
  data() {
    return {
      prgBar: this.width,
    };
  },
};
</script>

<style scoped>
.progress_bar_div {
  margin: 8% auto;
}

@media only screen and (max-width: 600px) {
  .progress_bar_div {
    max-width: 80%;
  }
}

@media only screen and (min-width: 600px) {
  .progress_bar_div {
    max-width: 42%;
  }
}

.progress {
  display: -ms-flexbox;
  display: flex;
  height: 5px; /* changing height of progress-bar*/
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: rgba(110, 110, 223, 0.1);
  border-radius: 0.25rem;
}
.progress-bar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #6e6edf; /* changing color of progressed bar */
  transition: width 0.6s ease;
}
</style>