<template>
  <Container
    :margin="containerStyle.margin"
    :borderRadius="containerStyle.borderRadius"
    :padding="containerStyle.padding"
  >
    <div v-if="$store.state.loadingQuotes">
      <div class="loading_title d-flex align-items-center my-4">
        <h2>Loading...</h2>
      </div>
    </div>

    <div v-else class="subheading-title mt-2 mb-2 d-flex flex-wrap">
      <h1>Your search has {{ total }} policies to choose from</h1>
      <form class="ml-auto" v-if="quotesStatus">
        <label for="search-no" class="search-no-title">Search No.</label>
        <div class="d-flex justify-content-end">
          <div class="d-flex flex-row" style="width: 46%">
            <input
              type="text"
              class="form-control search-quoteno-form ml-auto"
              id="search-no"
              v-model="search_quote_no"
            />
          </div>
          <button class="btn btn-search-arrow" type="button" @click.prevent="sendNo">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
                fill="rgba(110,110,223,1)"
              />
            </svg>
          </button>
        </div>
      </form>
     
    </div>

    <!-- Search Criteria Box -->
    <SearchCriteria></SearchCriteria>

    <!--======================= Modal: Email ===================-->
    <div
      class="modal fade"
      id="searchEmail"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Email Search</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <!--===== Modal Body of Email ===== -->
          <div class="modal-body">
            <form>
              <div class="form-group my-4">
                <label for="exampleName" class="emailLabel">Your Name</label>
                <input
                  type="email"
                  class="form-control"
                  id="exampleName"
                  placeholder=""
                  v-model.trim="formData.name"
                />
              </div>
              <div class="form-group my-4">
                <label for="exampleInputEmail1" class="emailLabel">
                  Your Email Address
                </label>
                <input
                  type="email"
                  class="form-control"
                  id="exampleInputEmail1"
                  placeholder=""
                  v-model.trim="formData.email[0]"
                />
              </div>
              <div class="form-group my-4" v-for="(no, index) in no_of_email" :key="index">
                <label for="exampleInputEmail1" class="emailLabel">
                  Your Email Address #{{ no+1 }}
                </label>
                <input
                  type="email"
                  class="form-control"
                  id="exampleInputEmail1"
                  placeholder=""
                  v-model.trim="formData.email[no]"
                />
              </div>
              <button @click.prevent="addEmail" class="addAnotherEmail">Add another email address</button>
              <!-- <div>
                <label for="exampleFormControlTextarea1">Message</label>
                <textarea
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  v-model.trim="formData.message"
                />
              </div> -->
            </form>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn emailBtn"
              @click="submitSearchMail"
            >
              Email my Search
            </button>
            <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
          </div>
        </div>
      </div>
    </div>
  </Container>
</template>

<script>
import Container from "../components/Container.vue";
import ButtonMain from "../components/ButtonMain.vue";
import { Calendar, DatePicker } from "v-calendar";
import { FunctionalCalendar } from "vue-functional-calendar";
import vSelect from "vue-select";
import SearchCriteria from "../components/SearchCriteria.vue";
import axios from "axios";
import { mapState } from "vuex";
import { mapActions } from 'vuex';


export default {
  name: "Subheader",
  props: ["total"],
  components: {
    Container,
    ButtonMain,
    Calendar,
    DatePicker,
    FunctionalCalendar,
    vSelect,
    SearchCriteria,
  },

  data() {
    return {
      containerStyle: {
        margin: "20px auto",
        borderRadius: "20px",
        padding: "1.0rem 1.5rem",
      },
      search_quote_no: this.quoteNo,
      urlLink: window.location.href,
      formData: {
        name: "",
        email: [],
        message: `Use this link to return to your quote search: ${this.search_quote_no}`,
        link: `${this.urlLink}`,
      },
      no_of_email: 0,
    };
  },

  mounted(){
    if (this.quotes.length != 0) {
        if(this.quotes.data.createSingleTripQuote){
          this.search_quote_no = this.quotes.data.createSingleTripQuote.quoteNumber;
          // this.formData.message = `Here is your Search Result No: ${this.search_quote_no}`;
          return true;
        }
        if(this.quotes.data.quote)
          this.search_quote_no = this.quotes.data.quote.quoteNumber;
          // this.formData.message = `Here is your Search Result No: ${this.search_quote_no}`;
        return true;
    }
    else {
      this.search_quote_no = this.quoteNo
    }
  },

  watch: {
    quoteNo(){
      this.search_quote_no = this.quoteNo.quote
      return 
    }
  },

  computed: {
    ...mapState(["quotes", "quoteNo"]),
    
    quotesStatus() {
      if (this.quotes.length != 0) {
        if(this.quotes.data.createSingleTripQuote){
          // this.search_quote_no = this.quotes.data.createSingleTripQuote.quoteNumber;
          this.formData.message = `Here is your Search Result No: ${this.search_quote_no}`;
          this.formData.link = `${this.urlLink}`;
          return true;
        }
        if(this.quotes.data.quote)
          // this.search_quote_no = this.quotes.data.quote.quoteNumber;
          this.formData.message = `Here is your Search Result No: ${this.search_quote_no}`;
          this.formData.link = `${this.urlLink}`;
        return true;
      }
      return false;
    },

  },

  methods: {
    ...mapActions(["getQoutesAgain"]),

    // function to send email
    async submitSearchMail() {
      const req = await axios({
        url: "/api/mail-results",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: this.formData,
      });

      alert("Email sent");
    },

    // function to generate quotes based on quote-no
    sendNo() {
      //updating quote-no entered by user
      this.$store.commit('setQuoteNo', 
        {quote:`${this.search_quote_no}`}
      );
      //fetching data and changing url based on entered quote-no
      this.getQoutesAgain().then(res => {
        this.$router.push({ path: `/quotes/${this.$store.state.quoteNo.quote}` });
      })
    },

    // function to increment no of emails that can be sent to user
    addEmail() {
      this.no_of_email += 1
    },
  },
};
</script>

<style >
.subheading-title h1 {
  font-family: "BasierCircle-Regular", sans-serif;
  /* font-size: 30px; */
  font-weight: 600;
  color: rgba(26, 26, 81, 1);
}
#searchParameters {
  /* border: 1px solid rgba(110, 110, 223, 1); */
  border-radius: 25px;
  padding: 5px 20px;
}
#searchParameters h2 {
  font-family: "BasierCircle-Regular", sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: rgba(26, 26, 81, 1);
}
#searchParameters h4 {
  font-family: "BasierCircle-Regular", sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: rgba(26, 26, 81, 1);
}
#searchParameters p {
  font-family: "BasierCircle-Regular", sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: rgba(26, 26, 81, 1);
}
#searchParameters hr {
  border: 1px solid grey;
  height: 48px;
}
#searchParameters button {
  background-color: rgba(110, 110, 223, 1);
  border-radius: 10px;
  border: none;
  padding: 10px 25px;
  cursor: pointer;

  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: rgba(255, 255, 255, 1);
  width: 15%;
}

#searchParameters button:hover,
#searchParameters button:active,
#searchParameters button:focus {
  opacity: 0.8;
}

.modal-body h2 {
  font-family: "BasierCircle-Regular", sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: rgba(26, 26, 81, 1);
}

.manual_select_class {
  border: 2px solid rgba(26, 26, 81, 0.07);
  border-radius: 10px;
  padding: 15px 15px;
  height: 60px;

  font-family: "Inter", sans-serif;
  /* font-size: 18px; */
  font-weight: 400;
  line-height: 24px;
  color: rgba(26, 26, 81, 1);

  background: rgba(250, 250, 255, 1) url("../assets/img/search_icon.svg") right
    0.75rem center/30px 18px no-repeat;
}

.manual_select_class:focus,
.manual_select_class:active,
.manual_select_class:hover {
  box-shadow: none !important;
  border: 2px solid rgba(26, 26, 81, 0.07);
}

.vfc-single-input,
.vfc-multiple-input input {
  border: 2px solid rgba(26, 26, 81, 0.07) !important;
  background-color: rgba(250, 250, 255, 1);
  border-radius: 10px !important;
  padding: 15px 15px !important;
  height: 60px;

  font-family: "Inter", sans-serif;
  /* font-size: 18px; */
  font-weight: 400;
  line-height: 24px;
  color: rgba(26, 26, 81, 1);
}

#searchEmail .emailLabel {
  font-family: "BasierCircle-Regular", sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.search-no-title {
  font-family: "BasierCircle-Regular", sans-serif;
  font-size: 14px !important;
  font-weight: 700;
  color: rgba(26, 26, 81, 1);
  width: 100%;
  text-align: end;
}

.btn-search-arrow {
  border: 1px solid rgba(110, 110, 223, 1);
}
.btn-search-arrow:hover,
.btn-search-arrow:focus .btn-search-arrow:active {
  border: 1px solid rgba(110, 110, 223, 1);
  opacity: 0.65;
}

.search-quoteno-form {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

@media only screen and (max-width: 600px) {
  .subheading-title h1 {
    font-size: 26px;
  }
}
@media only screen and (min-width: 600px) {
  .subheading-title h1 {
    font-size: 28px;
  }
}
@media only screen and (min-width: 992px) {
  .subheading-title h1 {
    font-size: 35px;
  }
}
.loading_title {
  font-family: "BasierCircle-Regular", sans-serif;
  font-size: 20px;
  font-weight: 700;
  color: rgba(26, 26, 81, 1);
  width: 100%;
  text-align: start;
}
.emailBtn{
    background-color: #fff;
    border-radius: 25px;
    border: 1px solid rgba(110, 110, 223, 1);
    padding: 10px 25px;
    cursor: pointer;
    font-family: 'DM Sans', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: rgba(110, 110, 223, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 100%; */
}
.emailBtn:hover, .emailBtn:focus, .emailBtn:active {
  opacity: 0.8;
  background-color: rgba(110, 110, 223, 1);
  color: #fff;
}

.addAnotherEmail {
  font-family: "BasierCircle-Regular", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: rgba(26, 26, 81, 1);
  width: 100%;
  background: none;
  border: none;
}
.addAnotherEmail:hover {
  opacity: 0.8;
  text-decoration: underline;
}
</style>