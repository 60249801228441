<template>
  <div>
    <header class="header container position-relative">
      <a href="https://covertrip.com/">
        <img src="../assets/img/main_logo.svg" />
        <p class="subtext-header">powered by Squaremouth</p>
      </a>
      <div class="contact-info d-none d-md-block">
        <span>1-800-240-0369</span>
        <span>8AM to 10PM ET Daily</span>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  name: "HeaderTop",
};
</script>

<style>
.header {
  position: relative;
  min-height: 80px;
  background-color: #fff;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: 10;
}
.subtext-header {
  font-family: "BasierCircle-Regular", sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: rgb(134, 134, 134);
  margin-bottom: 0;
  text-align: right;
}
.contact-info {
  position: absolute;
  top: 21%;
  right: 7%;
}
.contact-info span {
  font-family: "BasierCircle-Regular", sans-serif;
  color: rgba(26, 26, 81, 1);
  display: block;
}
.contact-info span:first-child {
  font-size: 17px;
  font-weight: 700;
}
.contact-info span:last-child {
  font-size: 13px;
  font-weight: 400;
}
</style>
