<template>
  <div>
    <router-view />
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'App',
  mounted() {
    this.getCountries();
    this.getFilters();
    this.getBenefits();
    this.getSports();
  },
  methods: {
    ...mapActions(['getCountries', 'getFilters', 'getBenefits', 'getSports']),
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-family: 'DM Sans', sans-serif;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

a {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
.vs__search,
.vs__search:focus {
  margin-top: 0px !important;
}
</style>
