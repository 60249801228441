<template>
  <div>
    <HeaderTop></HeaderTop>
    <section class="view-size">
      <div>
        <ProgressBar width="15"></ProgressBar>
      </div>
      <div class="central_block">
        <div class="container">
          <h2 class="d-flex align-items-center" style="gap: 5px">
            Which country are you traveling to?
            <img
              src="../assets/img/question-fill.svg"
              data-toggle="tooltip"
              data-placement="top"
              title="Please enter your destination country. If you are traveling multiple destinations, please just enter one."
            />
          </h2>

          <form class="my-2">
            <!-- <small ref="countryErr"></small> use it for custom validation -->
            <v-select
              v-model="countryCode"
              :options="countryList"
              :filter="fuseSearch"
              @input="setName($event)"
            ></v-select>
            <div class="error" v-if="!$v.countryCode.required">
              Please select destination country
            </div>
          </form>

          <div
            class="d-flex flex-nowrap justify-content-end align-items-center mt-5 mb-3"
          >
            <ButtonMain
              id="continue"
              :variant="continueBtnStatus"
              @btnClicked="nextStep()"
              :isDisabled="false"
              :style="{ color: 'red' }"
            >
              Continue
            </ButtonMain>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import HeaderTop from "../components/HeaderTop.vue";
import ProgressBar from "../components/ProgressBar.vue";
import ButtonMain from "../components/ButtonMain.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { mapGetters } from "vuex";
import {
  required,
  minLength,
  between,
  requiredUnless,
  requiredIf,
} from "vuelidate/lib/validators";

export default {
  components: {
    HeaderTop,
    ProgressBar,
    ButtonMain,
    vSelect,
  },

  validations: {
    countryCode: {
      required: requiredIf(function () {
        return this.userActivated;
      }),
    },
  },

  data() {
    const tripDetails = this.$store.state.tripDetails;

    return {
      countryCode: tripDetails.destinationCountryCode || null,
      continueBtnStatus: "",
      userActivated: false, //change it to false if error should not be displayed on first load - now changed to false
    };
  },
  computed: {
    ...mapGetters({
      countryList: "mappedCountries",
    }),

    // for removing duplicate values in country list array
    // unqCtList(){
    //   const result = Array.from(new Set(this.countryList.map(s => s.code))).map(code => {
    //     return {
    //       code:code,
    //       label:this.countryList.find(s => s.code === code).label
    //     }
    //   })
    //   return result
    //   // return [...new Set(this.countryList)]
    // },

    //:reduce="(country) => country.code"
  },
  mounted() {
    const countryCode = this.$route.query.country;

    if (countryCode) {
      this.countryCode = countryCode.toUpperCase();
    }

    //function to overcome multiple countries having same code
    if (this.countryCode) {
      // console.log("now run function...")
      const countryArr = [];
      this.countryList.forEach((item) => {
        if (item.code === this.countryCode) countryArr.push(item);
      });
      if (countryArr.length == 1) {
        // console.log("1 item only")
        this.countryCode = countryArr[0];
      }
      if (countryArr.length > 1) {
        // console.log("multiple items")
        this.countryCode = countryArr[2];
      }
      // console.log(countryArr)
    }
    this.continueBtnStatus = this.$store.state.tripDetails
      .destinationCountryCode
      ? "primary"
      : "disabled";
  },

  methods: {
    nextStep() {
      this.userActivated = true; //this will activate validation function irrespective of user input

      // this function can act as custom validation block - currently commented out
      // this.$refs.countryErr.innerText = ''
      if (this.countryCode == null) {
        // this.$refs.countryErr.innerText = 'Please select country';
        return;
      } else if (this.countryCode != null) {
        this.$store.commit("setTripDetails", {
          destinationCountryCode: this.countryCode.code,
        });

        this.$store.commit("incrementStep");

        this.$router.push({ name: "TripDetails2" });
      }
    },

    fuseSearch(options, search) {
      return options.filter((state) =>
        state.label.toLowerCase().startsWith(search.toLowerCase())
      );
    },

    setName(value) {
      // this.countryCode = value
      // console.log(value)
      // this.$v.countryCode.$touch()
      this.userActivated = true;
      if (value) {
        this.continueBtnStatus = "primary";
      } else if (value == null) {
        this.continueBtnStatus = "disabled";
      }
    },
  },
};

$(document).ready(function () {
  $("body").tooltip({ selector: "[data-toggle=tooltip]" });
});

// :isDisabled="!(countryCode && countryCode.trim())"
</script>

<style lang="scss">
.view-size {
  width: 100%;
  min-height: 100vh;
  background-color: rgba(234, 234, 252, 1);
  padding: 1% 0;
}
.central_block {
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 24px;
  padding: 3%; /*this property was commented: Reason?*/
  margin: 50px auto;
}

@media only screen and (max-width: 600px) {
  .central_block {
    max-width: 92%;
  }
}

@media only screen and (min-width: 600px) {
  .central_block {
    max-width: 65%;
  }
}

.central_block h2 {
  font-family: "Inter", sans-serif;
  /* font-size: 24px; */
  font-weight: 600;
  line-height: 30px;
  color: rgba(26, 26, 81, 1);
  padding-top: 2%;
  padding-bottom: 3%;
  cursor: pointer;
}

.vs__dropdown-toggle {
  background: rgb(250, 250, 255) !important;
  border: 2px solid rgba(26, 26, 81, 0.07) !important;
  border-radius: 10px !important;
  padding: 15px 15px !important;
  height: 60px !important;
}
.vs__selected {
  margin: 0 !important;
  padding-bottom: 2px;
}
.vs__actions {
  display: flex;
  align-items: center;
  padding: 0px 6px 0 3px;
  column-gap: 10px;
  background: rgba(250, 250, 255, 1) url("../assets/img/search_icon.svg") right
    0.75rem center/30px 18px no-repeat;
  width: 75px;
}
.vs__clear {
  cursor: pointer;
  margin-right: 8px;
  margin-bottom: 5px;
}
.vs__open-indicator {
  display: none;
}

.manual_select_class {
  border: 2px solid rgba(26, 26, 81, 0.07);
  border-radius: 10px;
  padding: 15px 15px;
  height: 60px;

  font-family: "Inter", sans-serif;
  /* font-size: 18px; */
  font-weight: 400;
  line-height: 24px;
  color: rgba(26, 26, 81, 1);

  background: rgba(250, 250, 255, 1) url("../assets/img/search_icon.svg") right
    0.75rem center/30px 18px no-repeat;
}

.manual_select_class:focus,
.manual_select_class:active,
.manual_select_class:hover {
  box-shadow: none !important;
  border: 2px solid rgba(26, 26, 81, 0.07);
}

@media only screen and (max-width: 600px) {
  .vs__actions {
    width: 12px;
  }
  .central_block {
    padding: 4% 6%;
  }
  .central_block h2 {
    font-size: 18px;
  }
  .form-group {
    margin-bottom: 2rem;
  }
  .age_div {
    margin-bottom: 1.5rem;
  }
  .form-group label {
    font-size: 16px;
  }
  .grid_container {
    width: 100%;
    height: 230px;
  }
  .grid_radioButtons {
    grid-template-columns: repeat(4, auto);
    grid-row-gap: 10%;
    grid-column-gap: 5%;
  }
  .radio_btn {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
  }
  .grid_radioButtons_Text {
    grid-template-columns: repeat(2, 135px) !important;
  }
  .grid_radioButtons_Text2 {
    grid-template-columns: repeat(1, 250px) !important;
    grid-row-gap: 10% !important;
  }
  .radio_btn_txt2 {
    max-width: 210px;
    width: 210px !important;
  }
  .manual_input_class {
    font-size: 16px;
  }
  .manual_input_class_3 {
    font-size: 16px;
  }
  .manual_select_class {
    font-size: 16px;
  }
}

@media only screen and (min-width: 600px) {
  .central_block {
    padding: 4% 4%;
  }
  .central_block h2 {
    font-size: 24px;
  }
  .form-group {
    margin-bottom: 3.5rem;
  }
  .age_div {
    margin-bottom: 2rem;
  }
  .form-group label {
    font-size: 24px;
  }
  .grid_container {
    width: 75%;
    height: 180px;
  }
  .grid_radioButtons {
    grid-template-columns: repeat(5, 50px);
    grid-row-gap: 25%;
    grid-column-gap: 7%;
  }
  .radio_btn {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
  }
  .grid_radioButtons_Text {
    grid-template-columns: repeat(2, 146px) !important;
  }
  .grid_radioButtons_Text2 {
    grid-template-columns: repeat(1, 250px) !important;
    grid-row-gap: 10% !important;
  }
  .radio_btn_txt2 {
    max-width: 210px;
    width: 210px !important;
  }
  .manual_input_class {
    font-size: 18px;
  }
  .manual_input_class_3 {
    font-size: 18px;
  }
  .manual_select_class {
    font-size: 18px;
  }
}

@media only screen and (min-width: 768px) {
  .grid_radioButtons_Text2 {
    grid-template-columns: repeat(1, 250px) !important;
    grid-row-gap: 10% !important;
  }
  .radio_btn_txt2 {
    max-width: 230px;
    width: 230px !important;
  }
}

@media only screen and (min-width: 992px) {
  .grid_radioButtons_Text2 {
    grid-template-columns: repeat(2, 250px) !important;
    grid-row-gap: 0% !important;
  }
  .radio_btn_txt2 {
    max-width: 230px;
    width: 230px !important;
  }
}

@media only screen and (min-width: 1200px) {
}
.tooltip > .tooltip-inner {
  /* background: green !important; */
  text-align: left;
  padding: 5%;
}

.error {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: rgb(182, 23, 2);
  margin-top: 10px;
}
</style>
