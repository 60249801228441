<template>
  <div>
    <HeaderTop></HeaderTop>
    <section class="view-size">
      <!-- Pogress Bar -->
      <div>
        <ProgressBar width="45"></ProgressBar>
      </div>

      <!-- Central Block -->
      <div class="container central_block">
        <h2 class="d-flex align-items-center" style="gap: 5px">
          How many travelers will be going?
          <img
            src="../assets/img/question-fill.svg"
            data-toggle="tooltip"
            data-placement="top"
            title="Please enter total number of travelers on this trip"
          />
        </h2>

        <!-- Radio buttons for no of travelers -->
        <div class="grid_container d-flex flex-column">
          <div
            class="btn-group btn-group-toggle grid_radioButtons"
            data-toggle="buttons"
          >
            <label
              v-for="(radNumber, index) in 10"
              :key="index"
              :class="[
                'btn btn-secondary radio_btn',
                radNumber === radioOptPicked && 'focus active',
              ]"
            >
              <div v-if="radNumber === 10">
                <input
                  type="radio"
                  name="options"
                  v-bind:id="radNumber"
                  v-bind:value="radNumber"
                  v-model="radioOptPicked"
                  @change="RadClick(radNumber)"
                />
                {{ radNumber }}
              </div>
              <div v-else>
                <input
                  type="radio"
                  name="options"
                  v-bind:id="radNumber"
                  v-bind:value="radNumber"
                  v-model="radioOptPicked"
                  @change="RadClick(radNumber)"
                />
                {{ radNumber }}
              </div>
            </label>
          </div>
          <div class="error pt-5" v-if="travelerActivated">
            <div v-if="!$v.numSelected.required">
              Please select total number of traveler
            </div>
          </div>
        </div>

        <!-- Age Collecting Box  -->
        <div v-if="this.numSelected">
          <h2 class="d-flex align-items-center" style="gap: 5px">
            What are the ages of the travelers?
            <img
              src="../assets/img/question-fill.svg"
              data-toggle="tooltip"
              data-placement="top"
              title="Please enter each traveler's age as of today. If you are traveling with a child under the age if 1, please enter 1."
            />
          </h2>

          <form>
            <div class="form-row">
              <div
                class="form-group age_div col-md-6"
                v-for="(number, index) in numSelected"
                :key="index"
                :id="index"
              >
                <div v-if="number <= 10">
                  <label for="inputTravlerAge1" class="label_age"
                    >Traveler {{ number }}</label
                  >
                  <input
                    type="text"
                    v-model.number="age[index]"
                    @input="updateAge"
                    class="inputField form-control manual_input_class no_icon"
                    v-bind:id="number"
                    placeholder="Age"
                    onkeypress="return /\d/.test(String.fromCharCode(((event||window.event).which||(event||window.event).which)));"
                  />
                </div>
                <!-- <div class="error" v-if="!$v.filteredAge.required">Field is required</div> -->

                <div v-if="number >= 11">
                  <label for="inputTravlerAge1" class="label_age"
                    >Traveler {{ number }}</label
                  >
                  <input
                    type="text"
                    v-model.number="age[index]"
                    @input="updateAge"
                    class="inputField form-control manual_input_class no_icon"
                    v-bind:id="number"
                    placeholder="Age"
                    onkeypress="return /\d/.test(String.fromCharCode(((event||window.event).which||(event||window.event).which)));"
                  />
                  <button class="deleteBtn" @click.prevent="delBtn(index)">
                    <i class="far fa-times-circle"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="error" v-if="userActivated">
              <div v-if="!($v.filteredAge.$model.length == numSelected)">
                Please fill all age boxes to continue
              </div>
            </div>
          </form>

          <!-- Add Traveler button when No of travellers exceed 10  -->
          <!-- <div
            v-if="numSelected >= 10"
            class="my-3 w-100 d-flex justify-content-start"
          >
            <ButtonMain
              class="w-25"
              variant="secondary"
              @btnClicked="addTraveler()"
            > Add Traveler</ButtonMain
            >
          </div> -->
        </div>

        <!-- Navigation Buttons Block -->
        <div
          class="d-flex flex-nowrap justify-content-between align-items-center mt-5 mb-3"
        >
          <ButtonMain variant="outline2" @btnClicked="prevStep()">
            Back
          </ButtonMain>

          <ButtonMain :variant="continueBtnStatus" @btnClicked="nextStep()">
            Continue
          </ButtonMain>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import HeaderTop from "../components/HeaderTop.vue";
import ProgressBar from "../components/ProgressBar.vue";
import ButtonMain from "../components/ButtonMain.vue";
import { required, requiredIf } from "vuelidate/lib/validators";

export default {
  components: { HeaderTop, ProgressBar, ButtonMain },
  data() {
    const travelers = this.$store.state.tripDetails.travelers;
    const radioOptPicked = travelers
      ? travelers.length <= 10
        ? travelers.length
        : 10
      : "";

    return {
      radioOptPicked, // v-model for radio buttons
      numSelected: travelers?.length || "", // variable to track no of input fields of age - do not delete
      age: travelers?.length ? travelers.map((t) => t.age) : [], // array to collect age data
      buttonDisabled: true, //boolean logic to enable/disable button
      filteredAge: [], // filtered age array to filter null values
      counter: 0,
      userActivated: false,
      continueBtnStatus: "",
      travelerActivated: false,
    };
  },

  validations: {
    filteredAge: {
      required: requiredIf(function () {
        return this.userActivated;
      }),
    },
    numSelected: {
      required: requiredIf(function () {
        return this.travelerActivated;
      }),
    },
  },

  mounted() {
    this.filteredAge = this.age.filter((element) => {
      return element !== "";
    });
    if (this.filteredAge.length) {
      this.buttonDisabled = false;
      this.continueBtnStatus = "primary";
    } else {
      this.continueBtnStatus = "disabled";
    }
  },

  watch: {
    counter() {
      console.log("counter value changed...");
    },
  },

  computed: {
    emptyCounter() {
      this.counter = 0;
      var fields = document.querySelectorAll(".manual_input_class");
      fields.forEach((item) => {
        if (item.value == "") {
          this.counter++;
        }
      });
    },
  },

  methods: {
    emptyFields() {
      this.counter = 0;
      var fields = document.querySelectorAll(".manual_input_class");
      // console.log(fields)
      fields.forEach((item) => {
        if (item.value == "") {
          this.counter++;
        }
      });
      // console.log("counter: ", this.counter)
    },

    updateAge() {
      this.filteredAge = this.age.filter((element) => {
        return element !== "";
      });

      // this.userActivated = true // uncomment it if you want to sow error on user input

      // this.age = this.age.filter(element => {
      //       return element !== ""
      // });
      // console.log("Filtered age Array: ", this.filteredAge)
      // this.emptyFields()
      // this.counter = this.numSelected - this.filteredAge.length
      // console.log( this.filteredAge,  this.filteredAge.length, this.numSelected)
      // this.buttonDisabled = !(this.numSelected != 0 && this.numSelected == this.filteredAge.length)
      // console.log("Final Updated age arr: ", this.age)
      // console.log("Final Updated age arr: ", this.age.length)
      // console.log("No of travelers: ", this.numSelected)

      this.buttonDisabled = !(
        this.numSelected != 0 && this.numSelected == this.filteredAge.length
      );
      this.continueBtnStatus = this.buttonDisabled ? "disabled" : "primary";
    },

    RadClick(radno) {
      //console.log("Traveler Quantity: ", this.radioOptPicked)
      //console.log("Age Array", this.age)
      // this.age = this.age.slice(0, this.radioOptPicked - 1);
      this.numSelected = radno;

      if (this.age.length > this.radioOptPicked - 1) {
        // console.log("Decreasing no of travelers...")
        // console.log("Age.length: ", this.age.length)
        // console.log("No of travelers: ", this.radioOptPicked)
        this.age = this.age.splice(0, +this.radioOptPicked);

        // Added bottom two additional lines
        this.filteredAge = this.age;
        this.filteredAge = this.age.filter((element) => {
          return element !== "";
        });
        // console.log("Updated age arr: ", this.filteredAge)
      }
      // The bottom block was commented out
      // this.age = this.age.filter(element => {
      //     return element !== ""
      // });

      // console.log("Final Updated age arr from RadioBtn: ", this.age)
      // console.log("Final Updated age arr from RadioBtn: ", this.age.length)
      // console.log("Final Updated age arr from RadioBtn: ", this.age)
      // this.emptyFields()
      // this.buttonDisabled = !(this.numSelected != 0 && this.numSelected == this.filteredAge.length)
      // Change back this.filteredAge to this.age
      this.buttonDisabled = !(
        this.numSelected != 0 && this.numSelected == this.filteredAge.length
      );
      this.continueBtnStatus = this.buttonDisabled ? "disabled" : "primary";
      this.userActivated = false;
    },

    addTraveler() {
      this.numSelected += 1;
      // this.updateAge()
      // this.counter++
      this.counter = this.numSelected - this.filteredAge.length;
      this.buttonDisabled = !(
        this.numSelected != 0 && this.numSelected == this.filteredAge.length
      );
    },

    delBtn(e) {
      this.filteredAge[e - 1] = "";
      this.age[e] = "";
      var i = e;
      for (i = e; i < this.age.length; i++) {
        this.age[i] = "";
      }
      // this.age = this.age.filter(element => {
      //       return element !== ""
      // });
      // console.log(this.age)
      this.filteredAge = this.age.filter((element) => {
        return element !== "";
      });

      this.numSelected -= 1;

      this.counter = this.numSelected - this.filteredAge.length;
      this.buttonDisabled = !(
        this.numSelected != 0 && this.numSelected == this.filteredAge.length
      );
    },

    nextStep() {
      this.travelerActivated = true;

      if (this.numSelected != "") {
        // console.log("now userActivated must be turned to true")
        this.userActivated = true;
      } else if (this.numSelected == "") {
        // console.log("now userActivated must be turned to false")
        this.userActivated = false;
      }
      // if(this.travelerActivated == true){
      //   this.userActivated = true
      // }

      var ageArray = this.age.map((item) => {
        var ageInt = parseInt(item);
        return { age: ageInt };
      });

      if (this.buttonDisabled == true) {
        return;
      } else if (this.buttonDisabled == false) {
        this.$store.commit("setTripDetails", {
          travelers: ageArray,
        });

        if (!this.$store.state.tripDetails.residentCountryCode) {
          this.$store.commit("setTripDetails", {
            residentCountryCode: "US",
          });
        }

        this.$store.commit("incrementStep");

        this.$router.push({ name: "TripDetails4" });
      }
    },

    prevStep() {
      var ageArray = this.age.map((item) => {
        var ageInt = parseInt(item);
        return { age: ageInt };
      });
      this.$store.commit("setTripDetails", {
        travelers: ageArray,
      });
      this.$router.push({ name: "TripDetails2" });
    },
  },
};

// :isDisabled="buttonDisabled"
</script>

<style scoped>
.view-size {
  width: 100%;
  min-height: 100vh;
  background-color: rgba(234, 234, 252, 1);
  padding: 1% 0;
}
.central_block {
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 24px;
  padding: 3%; /*this property was commented: Reason?*/
  margin: 50px auto;
}

@media only screen and (max-width: 600px) {
  .central_block {
    max-width: 92%;
  }
}

@media only screen and (min-width: 600px) {
  .central_block {
    max-width: 65%;
  }
}

.central_block h2 {
  font-family: "Inter", sans-serif;
  /* font-size: 24px; */
  font-weight: 600;
  line-height: 30px;
  color: rgba(26, 26, 81, 1);
  padding-top: 2%;
  padding-bottom: 3%;
}

.manual_select_class {
  border: 2px solid rgba(26, 26, 81, 0.07);
  border-radius: 10px;
  padding: 15px 15px;
  height: 60px;

  font-family: "Inter", sans-serif;
  /* font-size: 18px; */
  font-weight: 400;
  line-height: 24px;
  color: rgba(26, 26, 81, 1);

  background: rgba(250, 250, 255, 1) url("../assets/img/search_icon.svg") right
    0.75rem center/30px 18px no-repeat;
}

.manual_select_class:focus,
.manual_select_class:active,
.manual_select_class:hover {
  box-shadow: none !important;
  border: 2px solid rgba(26, 26, 81, 0.07);
}

.manual_input_class_3 {
  border: 2px solid rgba(26, 26, 81, 0.07);
  border-radius: 10px;
  padding: 10px 10px 10px 15px;
  height: 55px;

  font-family: "Inter", sans-serif;
  /* font-size: 18px; */
  font-weight: 400;
  line-height: 24px;
  color: rgba(26, 26, 81, 1);

  background: rgba(250, 250, 255, 1) url("../assets/img/calender_icon.svg")
    right 0.75rem center/30px 18px no-repeat;
}

.manual_input_class_3:focus,
.manual_input_class_3:active,
.manual_input_class_3:hover {
  box-shadow: none !important;
  border: 2px solid rgba(26, 26, 81, 0.07);
  background-color: rgba(250, 250, 255, 1) !important;
}

.label_age {
  font-family: "Inter", sans-serif !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 18px !important;
  color: rgba(26, 26, 81, 1);
  margin-bottom: 0.5rem !important;
}

.manual_input_class {
  border: 2px solid rgba(26, 26, 81, 0.07);
  border-radius: 10px;
  padding: 15px 15px;
  height: 55px;

  font-family: "Inter", sans-serif;
  /* font-size: 18px; */
  font-weight: 400;
  line-height: 24px;
  color: rgba(26, 26, 81, 1);

  background: rgba(250, 250, 255, 1) url("../assets/img/search_icon.svg") right
    0.75rem center/30px 18px no-repeat;
}

.manual_input_class:focus,
.manual_input_class:active,
.manual_input_class:hover {
  box-shadow: none !important;
  border: 2px solid rgba(26, 26, 81, 0.07);
  background-color: rgba(250, 250, 255, 1) !important;
}

.no_icon {
  background: rgba(250, 250, 255, 1) !important;
}

.grid_radioButtons {
  display: grid;
  /* grid-template-columns: repeat(5, auto); */
  /* grid-row-gap: 7%; */
}

.radio_btn {
  font-family: "BasierCircle-Regular", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 60px;
  width: 55px;
  height: 55px;
  color: rgba(26, 26, 81, 1);
  background-color: #fafaff;
  border: 2px solid rgba(26, 26, 81, 0.07);
  border-radius: 15px 15px 15px 15px !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.radio_btn:hover {
  color: #fff;
  background-color: rgba(110, 110, 223, 1);
  border-color: rgba(110, 110, 223, 1);
  box-shadow: none;
}
.radio_btn:active {
  color: #fff;
  background-color: rgba(110, 110, 223, 1);
  border-color: rgba(110, 110, 223, 1);
  box-shadow: none;
}

@media only screen and (max-width: 600px) {
  .central_block {
    padding: 4% 6%;
  }
  .central_block h2 {
    font-size: 18px;
  }
  .form-group {
    margin-bottom: 2rem;
  }
  .age_div {
    margin-bottom: 1.5rem;
  }
  .form-group label {
    font-size: 16px;
  }
  .grid_container {
    width: 100%;
    height: 230px;
  }
  .grid_radioButtons {
    grid-template-columns: repeat(4, auto);
    grid-row-gap: 10%;
    grid-column-gap: 5%;
  }
  .radio_btn {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
  }
  .grid_radioButtons_Text {
    grid-template-columns: repeat(2, 135px) !important;
  }
  .grid_radioButtons_Text2 {
    grid-template-columns: repeat(1, 250px) !important;
    grid-row-gap: 10% !important;
  }
  .radio_btn_txt2 {
    max-width: 210px;
    width: 210px !important;
  }
  .manual_input_class {
    font-size: 16px;
  }
  .manual_input_class_3 {
    font-size: 16px;
  }
  .manual_select_class {
    font-size: 16px;
  }
}

@media only screen and (min-width: 600px) {
  .central_block {
    padding: 4% 4%;
  }
  .central_block h2 {
    font-size: 24px;
  }
  .form-group {
    margin-bottom: 3.5rem;
  }
  .age_div {
    margin-bottom: 2rem;
  }
  .form-group label {
    font-size: 24px;
  }
  .grid_container {
    width: 75%;
    height: 180px;
  }
  .grid_radioButtons {
    grid-template-columns: repeat(5, 50px);
    grid-row-gap: 25%;
    grid-column-gap: 5%;
  }
  .radio_btn {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
  }
  .grid_radioButtons_Text {
    grid-template-columns: repeat(2, 146px) !important;
  }
  .grid_radioButtons_Text2 {
    grid-template-columns: repeat(1, 250px) !important;
    grid-row-gap: 10% !important;
  }
  .radio_btn_txt2 {
    max-width: 210px;
    width: 210px !important;
  }
  .manual_input_class {
    font-size: 18px;
  }
  .manual_input_class_3 {
    font-size: 18px;
  }
  .manual_select_class {
    font-size: 18px;
  }
}

@media only screen and (min-width: 768px) {
  .grid_radioButtons_Text2 {
    grid-template-columns: repeat(1, 250px) !important;
    grid-row-gap: 10% !important;
  }
  .radio_btn_txt2 {
    max-width: 230px;
    width: 230px !important;
  }
}

@media only screen and (min-width: 992px) {
  .grid_radioButtons_Text2 {
    grid-template-columns: repeat(2, 250px) !important;
    grid-row-gap: 0% !important;
  }
  .radio_btn_txt2 {
    max-width: 230px;
    width: 230px !important;
  }
}

@media only screen and (min-width: 1200px) {
}

.deleteBtn {
  background-color: transparent;
  border: none;
  position: absolute;
  top: 46px;
  right: 10px;
}
</style>
