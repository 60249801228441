import Vue from 'vue';
import VueRouter from 'vue-router';
import TripDetails1 from '../views/TripDetails1.vue';
import TripDetails2 from '../views/TripDetails2.vue';
import TripDetails3 from '../views/TripDetails3.vue';
import TripDetails4 from '../views/TripDetails4.vue';
import TripDetails5 from '../views/TripDetails5.vue';
import TripDetails6 from '../views/TripDetails6.vue';
import Quotes1 from '../views/Quotes1.vue';
import Quotes2 from '../views/Quotes2.vue';
import Quotes4 from '../views/Quotes4.vue';
import Compare from '../views/Compare.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/trip-details/step-1',
  },
  {
    path: '/trip-details/step-1',
    name: 'TripDetails1',
    component: TripDetails1,
  },
  {
    path: '/trip-details/step-2',
    name: 'TripDetails2',
    component: TripDetails2,
  },
  {
    path: '/trip-details/step-3',
    name: 'TripDetails3',
    component: TripDetails3,
  },
  {
    path: '/trip-details/step-4',
    name: 'TripDetails4',
    component: TripDetails4,
  },
  {
    path: '/trip-details/step-5',
    name: 'TripDetails5',
    component: TripDetails5,
  },
  {
    path: '/trip-details/step-6',
    name: 'TripDetails6',
    component: TripDetails6,
  },
  {
    path: '/quotes',
    name: 'Quotes1',
    component: Quotes1,
  },
  {
    path: '/quotes/:id',
    name: 'Quotes4',
    component: Quotes4,
  },
  {
    path: '/policy-detail',
    name: 'Quotes2',
    component: Quotes2,
  },
  {
    path: '/compare-quotes',
    name: 'Compare',
    component: Compare,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
