<template>
  <div class="view-size" >
    <div v-if="$store.state.loadingQuotes" class="loading">
      <p>Searching for policies...</p>
      <img src="../assets/loadingCovertrip.gif" class="loading_logo"/>
    </div>
    <div :style="isload ? { filter: 'blur(8px)' }:''">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'Background',
  data(){
    return {
      isloading : this.$store.state.loadingQuotes,
      loadstyle: {
        filter: 'blur(6px)',
        background: `lightblue url(${'../assets/loadingCovertrip.gif'}) no-repeat fixed center`,
      },
    }
  },
  computed : {
    ...mapState(['loadingQuotes']),
    isload() {
      return this.$store.state.loadingQuotes
    },
  },
};

</script>

<style scoped>
.view-size {
  width: 100%;
  min-height: 100vh;
  background-color: rgba(234, 234, 252, 1);
  padding: 1% 0;
}
.loading {
  /* filter: blur(6px); */
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  z-index: 99999!important;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-color: rgba(179, 206, 247, 0.68); */
}
.loading_logo {
  width: 80px;
  height: 80px;
}
.loading p {
  font-family: 'Inter', sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  color: rgba(26, 26, 81, 1);
}
/* :style="isload ? { filter: 'blur(6px)' }:{ filter: 'blur(0px)' }" */
</style>
