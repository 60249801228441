<template>
  <div>
    <HeaderTop></HeaderTop>
    <section class="view-size">
      <!-- Pogress Bar -->
      <div>
        <ProgressBar width="60"></ProgressBar>
      </div>

      <!-- Central Block -->
      <div class="container central_block">
        <form v-on:submit.prevent="onSubmit">
          <small ref="countryErr"></small>

          <!-- Selecting Country -->
          <transition name="slide-fade">
            <div class="form-group">
              <label
                for="formGroupExampleInput"
                class="d-flex align-items-center"
                style="gap: 5px"
              >
                What country do you live in?
                <img
                  src="../assets/img/question-fill.svg"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Please enter your primary country of residence."
                />
              </label>

              <small ref="countryErr"></small>
              <v-select
                :options="countryList"
                v-model="residentCountry"
                :filter="fuseSearch"
                @input="setCountryName($event)"
              ></v-select>
              <div class="error" v-if="!$v.residentCountry.required">
                Resident Country is required
              </div>
            </div>
          </transition>

          <!-- Selecting US State -->
          <transition name="slide-fade">
            <div class="form-group" v-show="showRegion">
              <label
                for="formGroupExampleInput2"
                class="d-flex align-items-center"
                style="gap: 5px"
              >
                What {{ getStatesData("text") }} do you live in?
                <img
                  v-show="getStatesData('hasStates')"
                  src="../assets/img/question-fill.svg"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Please enter your primary state of residence."
                />
              </label>

              <v-select
                :options="getStatesData('stateList')"
                v-model="residentRegion"
                :reduce="(region) => region.code"
                :filter="fuseSearch"
                @input="setName($event)"
              ></v-select>
              <div class="error" v-if="!$v.residentRegion.required">
                State/Province field is required
              </div>
            </div>
          </transition>

          <!-- Selecting Citzenship -->
          <transition name="slide-fade">
            <div class="form-group" v-show="residentCountry">
              <label
                for="formGroupExampleInput3"
                class="d-flex align-items-center"
                style="gap: 5px"
              >
                What country are you a citizen of?
                <img
                  src="../assets/img/question-fill.svg"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Please enter your country of citizenship"
                />
              </label>

              <v-select
                :options="countryList"
                v-model="citizenship"
                :filter="fuseSearch"
                @input="setName($event)"
              ></v-select>
              <div class="error" v-if="!$v.citizenship.required">
                Citizenship field is required
              </div>
            </div>
          </transition>
        </form>

        <!-- Navigation buttons Block -->
        <div
          class="d-flex flex-nowrap justify-content-between align-items-center mt-5 mb-3"
        >
          <ButtonMain variant="outline2" @btnClicked="prevStep()">
            Back
          </ButtonMain>

          <ButtonMain :variant="continueBtnStatus" @btnClicked="nextStep()">
            Continue
          </ButtonMain>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import HeaderTop from "../components/HeaderTop.vue";
import ProgressBar from "../components/ProgressBar.vue";
import ButtonMain from "../components/ButtonMain.vue";
import vSelect from "vue-select";
import { mapGetters } from "vuex";
import { required, requiredIf } from "vuelidate/lib/validators";

export default {
  name: "TripDetails4",
  components: { HeaderTop, ProgressBar, ButtonMain, vSelect },

  data() {
    const tripDetails = this.$store.state.tripDetails;

    return {
      residentCountry: tripDetails.residentCountryCode,
      residentRegion: tripDetails.residentRegionCode,
      citizenship: tripDetails.citizenCountryCode,
      showRegion: true,
      userActivated1: false,
      userActivated2: false,
      userActivated3: false,
      continueBtnStatus: "",
    };
  },

  validations: {
    residentCountry: {
      required: requiredIf(function () {
        return this.userActivated1;
      }),
    },
    residentRegion: {
      required: requiredIf(function () {
        return this.userActivated2;
      }),
    },
    citizenship: {
      required: requiredIf(function () {
        return this.userActivated3;
      }),
    },
  },

  mounted() {
    // console.log(this.$store.state.tripDetails.citizenCountryCode, this.$store.state.tripDetails.residentCountryCode, this.$store.state.tripDetails.residentRegionCode)
    // if(this.$store.state.tripDetails.citizenCountryCode && this.$store.state.tripDetails.residentCountryCode && this.$store.state.tripDetails.residentRegionCode){
    //   // console.log("btn can now turn blue...")
    //   this.continueBtnStatus = 'primary'
    // }

    if (this.residentCountry) {
      const countryArr = [];
      this.countryList.forEach((item) => {
        if (item.code === this.residentCountry) countryArr.push(item);
      });
      if (countryArr.length == 1) {
        this.residentCountry = countryArr[0];
        this.citizenship = countryArr[0];
      }
      if (countryArr.length > 1) {
        this.residentCountry = countryArr[2];
        this.citizenship = countryArr[2];
      }
    }

    if (
      this.$store.state.tripDetails.residentCountryCode == "US" ||
      this.$store.state.tripDetails.residentCountryCode == "CA"
    ) {
      // console.log("the resident region is:: ", this.residentRegion, this.$store.state.tripDetails.residentRegionCode)
      // this.residentRegion = "anything"
      // console.log("show mid field")
      this.showRegion = true;
    } else {
      // console.log("hide mid field")
      this.showRegion = false;
    }

    if (
      this.$store.state.tripDetails.citizenCountryCode &&
      this.$store.state.tripDetails.residentCountryCode
    ) {
      // console.log("button must turn blue")
      this.continueBtnStatus = "primary";
    } else {
      // console.log("button must remain grey")
      this.continueBtnStatus = "disabled";
    }
  },

  computed: {
    ...mapGetters({
      countryList: "mappedCountries",
      statesList: "mappedStates",
      provinceList: "mappedProvince",
    }),

    // statesCountrySelected() {
    //   return this.residentCountry.code === 'US' || this.residentCountry.code === 'CA';
    // },
    // invalidSelection() {
    //   return this.statesCountrySelected
    //     ? !(this.residentCountry && this.residentRegion && this.citizenship)
    //     : !(this.residentCountry && this.citizenship);
    // },
  },

  watch: {
    residentCountry(countryCode) {
      this.citizenship = countryCode;
      // this.residentRegion = null;

      if (countryCode) {
        switch (countryCode.code) {
          case "CA":
            if (this.residentRegion) {
              return (this.continueBtnStatus = "primary");
            } else {
              return (this.continueBtnStatus = "disabled");
            }
          case "US":
            if (this.residentRegion) {
              return (this.continueBtnStatus = "primary");
            } else {
              return (this.continueBtnStatus = "disabled");
            }
          default:
            this.continueBtnStatus = "primary";
            return console.log("not canada or us");
        }
      } else if (!countryCode) {
        this.continueBtnStatus = "disabled";
      }
    },
  },

  methods: {
    getStatesData(type = "text") {
      if (this.residentCountry) {
        switch (type) {
          case "text":
            if (this.residentCountry.code === "CA") return "province";
            return "state";

          case "stateList":
            if (this.residentCountry.code === "CA") return this.provinceList;
            return this.statesList;

          case "hasStates":
            if (this.residentCountry.code === "US") return true;
            return false;
        }
      } else {
        return;
      }
    },

    nextStep() {
      if (!this.residentCountry) {
        this.userActivated1 = true;
        this.userActivated2 = false;
        this.userActivated3 = false;
      }

      if (this.residentCountry) {
        this.userActivated2 = true;
        this.userActivated3 = true;
      }

      // if selected country is USA
      if (this.residentCountry == "US") {
        if (this.residentCountry && this.citizenship && this.residentRegion) {
          this.$store.commit("setTripDetails", {
            residentCountryCode: this.residentCountry.code,
            citizenCountryCode: this.citizenship.code,
            residentRegionCode: this.residentRegion,
          });

          this.$store.commit("incrementStep");

          this.$router.push({ name: "TripDetails5" });
        } else {
          return;
        }
      }

      // if selected country is Canada
      if (this.residentCountry == "CA") {
        if (this.residentCountry && this.citizenship && this.residentRegion) {
          this.$store.commit("setTripDetails", {
            residentCountryCode: this.residentCountry.code,
            citizenCountryCode: this.citizenship.code,
            residentRegionCode: this.residentRegion,
          });

          this.$store.commit("incrementStep");

          this.$router.push({ name: "TripDetails5" });
        } else {
          return;
        }
      }
      // if other country beside USA and CANADA is selected
      if (this.residentCountry != "CA" && this.residentCountry != "US") {
        // console.log("some other country selected")
        if (this.residentCountry && this.citizenship) {
          this.$store.commit("setTripDetails", {
            residentCountryCode: this.residentCountry.code,
            citizenCountryCode: this.citizenship.code,
            residentRegionCode: this.residentRegion,
          });

          this.$store.commit("incrementStep");

          this.$router.push({ name: "TripDetails5" });
        } else {
          return;
        }
      }
    },

    prevStep() {
      // if selected country is USA
      if (this.residentCountry == "US") {
        if (this.residentCountry && this.citizenship && this.residentRegion) {
          this.$store.commit("setTripDetails", {
            residentCountryCode: this.residentCountry.code,
            citizenCountryCode: this.citizenship.code,
            residentRegionCode: this.residentRegion,
          });

          this.$router.push({ name: "TripDetails3" });
        }
      }

      // if selected country is Canada
      if (this.residentCountry == "CA") {
        if (this.residentCountry && this.citizenship && this.residentRegion) {
          this.$store.commit("setTripDetails", {
            residentCountryCode: this.residentCountry.code,
            citizenCountryCode: this.citizenship.code,
            residentRegionCode: this.residentRegion,
          });

          this.$router.push({ name: "TripDetails3" });
        }
      }
      // if other country beside USA and CANADA is selected
      if (this.residentCountry != "CA" && this.residentCountry != "US") {
        // console.log("some other country selected")
        if (this.residentCountry && this.citizenship) {
          this.$store.commit("setTripDetails", {
            residentCountryCode: this.residentCountry.code,
            citizenCountryCode: this.citizenship.code,
            residentRegionCode: this.residentRegion,
          });

          this.$store.commit("incrementStep");

          this.$router.push({ name: "TripDetails3" });
        }
      }
      // this.$router.push({ name: 'TripDetails3' });
    },

    fuseSearch(options, search) {
      return options.filter((state) =>
        state.label.toLowerCase().startsWith(search.toLowerCase())
      );
    },

    setName(value) {
      // this.userActivated = true
      if (this.residentCountry && this.citizenship && this.residentRegion) {
        // console.log("in if loop")
        this.continueBtnStatus = "primary";
      } else {
        // console.log("in else loop")
        this.continueBtnStatus = "disabled";
        this.userActivated2 = false;
        this.userActivated3 = false;
      }
    },

    setCountryName(value) {
      if (this.residentCountry) {
        this.residentRegion = null;
        this.citizenship = this.residentCountry;
        if (
          this.residentCountry.code == "US" ||
          this.residentCountry.code == "CA"
        ) {
          this.showRegion = true;
        } else {
          this.showRegion = false;
        }
      }
    },
    // setName() {
    //   // console.log(value)
    //   console.log(this.residentCountry, this.citizenship)
    //   // this.userActivated = true
    //   // if(this.residentCountry==="US" && this.citizenship && this.residentRegion){
    //   //   console.log("in if US loop")
    //   //   this.continueBtnStatus = 'primary'
    //   // }
    //   // if(this.residentCountry==="CA" && this.citizenship && this.residentRegion){
    //   //   console.log("in if CA loop")
    //   //   this.continueBtnStatus = 'primary'
    //   // }
    //   // if((this.residentCountry !== "US" || this.residentCountry !=="CA") && this.citizenship){
    //   //   console.log("in if loop")
    //   //   this.continueBtnStatus = 'primary'
    //   // }
    //   // else {
    //   //   console.log("in else loop")
    //   //   this.continueBtnStatus = 'disabled'
    //   //   this.userActivated2 = false
    //   //   this.userActivated3 = false
    //   // }
    //   // return
    // },
  },
};
// :isDisabled="invalidSelection"
</script>

<style scoped>
.view-size {
  width: 100%;
  min-height: 100vh;
  background-color: rgba(234, 234, 252, 1);
  padding: 1% 0;
}
.central_block {
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 24px;
  padding: 3%; /*this property was commented: Reason?*/
  margin: 50px auto;
}

@media only screen and (max-width: 600px) {
  .central_block {
    max-width: 92%;
  }
}

@media only screen and (min-width: 600px) {
  .central_block {
    max-width: 65%;
  }
}

.central_block h2 {
  font-family: "Inter", sans-serif;
  /* font-size: 24px; */
  font-weight: 600;
  line-height: 30px;
  color: rgba(26, 26, 81, 1);
  padding-top: 2%;
  padding-bottom: 3%;
}

.manual_select_class {
  border: 2px solid rgba(26, 26, 81, 0.07);
  border-radius: 10px;
  padding: 15px 15px;
  height: 60px;

  font-family: "Inter", sans-serif;
  /* font-size: 18px; */
  font-weight: 400;
  line-height: 24px;
  color: rgba(26, 26, 81, 1);

  background: rgba(250, 250, 255, 1) url("../assets/img/search_icon.svg") right
    0.75rem center/30px 18px no-repeat;
}

.manual_select_class:focus,
.manual_select_class:active,
.manual_select_class:hover {
  box-shadow: none !important;
  border: 2px solid rgba(26, 26, 81, 0.07);
}

.manual_input_class_3 {
  border: 2px solid rgba(26, 26, 81, 0.07);
  border-radius: 10px;
  padding: 10px 10px 10px 15px;
  height: 55px;

  font-family: "Inter", sans-serif;
  /* font-size: 18px; */
  font-weight: 400;
  line-height: 24px;
  color: rgba(26, 26, 81, 1);

  background: rgba(250, 250, 255, 1) url("../assets/img/calender_icon.svg")
    right 0.75rem center/30px 18px no-repeat;
}

.manual_input_class_3:focus,
.manual_input_class_3:active,
.manual_input_class_3:hover {
  box-shadow: none !important;
  border: 2px solid rgba(26, 26, 81, 0.07);
  background-color: rgba(250, 250, 255, 1) !important;
}

.label_age {
  font-family: "Inter", sans-serif !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 18px !important;
  color: rgba(26, 26, 81, 1);
  margin-bottom: 0.5rem !important;
}

.manual_input_class {
  border: 2px solid rgba(26, 26, 81, 0.07);
  border-radius: 10px;
  padding: 15px 15px;
  height: 55px;

  font-family: "Inter", sans-serif;
  /* font-size: 18px; */
  font-weight: 400;
  line-height: 24px;
  color: rgba(26, 26, 81, 1);

  background: rgba(250, 250, 255, 1) url("../assets/img/search_icon.svg") right
    0.75rem center/30px 18px no-repeat;
}

.manual_input_class:focus,
.manual_input_class:active,
.manual_input_class:hover {
  box-shadow: none !important;
  border: 2px solid rgba(26, 26, 81, 0.07);
  background-color: rgba(250, 250, 255, 1) !important;
}

.form-group label {
  font-family: "Inter", sans-serif;
  /* font-size: 24px; */
  font-weight: 600;
  line-height: 30px;
  color: rgba(26, 26, 81, 1);
  margin-bottom: 1.25rem;
}

@media only screen and (max-width: 600px) {
  .central_block {
    padding: 4% 6%;
  }
  .central_block h2 {
    font-size: 18px;
  }
  .form-group {
    margin-bottom: 2rem;
  }
  .age_div {
    margin-bottom: 1.5rem;
  }
  .form-group label {
    font-size: 16px;
  }
  .grid_container {
    width: 100%;
    height: 230px;
  }
  .grid_radioButtons {
    grid-template-columns: repeat(4, auto);
    grid-row-gap: 10%;
    grid-column-gap: 5%;
  }
  .radio_btn {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
  }
  .grid_radioButtons_Text {
    grid-template-columns: repeat(2, 135px) !important;
  }
  .grid_radioButtons_Text2 {
    grid-template-columns: repeat(1, 250px) !important;
    grid-row-gap: 10% !important;
  }
  .radio_btn_txt2 {
    max-width: 210px;
    width: 210px !important;
  }
  .manual_input_class {
    font-size: 16px;
  }
  .manual_input_class_3 {
    font-size: 16px;
  }
  .manual_select_class {
    font-size: 16px;
  }
}

@media only screen and (min-width: 600px) {
  .central_block {
    padding: 4% 4%;
  }
  .central_block h2 {
    font-size: 24px;
  }
  .form-group {
    margin-bottom: 3.5rem;
  }
  .age_div {
    margin-bottom: 2rem;
  }
  .form-group label {
    font-size: 24px;
  }
  .grid_container {
    width: 75%;
    height: 180px;
  }
  .grid_radioButtons {
    grid-template-columns: repeat(5, 50px);
    grid-row-gap: 25%;
    grid-column-gap: 7%;
  }
  .radio_btn {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
  }
  .grid_radioButtons_Text {
    grid-template-columns: repeat(2, 146px) !important;
  }
  .grid_radioButtons_Text2 {
    grid-template-columns: repeat(1, 250px) !important;
    grid-row-gap: 10% !important;
  }
  .radio_btn_txt2 {
    max-width: 210px;
    width: 210px !important;
  }
  .manual_input_class {
    font-size: 18px;
  }
  .manual_input_class_3 {
    font-size: 18px;
  }
  .manual_select_class {
    font-size: 18px;
  }
}

@media only screen and (min-width: 768px) {
  .grid_radioButtons_Text2 {
    grid-template-columns: repeat(1, 250px) !important;
    grid-row-gap: 10% !important;
  }
  .radio_btn_txt2 {
    max-width: 230px;
    width: 230px !important;
  }
}

@media only screen and (min-width: 992px) {
  .grid_radioButtons_Text2 {
    grid-template-columns: repeat(2, 250px) !important;
    grid-row-gap: 0% !important;
  }
  .radio_btn_txt2 {
    max-width: 230px;
    width: 230px !important;
  }
}

@media only screen and (min-width: 1200px) {
}

.slide-fade-enter-active {
  transition: all 0.6s ease-in;
}

.slide-fade-leave-active {
  transition: all 0.6s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from {
  transform: translateY(20px);
  opacity: 0;
}
.slide-fade-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}
</style>
