<template>
  <div>
    <Container
      :margin="containerStyle.margin"
      :borderRadius="containerStyle.borderRadius"
      :padding="containerStyle.padding"
    >
      <div>
        <div
          class="d-flex justify-content-between align-items-center px-4"
          style="gap: 16px; padding-top: 20px"
        >
          <div class="d-flex">
            <!-- <img :src="require('../assets/img/SVGs/' + logo )"  alt="company logo"> -->
            <img :src="provider.mediumLogoUrl" alt="company logo" />
          </div>
          <div class="offer_box_title mr-auto d-none d-lg-flex">
            <h1>{{ provider.name }}: {{ productName }}</h1>
          </div>
          <div class="compareBtn d-none d-lg-flex" style="min-width: 23%">
            <div
              v-show="compare"
              style="width: 100%"
              @click="$emit('cardClicked')"
            >
              <ButtonMain
                style="width: 100%"
                variant="compare"
                :isDisabled="this.$store.state.cardsForCompare.length >= 3"
                @btnClicked="
                  (compare = false),
                    (unselect = true),
                    $emit('cardClicked', `${productCode}`)
                "
              >
                Compare
              </ButtonMain>
            </div>
            <div
              v-show="unselect"
              style="width: 100%"
              @click="$emit('cardClicked')"
            >
              <ButtonMain
                style="width: 100%"
                variant="compareUnselect"
                @btnClicked="
                  (compare = true),
                    (unselect = false),
                    $emit('cardUNClicked', `${productCode}`)
                "
              >
                Unselect
              </ButtonMain>
            </div>
          </div>
          <div class="d-flex d-lg-none">
            <h3 id="price_of_offer_bold" class="my-3 my-lg-0">
              $ {{ premium.toFixed(2).toLocaleString().toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </h3>
          </div>
        </div>
        <!-- Titles for mobile screen -->
        <div class="offer_box_title_mob mr-auto d-block d-lg-none px-4">
          <h1>{{ provider.name }}:</h1>
          <h2>{{ productName }}</h2>
        </div>

        <div class="px-4"><hr /></div>

        <div class="row ob-main-body px-4 pt-0 pt-lg-4 pb-4">
          <!-- First column -->
          <div class="card_details_left col-0 col-lg-1 d-none d-lg-block">
            <div
              class="d-flex justify-content-center align-items-center mt-4"
            ></div>
          </div>

          <!-- Second Column -->
          <div class="col-12 col-lg-8">
            <div class="d-flex flex-wrap">
              <!-- <div class="center-title d-none d-md-flex" style="width: 100%">
                <h3>Trip coverage</h3>
              </div> -->
              <div
                class="
                  d-flex d-lg-none
                  flex-wrap
                  justify-content-between
                  align-items-center
                  w-100
                  my-3
                "
              >
                <!-- Rating disabled on mobile screen -->
                <div class="rating_block">
                  <i
                    class="d-none rating__star fas fa-star"
                    v-for="(item, idx) in Math.floor(
                      provider.averageOverallRating
                    )"
                    :key="idx"
                  ></i>
                </div>
                <!-- Comparison button for smaller screen -->
                <div class="compareBtn d-flex d-lg-none w-100" style="min-width: 23%">
                  <div
                    v-show="compare"
                    style="width: 100%"
                    @click="$emit('cardClicked')"
                  >
                    <ButtonMain
                      style="width: 100%"
                      variant="compare"
                      :isDisabled="
                        this.$store.state.cardsForCompare.length >= 3
                      "
                      @btnClicked="
                        (compare = false),
                          (unselect = true),
                          $emit('cardClicked', `${productCode}`)
                      "
                    >
                      Compare
                    </ButtonMain>
                  </div>
                  <div
                    v-show="unselect"
                    style="width: 100%"
                    @click="$emit('cardClicked')"
                  >
                    <ButtonMain
                      style="width: 100%"
                      variant="compareUnselect"
                      @btnClicked="
                        (compare = true),
                          (unselect = false),
                          $emit('cardUNClicked', `${productCode}`)
                      "
                    >
                      Unselect
                    </ButtonMain>
                  </div>
                </div>
                <!-- <img src="../assets/img/SVGs/rating_stars.svg" /> -->
                <!-- <img src="../assets/img/SVGs/editors_choice.svg" /> -->
              </div>
              <template v-for="(detail, index) in coverages">
                <div
                  :key="index"
                  v-if="checkNames(detail.benefitName)"
                  class="
                    item_details_div
                    d-flex
                    flex-wrap
                    justify-content-start
                    align-items-start
                  "
                >
                  <div class="w-75 list-items-left">
                    {{ detail.benefitName }}
                  </div>
                  <div
                    class="w-25 list-items-left"
                    v-if="detail.coverageSummary == 'Covered'"
                    style="color: rgba(51, 204, 155, 1)"
                  >
                    {{ detail.coverageSummary }}
                  </div>
                  <div class="w-25 list-items-left" v-else>
                    {{ detail.coverageSummary }}
                  </div>
                </div>
              </template>
            </div>
          </div>

          <!-- Third Class -->
          <div
            class="
              col-12 col-lg-3
              d-flex
              flex-column
              justify-content-between
              px-2 px-lg-1
            "
          >
            <div class="d-flex justify-content-center">
              <h3 id="price_of_offer" class="my-3 my-lg-0 d-none d-lg-flex">
                $
                {{
                  premium
                    .toFixed(2)
                    .toLocaleString()
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}
              </h3>
            </div>

            <div
              class="
                d-none d-md-flex
                flex-lg-column flex-row-reverse
                justify-content-between
                align-items-center
                mt-3
              "
            >
              <div class="btn_poliyDetails_div my-2">
                <ButtonMain
                  variant="outline"
                  data-toggle="modal"
                  :data-target="'#' + 'firstModal' + productCode"
                >
                  Policy Details
                </ButtonMain>
              </div>
              <div class="btn_buy_div my-2">
                <a :href="purchaseUrl + `&aid=${aid}`" target="_blank">
                  <ButtonMain variant="primary">Purchase</ButtonMain>
                </a>
              </div>
            </div>
          </div>

    <!-- Buttons for mobile screens -->
    <div
      class="
        d-flex d-md-none
        flex-lg-column flex-row-reverse
        justify-content-between
        align-items-center
        mt-3
        w-100
      "
    >
      <div class="btn_poliyDetails_div my-2 ml-3">
        <ButtonMain
          variant="outline"
          data-toggle="modal"
          :data-target="'#firstModal'+productCode"
        >
          Policy Details
        </ButtonMain>
      </div>
      <div class="btn_buy_div my-2">
        <a :href="purchaseUrl + `&aid=${aid}`" target="_blank">
          <ButtonMain variant="primary">Purchase</ButtonMain>
        </a>
      </div>
    </div>

          <!-- Last Sold at bottom -->
          <div class="card_footer">
            <h3>{{ provider.totalSales.toLocaleString() }} policies sold</h3>
            <p class="last_sold" v-if="productLastPurchasedAt">
              Last sold {{ timeEdited }}
            </p>
          </div>
        </div>
      </div>
    </Container>

    

    <!-- Modal for Details -->
    <div
      class="modal fade"
      :id="'firstModal'+`${productCode}`"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-scrollable modal-xl">
        <div class="modal-content">
          <!-- =================== Modal Header ================ -->
          <div class="modal-header px-5 d-flex">
            <div
              class="d-none d-xl-block"
              style="position: absolute; left: 2.5rem"
            >
              <img :src="provider.mediumLogoUrl" alt="company logo" />
            </div>
            <div>
              <h1 class="modal-title" id="exampleModalLabel">
                {{ provider.name }}: {{ productName }}
              </h1>
              <h1 class="modal-subtitle" id="exampleModalLabel">
                Coverage Details
              </h1>
              <div class="d-flex justify-content-center pt-4 pb-2">
                <a
                  :href="certificateUrl"
                  target="_blank"
                  download="proposed_file_name"
                  class="spc_certificate"
                  >View Certificate</a
                >
              </div>
            </div>
          </div>
          <!-- =================== Modal Body =================== -->
          <div class="modal-body">
            <div class="accordion" id="accordionExample">
              <div
                class="card"
                v-for="(name, index) in uniqCategory"
                :key="index"
              >
                <!-- Unique Categories Accordian Parent Body -->
                <div class="card-header" id="headingOne">
                  <h2 class="mb-0">
                    <button
                      class="btn btn-link btn-block text-left"
                      type="button"
                      data-toggle="collapse"
                      :data-target="
                        '#' + name.replace(' ', '').replace(' ', '') + randomNo
                      "
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      {{ name }}
                    </button>
                  </h2>
                </div>

                <!-- Unique Categories Accordian Target Body -->
                <div
                  v-for="(item, idx) in subCategory"
                  :key="idx"
                  :id="name.replace(' ', '').replace(' ', '') + randomNo"
                >
                  <template v-for="(element, index) in item">
                    <div
                      class="collapse show"
                      aria-labelledby="headingOne"
                      data-parent="#accordionExample"
                      :key="index"
                      v-if="element.benefitGroupName == name"
                    >
                      <div class="card-body">
                        <div>
                          <div
                            class="
                              accodianDetail
                              d-flex
                              flex-wrap flex-lg-nowrap
                              justify-content-between
                            "
                          >
                            <h5>{{ element.benefitName }}</h5>
                            <p>{{ element.coverageText }}</p>
                            <button
                              type="button"
                              class="btn btn-link modal_btn my-2 my-lg-0"
                              data-toggle="modal"
                              :data-target="
                                '#exampleModal' + idx + index + randomNo
                              "
                              @click="ModalClose(productCode)"
                            >
                              More Details
                            </button>
                            <!-- :data-target="'#'+ idx + index + randomNo" -->
                            <!-- :id="`${idx}` + index + randomNo" -->

                            <MoreDetailsModal
                              :randomNo="randomNo"
                              :productName="productName"
                              :provider="provider"
                              :coverages="coverages"
                              :coverageText="element.coverageText"
                              :coverageLongText="element.coverageLongText"
                              :purchaseUrl="purchaseUrl"
                              :benefitName="element.benefitName"
                              :benefitGroupName="element.benefitGroupName"
                              :certificateUrl="certificateUrl"
                              :code="
                                'exampleModal' +
                                `${idx}` +
                                `${index}` +
                                `${randomNo}`
                              "
                              :ModalVal="ModalVal"
                            >
                            </MoreDetailsModal>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
              <!-- Certificate URL PDF Document -->
              <div class="my-5">
                <h2 style="border-bottom: 2px solid rgb(218, 218, 218, 1)">
                  Definitions and Exclusions
                </h2>
                <a
                  :href="certificateUrl"
                  target="_blank"
                  download="proposed_file_name"
                  class="spc_certificate"
                  >Click here for full Definitions and Exclusions</a
                >
              </div>
            </div>
          </div>
          <!-- =================== Modal Footer =================== -->
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary btn_close"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Container from "../components/Container.vue";
import ButtonMain from "../components/ButtonMain.vue";
import ModalQuotes from "./ModalQuotes.vue";
import { mapActions } from "vuex";
import { AFFILIATE_ID } from "../app.config";
import MoreDetailsModal from "../components/MoreDetailsModal.vue";

export default {
  name: "OfferCard",
  components: { Container, ButtonMain, ModalQuotes, MoreDetailsModal },
  props: [
    "productName",
    "premium",
    "provider",
    "productLastPurchasedAt",
    "coverages",
    "productCode",
    "randomNo",
    "purchaseUrl",
    "certificateUrl",
    "compareStatus",
    "unselectStatus",
  ],

  data() {
    return {
      containerStyle: {
        margin: "20px auto",
        borderRadius: "20px",
        padding: "0.25rem 0px",
      },
      aid: AFFILIATE_ID,
      offersList: {
        productName: this.productName,
        premium: this.premium,
        provider: this.provider,
        productLastPurchasedAt: this.productLastPurchasedAt,
        coverages: this.coverages,
        productCode: this.productCode,
      },
      randomNo2: Math.random().toString().split(".").join(""),
      compare: this.compareStatus,
      unselect: this.unselectStatus,
      uniqCategory: [], // array to capture benefitGroupName
      subCategory: [], // array to store subcategories of benefitGroupName
      timeEdited: dayjs(this.productLastPurchasedAt).fromNow(),
      ModalVal: "",
    };
  },

  mounted() {
    var data = [];

    data = this.coverages.map((item) => {
      return item.benefitGroupName;
    });
    this.uniqCategory = [...new Set(data)];
    // console.log(this.uniqCategory)

    for (var i = 0; i < this.uniqCategory.length; i++) {
      this.subCategory.push(
        this.coverages.filter((item) => {
          return item.benefitGroupName == this.uniqCategory[i];
        })
      );
    }
    // console.log(this.subCategory)

    if (this.$store.state.cardsForCompare.length != 0) {
      this.$store.state.cardsForCompare.forEach((cardItem) => {
        // console.log("selected Card: ", cardItem)
        if (this.$el.id == cardItem) {
          // console.log("Hello there...")
          this.$data.compare = false;
          this.$data.unselect = true;
        }
      });
    }
  },

  methods: {
    ...mapActions(["getSelectedCardID"]),

    toggleText(e) {
      const compareBtn = document.querySelectorAll(".compareBtn");

      if (compareBtn.innerText == "Compare") {
        this.compare = false;
        this.unselect = true;
      } else if (compareBtn.innerText == "Unselect") {
        this.compare = true;
        this.unselect = false;
      }
    },

    checkNames(name) {
      if (
        name == "Cancel for Covid-19 Sickness" ||
        name == "Medical Coverage for Covid-19" ||
        name == "Trip Cancellation" ||
        name == "Trip Interruption" ||
        name == "Medical Evacuation & Repatriation" ||
        name == "Emergency Medical"
      ) {
        return true;
      } else {
        return false;
      }
    },

    ModalClose(e) {
      this.ModalVal = "firstModal" + e;
    },

    firstClick() {
      this.compare = false;
      this.unselect = true;
      this.$emit("cardClicked", this.productCode);
    },
  },
};
</script>

<style scoped>
@font-face {
  font-family: "BasierCircle-Regular";
  src: url("../assets/fonts/basiercircle-regular.otf") format("woff2"),
    url("../assets/fonts/BasierCircle-Regular.ttf") format("woff"),
    url("../assets/fonts/BasierCircle-Regular.woff") format("otf"),
    url("../assets/fonts/BasierCircle-Regular.woff2") format("ttf");
  font-weight: normal;
  font-style: normal;
}
.offer_box_title h1 {
  font-family: "BasierCircle-Regular", sans-serif;
  font-size: 22px;
  font-weight: 700;
  color: rgba(26, 26, 81, 1);
  margin-bottom: 0;
}
.offer_box_title_mob h1 {
  font-family: "BasierCircle-Regular", sans-serif;
  font-size: 18px;
  font-weight: 700;
  color: rgba(26, 26, 81, 1);
  margin-bottom: 0;
}
.offer_box_title_mob h2 {
  font-family: "BasierCircle-Regular", sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: rgba(26, 26, 81, 1);
  margin-bottom: 0;
}
#price_of_offer {
  font-family: "BasierCircle-Regular", sans-serif;
  /* font-size: 32px; */
  font-weight: 700;
  color: rgba(26, 26, 81, 1);
}
#price_of_offer_bold {
  font-family: "BasierCircle-Regular", sans-serif;
  /* font-size: 30px; */
  font-weight: 900;
  color: #6e6edf;
}

hr {
  border: 2px solid rgba(250, 250, 255, 1);
  width: 100%;
}

.card_details_left {
  padding-top: 6px;
}

.card_details_left h3 {
  font-family: "BasierCircle-Regular", sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: rgba(26, 26, 81, 1);
  margin-bottom: 0;
}
/* left-left-panel => card_details_left*/
.card_details_left p {
  font-family: "BasierCircle-Regular", sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: rgba(26, 26, 81, 1);
}
.center-title h3 {
  font-family: "BasierCircle-Regular", sans-serif;
  font-size: 18px;
  font-weight: 400;
  font-style: italic;
  color: rgba(26, 26, 81, 1);
}
.list-items-left {
  font-family: "BasierCircle-Regular", sans-serif;
  /* font-size: 14px; */
  font-weight: 400;
  line-height: 26px;
  color: rgba(26, 26, 81, 1);
}

.list-items-right {
  font-family: "BasierCircle-Regular", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  color: rgba(26, 26, 81, 1);
}

/* ================================== */
/* ---------- Media Query ------------ */

@media only screen and (max-width: 600px) {
  .sub-header {
    background-color: inherit;
  }
  .main-box {
    margin-top: 0;
  }
  .trip_container_div {
    margin-top: 0;
  }
  .background_color_tripflow {
    background-color: #fff;
  }
  /* .body {background-color: #fff;} */
  .btn-back {
    color: black;
    border: none;
    background-color: #fff;
    text-decoration: underline;
    padding: 10px 0px;
  }
  .progress_bar_div {
    width: 69%;
  }
  .sub_header_btn_div_1 {
    width: 40%;
  }
  .sub_header_btn_div_2 {
    width: 50%;
  }
  .acc_custom {
    width: 100%;
  }
  .item_details_div {
    width: 100%;
    gap: 0px;
  }
  .list-items-left {
    font-size: 12px;
    padding-left: 0%;
  }
  .list-items-right {
    font-size: 11px;
    padding-left: 5%;
    text-align: right;
  }
  .btn_poliyDetails_div {
    width: 48%;
  }
  .btn_buy_div {
    width: 48%;
  }
  .minimize_btn_div {
    width: 100%;
  }
  .inputrange1 {
    width: 69%;
  }
  .inputrange2 {
    width: 69%;
  }
  .input-group {
    width: 75%;
  }
  .checkout_timer {
    width: 100%;
  }
  .timer_div {
    background-color: rgba(234, 234, 252, 1) !important;
    border: none !important;
  }
  .back-btn-div {
    max-width: 65%;
  }
  .btn-group {
    gap: 25px;
  }
  .sub_header_btn {
    color: rgba(26, 26, 81, 1);
    border: none;
    background-color: #fff;
  }
  .sub_header_btn:hover {
    color: rgba(110, 110, 223, 1);
    background-color: none;
    border: none;
    box-shadow: none !important;
  }
  .modal_body_text {
    width: 100%;
  }
  .toolTip_wd {
    width: none;
  }

  .heading h1 {
    font-size: 24px;
    line-height: 35px;
  }
  .radio_btn {
    font-size: 22px;
    font-weight: 600;
    line-height: 28px;
  }
  .inputrange1_heading h1 {
    font-size: 50px;
    font-weight: 700;
  }
  .subheading-title h1 {
    font-size: 24px;
  }
  .left-side-heading h1 {
    font-size: 18px;
    font-weight: 500;
  }

  .grid_radioButtons {
    grid-template-columns: repeat(3, auto);
    grid-row-gap: 5%;
  }

  #price_of_offer_bold {
    font-size: 22px;
  }

  .card_footer h3 {
    font-size: 12px;
    margin: 1rem 0rem 0rem 1rem;
  }
  .card_footer p {
    font-size: 11px;
    margin-left: 1rem;
  }
}

@media only screen and (min-width: 600px) {
  .sub-header {
    background-color: #fff;
  }
  .main-box {
    margin-top: 100px;
  }
  .trip_container_div {
    margin-top: 100px;
  }
  .background_color_tripflow {
    background-color: rgba(234, 234, 252, 1);
  }
  /* .body {background-color: rgba(234, 234, 252, 1);} */
  .btn-back {
    text-decoration: none;
    color: #fff;
    background: #6e6edf;
    border: 1px solid #6e6edf;
    border-radius: 25px;
    padding: 10px 40px;
  }
  .progress_bar_div {
    width: 50%;
  }
  .sub_header_btn_div_1 {
    width: 40%;
  }
  .sub_header_btn_div_2 {
    width: 50%;
  }
  .acc_custom {
    width: 100%;
  }
  .item_details_div {
    width: 100%;
    gap: 0px;
  }
  .list-items-left {
    font-size: 12px;
  }
  .list-items-right {
    font-size: 14px;
    padding-left: 0%;
  }
  .btn_poliyDetails_div {
    width: 49%;
  }
  .btn_buy_div {
    width: 45%;
  }
  .minimize_btn_div {
    width: 100%;
  }
  .inputrange1 {
    width: 50%;
  }
  .inputrange2 {
    width: 50%;
  }
  .input-group {
    width: 50%;
  }
  .checkout_timer {
    width: 100%;
  }
  .timer_div {
    background-color: rgba(234, 234, 252, 1) !important;
    border: none !important;
  }
  .back-btn-div {
    max-width: 100%;
  }
  .btn-group {
    gap: 35px;
  }
  .sub_header_btn {
    color: rgba(110, 110, 223, 1);
    border: 1px solid rgba(234, 234, 252, 1);
    background-color: rgba(234, 234, 252, 1);
  }
  .modal_body_text {
    width: 65%;
  }
  .toolTip_wd {
    width: none;
  }

  .heading h1 {
    font-size: 35px;
    line-height: 46px;
  }
  .radio_btn {
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
  }
  .inputrange1_heading h1 {
    font-size: 60px;
    font-weight: 700;
  }
  .subheading-title h1 {
    font-size: 35px;
  }
  .left-side-heading h1 {
    font-size: 22px;
    font-weight: 700;
  }

  .grid_radioButtons {
    grid-template-columns: repeat(5, auto);
    grid-row-gap: 17%;
  }

  #price_of_offer_bold {
    font-size: 26px;
  }

  .card_footer h3 {
    font-size: 13px;
    margin: 1rem 0rem 0rem 1rem;
  }

  .card_footer p {
    font-size: 12px;
    margin-left: 1rem;
  }
}

@media only screen and (min-width: 768px) {
  .list-items-left {
    font-size: 12px;
  }
  .sub_header_btn_div_1 {
    width: 40%;
  }
  .sub_header_btn_div_2 {
    width: 50%;
  }
  .acc_custom {
    width: 48%;
  }
  .btn_confirm_purchase {
    max-width: 100%;
  }
  .item_details_div {
    width: 100%;
    gap: 0px;
  }
  .checkout_timer {
    width: 50%;
  }
  .timer_div {
    background-color: #fff !important;
  }
  .toolTip_wd {
    width: none;
  }
  #price_of_offer {
    font-size: 20px;
  }
  #price_of_offer_bold {
    font-size: 30px;
  }
}

@media only screen and (min-width: 992px) {
  .list-items-left {
    font-size: 14px;
  }
  .btn_confirm_purchase {
    max-width: max-content;
  }
  .item_details_div {
    width: 100%;
    gap: 0px;
  }
  .btn_poliyDetails_div {
    width: 100%;
  }
  .btn_buy_div {
    width: 100%;
  }
  .minimize_btn_div {
    width: 35%;
  }
  .toolTip_wd {
    width: 25%;
  }
  #price_of_offer {
    font-size: 27px;
  }
  .card_footer h3 {
    font-size: 13px;
    margin: 2rem 0rem 0rem 2rem;
  }
  .card_footer p {
    font-size: 12px;
    margin-left: 2rem;
  }
}

@media only screen and (min-width: 1200px) {
  #price_of_offer {
    font-size: 32px;
  }
}

.card_footer h3 {
  font-family: "BasierCircle-Regular", sans-serif;
  /* font-size: 13px; */
  font-weight: 400;
  line-height: 22px;
  color: rgba(26, 26, 81, 1);
  font-style: italic;
  /* margin: 1rem 0rem 0rem 2rem; */
}

.card_footer p {
  font-family: "BasierCircle-Regular", sans-serif;
  /* font-size: 12px; */
  font-weight: 400;
  line-height: 22px;
  color: rgba(26, 26, 81, 1);
  font-style: italic;
  /* margin-left: 2rem; */
  margin-bottom: 0rem;
}

/* ============= Modal Styles ================ */
.modal_body_heading h2 {
  font-family: "BasierCircle-Regular", sans-serif;
  font-size: 20px;
  font-weight: 700;
  color: rgba(26, 26, 81, 1);
}

.modal_body_heading h3 {
  font-family: "BasierCircle-Regular", sans-serif;
  font-size: 20px;
  font-weight: 700;
  color: rgba(26, 26, 81, 1);
}

.modal_body_text {
  font-family: "BasierCircle-Regular", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: rgba(26, 26, 81, 1);
  /* width: 65%; */
  text-align: justify;
}

.modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: center;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
  gap: 5%;
}

.modal-title {
  font-family: "BasierCircle-Regular", sans-serif;
  font-size: 24px;
  font-weight: 700;
  color: rgba(89, 89, 89, 1);
  text-align: center;
  width: 100%;
}

.modal-subtitle {
  font-family: "BasierCircle-Regular", sans-serif;
  font-size: 20px;
  font-weight: 700;
  color: rgba(89, 89, 89, 1);
  text-align: center;
  width: 100%;
}

.modal-body hr {
  border: 1px solid rgba(89, 89, 89, 0.5);
  width: 94%;
  background-color: rgba(89, 89, 89, 0.5);
}

.card-header {
  background-color: rgba(234, 234, 252, 0.5);
}
.btn-link {
  font-family: "BasierCircle-Regular", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: rgba(26, 26, 81, 1);
}

.btn-link:hover,
.btn-link:active,
.btn-link:focus {
  color: rgba(110, 110, 223, 1);
  outline: none;
  text-decoration: none;
  box-shadow: none;
}

.btn-link:active,
.btn-link:focus {
  color: rgba(26, 26, 81, 1);
  outline: none;
  text-decoration: none;
  box-shadow: none;
}

.accodianDetail h5 {
  font-family: "BasierCircle-Regular", sans-serif;
  /* font-size: 14px; */
  font-weight: 700;
  line-height: 20px;
  color: rgba(26, 26, 81, 1);
  margin-bottom: 0;
  /* width: 40%; */
}

.accodianDetail p {
  font-family: "BasierCircle-Regular", sans-serif;
  /* font-size: 14px; */
  font-weight: 400;
  line-height: 20px;
  color: rgba(26, 26, 81, 1);
  /* width: 60%; */
  margin-bottom: 0;
  /* margin-left: 15px; */
  white-space: pre-line;
}

.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1rem 1.25rem;
}

/* ================================== */
/* ---------- Media Query ------------ */

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .sub-header {
    background-color: inherit;
  }
  .main-box {
    margin-top: 0;
  }
  .trip_container_div {
    margin-top: 0;
  }
  .background_color_tripflow {
    background-color: #fff;
  }
  /* .body {background-color: #fff;} */
  .btn-back {
    color: black;
    border: none;
    background-color: #fff;
    text-decoration: underline;
    padding: 10px 0px;
  }
  .progress_bar_div {
    width: 69%;
  }
  .sub_header_btn_div_1 {
    width: 40%;
  }
  .sub_header_btn_div_2 {
    width: 50%;
  }
  .acc_custom {
    width: 100%;
  }
  .item_details_div {
    width: 100%;
    gap: 0px;
  }
  .list-items-right {
    font-size: 11px;
    padding-left: 5%;
    text-align: right;
  }
  .btn_poliyDetails_div {
    width: 51%;
  }
  .btn_buy_div {
    width: 45%;
  }
  .minimize_btn_div {
    width: 100%;
  }
  .inputrange1 {
    width: 69%;
  }
  .inputrange2 {
    width: 69%;
  }
  .input-group {
    width: 75%;
  }
  .checkout_timer {
    width: 100%;
  }
  .timer_div {
    background-color: rgba(234, 234, 252, 1) !important;
    border: none !important;
  }
  .back-btn-div {
    max-width: 65%;
  }
  .btn-group {
    gap: 25px;
  }
  .sub_header_btn {
    color: rgba(26, 26, 81, 1);
    border: none;
    background-color: #fff;
  }
  .sub_header_btn:hover {
    color: rgba(110, 110, 223, 1);
    background-color: none;
    border: none;
    box-shadow: none !important;
  }
  .modal_body_text {
    width: 100%;
  }
  .toolTip_wd {
    width: none;
  }

  .heading h1 {
    font-size: 24px;
    line-height: 35px;
  }
  .radio_btn {
    font-size: 22px;
    font-weight: 600;
    line-height: 28px;
  }
  .inputrange1_heading h1 {
    font-size: 50px;
    font-weight: 700;
  }
  .subheading-title h1 {
    font-size: 24px;
  }
  .left-side-heading h1 {
    font-size: 18px;
    font-weight: 500;
  }

  .grid_radioButtons {
    grid-template-columns: repeat(3, auto);
    grid-row-gap: 5%;
  }

  .accodianDetail h5 {
    font-size: 14px;
    width: 100%;
  }

  .accodianDetail p {
    font-size: 12px;
    width: 100%;
    margin-left: 0;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .sub-header {
    background-color: #fff;
  }
  .main-box {
    margin-top: 100px;
  }
  .trip_container_div {
    margin-top: 100px;
  }
  .background_color_tripflow {
    background-color: rgba(234, 234, 252, 1);
  }
  /* .body {background-color: rgba(234, 234, 252, 1);} */
  .btn-back {
    text-decoration: none;
    color: #fff;
    background: #6e6edf;
    border: 1px solid #6e6edf;
    border-radius: 25px;
    padding: 10px 40px;
  }
  .progress_bar_div {
    width: 50%;
  }
  .sub_header_btn_div_1 {
    width: 40%;
  }
  .sub_header_btn_div_2 {
    width: 50%;
  }
  .acc_custom {
    width: 100%;
  }
  .item_details_div {
    width: 100%;
    gap: 0px;
  }
  /* .list-items-left {
    font-size: 14px;
  } */
  .list-items-right {
    font-size: 14px;
    padding-left: 0%;
  }
  .btn_poliyDetails_div {
    width: 49%;
  }
  .btn_buy_div {
    width: 45%;
  }
  .minimize_btn_div {
    width: 100%;
  }
  .inputrange1 {
    width: 50%;
  }
  .inputrange2 {
    width: 50%;
  }
  .input-group {
    width: 50%;
  }
  .checkout_timer {
    width: 100%;
  }
  .timer_div {
    background-color: rgba(234, 234, 252, 1) !important;
    border: none !important;
  }
  .back-btn-div {
    max-width: 100%;
  }
  .btn-group {
    gap: 35px;
  }
  .sub_header_btn {
    color: rgba(110, 110, 223, 1);
    border: 1px solid rgba(234, 234, 252, 1);
    background-color: rgba(234, 234, 252, 1);
  }
  .modal_body_text {
    width: 65%;
  }
  .toolTip_wd {
    width: none;
  }

  .heading h1 {
    font-size: 35px;
    line-height: 46px;
  }
  .radio_btn {
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
  }
  .inputrange1_heading h1 {
    font-size: 60px;
    font-weight: 700;
  }
  .subheading-title h1 {
    font-size: 35px;
  }
  .left-side-heading h1 {
    font-size: 22px;
    font-weight: 700;
  }

  .grid_radioButtons {
    grid-template-columns: repeat(5, auto);
    grid-row-gap: 17%;
  }

  .accodianDetail h5 {
    font-size: 14px;
    width: 100%;
  }

  .accodianDetail p {
    font-size: 12px;
    width: 100%;
    margin-left: 0;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .sub_header_btn_div_1 {
    width: 40%;
  }
  .sub_header_btn_div_2 {
    width: 50%;
  }
  .acc_custom {
    width: 48%;
  }
  .btn_confirm_purchase {
    max-width: 100%;
  }
  .item_details_div {
    width: 100%;
    gap: 0px;
  }
  .checkout_timer {
    width: 50%;
  }
  .timer_div {
    background-color: #fff !important;
  }
  .toolTip_wd {
    width: none;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .btn_confirm_purchase {
    max-width: max-content;
  }
  .item_details_div {
    width: 100%;
    gap: 0px;
  }
  .btn_poliyDetails_div {
    width: 100%;
  }
  .btn_buy_div {
    width: 100%;
  }
  .minimize_btn_div {
    width: 35%;
  }
  .toolTip_wd {
    width: 25%;
  }

  .accodianDetail h5 {
    font-size: 14px;
    width: 25%;
  }

  .accodianDetail p {
    font-size: 14px;
    width: 75%;
    margin-left: 20px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

.rating__star {
  color: #dabd18b2;
}

.modal_btn {
  font-size: 15px !important;
  font-weight: 400;
  line-height: 16px;
}

.btn_close {
  background-color: #fff;
  border-radius: 25px;
  border: 1px solid rgba(110, 110, 223, 1);
  padding: 10px 25px;
  cursor: pointer;

  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: rgba(110, 110, 223, 1);

  display: flex;
  align-items: center;
  justify-content: center;
}

.btn_close:hover,
.btn_close:active,
.btn_close:focus {
  opacity: 0.8;
}

.spc_certificate {
  color: #4a25fae8;
  text-decoration: underline !important;
}

.modal-header a {
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: rgba(110, 110, 223, 1);
  text-align: center;
}
</style>