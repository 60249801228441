<template>
  <div>
      <div class="modal fade" id="searchInput" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Edit Search Criteria</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <!--======================== MODAL BODY =====================================-->
            <div class="modal-body" style="margin-bottom: 10rem">
              
              <!------------------------------------ New Section ------------------------------->
              <h2 class="mt-3">Trip Info</h2>
              <hr>

              <!-- Functional Calender: Departure and Return Date -->
              <div class="d-flex flex-wrap justify-content-between align-items-center my-4">
                  <div class="py-4 sizingEditSearch">
                      <h3 class="heading d-flex align-items-center" style="gap: 5px">
                        Leaving Home
                        <img
                          src="../assets/img/question-fill.svg"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Please enter starting date of your trip."
                        />
                      </h3>
                      <functional-calendar
                        :is-modal="true"
                        :is-date-picker="true"
                        v-model="calendarData1"
                        placeholder="Edit Departure Date"
                        :date-format="'mm/dd/yyyy'"
                        :sundayStart="true"
                        :disabledDates="['beforeToday']"
                        :isAutoCloseable='true'
                        :isTypeable='true'
                      >
                      </functional-calendar>
                  </div>
                  <div class="py-4 sizingEditSearch">
                      <h3 class="heading d-flex align-items-center" style="gap: 5px">
                        Returning Home
                        <img
                          src="../assets/img/question-fill.svg"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Please enter return date of your trip."
                        />
                      </h3>
                      <functional-calendar
                        :is-modal="true"
                        :is-date-picker="true"
                        v-model="calendarData2"
                        placeholder="Edit Return Date"
                        :date-format="'mm/dd/yyyy'"
                        :sundayStart="true"
                        :disabledDates="['beforeToday']"
                        :isAutoCloseable='true'
                        :isTypeable='true'
                      >
                      </functional-calendar>
                  </div>
              </div>

              <!-- Main Destination Selection -->
              <div class="my-4">
                  <h3 class="d-flex align-items-center" style="gap: 5px">
                    Main Destination
                    <img
                      src="../assets/img/question-fill.svg"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Please enter your destination country. If you are traveling multiple destinations, please just enter one."
                    />
                  </h3>
                  <form class="my-2">
                    <v-select
                      v-model="countryCode"
                      :options="countryList"
                      
                      :filter="fuseSearch"
                    ></v-select>
                  </form>
              </div>

              <!------------------------------------ New Section ------------------------------->
              <h2 class="mt-5">Traveler Info</h2>
              <hr>
              
              <!-- Country and Region of Residence -->
              <div>
                <!-- Residence Country -->
                <div class="my-4 py-2">
                    <h3 class="d-flex align-items-center" style="gap: 5px">
                      C‌ountry of Residence
                      <img
                        src="../assets/img/question-fill.svg"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Please enter your primary country of residence."
                      />
                    </h3>
                    <form class="my-2">
                      <v-select
                        v-model="countryCodeResidence"
                        :options="countryList"
                        @input="setName($event)"
                        :filter="fuseSearch"
                      ></v-select>
                    </form>
                </div>

                <!-- State of Residence -->
                <div class="my-4 py-2" v-show="statesCountrySelected">
                    <h3
                        for="formGroupExampleInput2"
                        class="d-flex align-items-center"
                        style="gap: 5px"
                      >
                        Your {{ getStatesData('text') }}
                        <img
                          src="../assets/img/question-fill.svg"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Please enter your primary state of residence."
                        />
                      </h3>

                      <v-select
                        :options="getStatesData('stateList')"
                        v-model="residentRegion"
                        :reduce="(region) => region.code"
                        :filter="fuseSearch"
                      ></v-select>
                </div>

                <!-- Citizenship -->
                <div class="my-4 py-2">
                    <h3 class="d-flex align-items-center" style="gap: 5px">
                      C‌itizenship
                      <img
                        src="../assets/img/question-fill.svg"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Please enter your country of citizenship"
                      />
                    </h3>
                    <form class="my-2">
                      <v-select
                        v-model="countryCodeCitizenship"
                        :options="countryList"
                        
                        :filter="fuseSearch"
                      ></v-select>
                    </form>
                </div>
                
                                                
                <!-- Number of Traverls -->
                <div class="my-4 py-2">
                    <h3 class="d-flex align-items-center" style="gap: 5px">
                      Number of Travelers
                      <img
                        src="../assets/img/question-fill.svg"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Please enter total number of travelers on this trip"
                      />
                    </h3>
                    <select class="custom-select manual_select_class" v-model="travelerNO" @change="checkTravNo">
                      <option selected>Select number of travelers</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                    </select>
                </div>
                <!-- Number of Traverls: Travelers Age -->
                <div v-show="travelerNO" class="my-4 py-4">
                    <h3 class="d-flex align-items-center" style="gap: 5px">
                      What are the ages of the travelers?
                      <img
                        src="../assets/img/question-fill.svg"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Please enter each traveler's age as of today. If you are traveling with a child under the age of 1, please enter 1."
                      />
                    </h3>
                    <div class="d-flex" style="gap : 15px">
                       
                        <div v-for="(number, index) in +travelerNO" :key="index" style="width: 70px">
                            <input
                              type="text"
                              v-model.number="age[index]"
                              class="inputField form-control manual_input_class no_icon"
                              v-bind:id="number"
                              :placeholder="number.age"
                              required
                              onkeypress="return /\d/.test(String.fromCharCode(((event||window.event).which||(event||window.event).which)));"
                            />
                        </div>
                    </div>
                    
                </div>
              </div>

              <!------------------------------------ New Section ------------------------------->
              <h2 class="mt-5">Trip Cost</h2>
              <hr>

              <div class="d-inline d-lg-flex justify-content-between my-4 ">
                  <!-- Edit Amount Deposit Date -->
                  <div class="my-4 my-lg-0 sizingEditSearch">
                      <h3 class="heading d-flex align-items-center" style="gap: 5px">
                        First Deposit Date
                        <img
                          src="../assets/img/question-fill.svg"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Please enter the date you made your first booking for this trip. This could be when you booked your flight, hotel or any trip activity. If you have not yet booked, please use today's date."
                        />
                      </h3>
                      <functional-calendar
                        :is-modal="true"
                        :is-date-picker="true"
                        v-model="calendarData3"
                        placeholder="Edit Amount Deposit Date"
                        :date-format="'mm/dd/yyyy'"
                        :disabledDates="['afterToday']"
                        :isAutoCloseable='true'
                      >
                      </functional-calendar>
                  </div>

                  <!-- Payments left Selection -->
                  <div class="my-4 my-lg-0 sizingEditSearch">
                      <h3 class="d-flex align-items-center" style="gap: 5px">
                        Payments Left
                        <img
                          src="../assets/img/question-fill.svg"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Please select Yes if you have any significant payments to be made towards your trip. Select No if your trip is paid in full."
                        />
                      </h3>
                      <select class="custom-select manual_select_class" v-model="paymentLeft">
                        <option selected>Select Payment Left Options</option>
                        <option value="false">No, trip is paid in full</option>
                        <option value="true">Yes</option>
                      </select>
                  </div>
              </div>
              
              <div class="d-inline d-lg-flex justify-content-between py-4" style="gap: 20px">
                  <!-- Trip Cancellation Coverage Option -->
                  <div class="my-4 my-lg-0 sizingEditSearch">
                    <h3 class="d-flex align-items-center" style="gap: 5px">
                      Cancellation Coverage
                      <img
                        src="../assets/img/question-fill.svg"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Please select 'Yes cover my trip cost' if you would like Trip Cancellation Coverage. This benefit provides reimbursement for prepaid and non-refundable trip payments if a trip is cancelled for a covered reason."
                      />
                    </h3>
                    <select class="custom-select manual_select_class" v-model="travelCost">
                      <option selected>Select Cancellation Coverage</option>
                      <option value='true'>Yes, cover my trip cost</option>
                      <option value='false'>No</option>
                    </select>
                  </div>
                  <!-- Total Cost of Trip -->
                  <div class="my-4 my-lg-0 sizingEditSearch">
                    <h3 class="d-flex align-items-center" style="gap: 5px">
                      Trip Cost Amount
                      <img
                        src="../assets/img/question-fill.svg"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Please enter your trip cost. You may insure all, some, or none of your prepaid and non-refundable trip expenses."
                      />
                    </h3>
                    <form>
                        <input
                            type="text"
                            class="form-control manual_input_class_2"
                            placeholder=""
                            v-model="AmountTyped"
                            onkeypress="return /\d/.test(String.fromCharCode(((event||window.event).which||(event||window.event).which)));"
                        />
                    </form>
                  </div>

              </div>

      
            </div>
            <!--======================== MODAL FOOTER =====================================-->
            <div id="searchParameters" class="modal-footer">
              <button  type="button" class="btn" data-dismiss="modal" @click="submitChanges">Update Search</button>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>

import { FunctionalCalendar } from "vue-functional-calendar";
import vSelect from "vue-select";
import { mapGetters, mapState } from "vuex";
import { mapActions } from 'vuex';
import { AFFILIATE_ID } from '../app.config';

export default {
    name: 'Subheader',
    components: {
        FunctionalCalendar,
        vSelect,
    },
    
    data() {
      var tripDetailsData = this.$store.state.tripDetails;
      const tripDetails = this.$store.state.tripDetails;
      const startDate = tripDetails.departureDate || '';
      const endDate = tripDetails.returnDate || '';

      const startDateSplit = startDate ? startDate.split('-') : [];
      const endDateSplit = endDate ? endDate.split('-') : [];

      const dateRange = {
        start: startDate ? `${startDateSplit[1]}/${startDateSplit[2]}/${startDateSplit[0]}` : '',
        end: endDate ? `${endDateSplit[1]}/${endDateSplit[2]}/${endDateSplit[0]}` : '',
      };
      return {
        calendarData1: {
          currentDate: new Date(),
          selectedDate: dayjs(tripDetailsData.departureDate).format('MM/DD/YYYY'),
          dateRange: {
            start: "",
            end: "",
          },    
          multipleDateRange: [],
        },
        startDate, // starting date formatted
        endDate, // ending date formatted                                                                    // departure date from calender 
        calendarData2: {
          currentDate: new Date(),
          selectedDate: dayjs(tripDetailsData.returnDate).format('MM/DD/YYYY'),
          dateRange: {
            start: "",
            end: "",
          },
          multipleDateRange: [],
        }, 
        calendarData3: {
          currentDate: new Date(),
          selectedDate:  dayjs(tripDetailsData.depositDate).format('MM/DD/YYYY'),
          selectedDateTime: false,
          selectedHour: "00",
          selectedMinute: "00",
          selectedDatesItem: "",
          selectedDates: [],
          dateRange: {
            start: "",
            end: "",
          },    
          multipleDateRange: [],
        },                                                                    // amount deposit date date from calender
        countryCode             : tripDetailsData.destinationCountryCode,      // data code for destination country selection
        countryCodeResidence    : tripDetailsData.residentCountryCode,         // data code for residence country selection
        countryCodeCitizenship  : tripDetailsData.citizenCountryCode,          // data code for citizenship country selection
        residentRegion          : tripDetailsData.residentRegionCode,          // v-model to track state/province                                                   // data code for US States selected
        travelerNO : tripDetailsData.travelers
          ? tripDetailsData.travelers.length <= 10
            ? tripDetailsData.travelers.length
            : 10
          : '',                                                                // no of travelers of selected
        age : tripDetailsData.travelers.length 
            ? tripDetailsData.travelers.map((t) => t.age) : [],                // array to store ages of traveler
        travelCost : 'true',                                                   // travel cost show/hide based on user selection
        AmountTyped : tripDetailsData.tripCost,                                // total cost trip amount
        paymentLeft : tripDetailsData.hasPaymentsLeft,                         // payment left
        unFormatedAmount : tripDetailsData.tripCost,
      };
    },

    beforeMount() {
        this.$nextTick(function () {
          if(this.AmountTyped){
              this.AmountTyped = this.AmountTyped.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }
        })
    },

    mounted(){
      if(this.countryCode){
        // console.log("now run function...")
        const countryArr = []
        const countryResidenceArr = []
        const citizenshipArr = []

        // loop to identify destination country --------------
        this.countryList.forEach((item) => {
          if(item.code === this.countryCode)
            countryArr.push(item)
        })
        if(countryArr.length == 1){
          this.countryCode = countryArr[0]
        }
        if(countryArr.length > 1){
          this.countryCode = countryArr[2]
        }

        // loop to identify resident country ------------------
        this.countryList.forEach((item) => {
          if(item.code === this.countryCodeResidence)
            countryResidenceArr.push(item)
        })
        if(countryResidenceArr.length == 1){
          this.countryCodeResidence = countryResidenceArr[0]
        }
        if(countryResidenceArr.length > 1){
          this.countryCodeResidence = countryResidenceArr[2]
        }

        // loop to identify citizenship -------------------------
        this.countryList.forEach((item) => {
          if(item.code === this.countryCodeCitizenship)
            citizenshipArr.push(item)
        })
        if(citizenshipArr.length == 1){
          this.countryCodeCitizenship = citizenshipArr[0]
        }
        if(citizenshipArr.length > 1){
          this.countryCodeCitizenship = citizenshipArr[2]
        }
        
      }

      if(this.paymentLeft == true){
        // console.log("the value of paymentLeft: ", this.paymentLeft)
        this.paymentLeft == 'true'
      }
      else if(this.paymentLeft == false){
        // console.log("the value of paymentLeft: ", this.paymentLeft)
        this.paymentLeft == 'false'
      }
    },
    
    watch: {
      countryCodeResidence(countryCode) {
        this.countryCodeCitizenship = countryCode;
        // this.residentRegion = null;
      },

      // function to add seprator in numbers
      AmountTyped(newValue) {
        if(newValue == '0'){
          // console.log(newValue)
          this.travelCost = 'false'
        }
        
        const result = newValue.replace(/\D/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.AmountTyped = result
        this.unFormatedAmount = this.AmountTyped.replace(/\,/g,'')
        // Vue.nextTick(() => this.price = result);
      },

    },

    computed: {
      ...mapGetters({
        countryList: "mappedCountries",
        statesList : 'mappedStates',
        provinceList : 'mappedProvince'
      }),

      ...mapState(['tripDetails']),

      // method to display this block when country selected is US or CA
      statesCountrySelected() {
        return this.countryCodeResidence.code === 'US' || this.countryCodeResidence.code === 'CA';
      },

      invalidSelection() {
        return this.statesCountrySelected
          ? !(this.residentCountry && this.residentRegion && this.citizenship)
          : !(this.residentCountry && this.citizenship);
      },

    },

    methods: {
      ...mapActions(['getQoutes', 'getCardsForCompare']),

      getStatesData(type = 'text') {
        switch (type) {
          case 'text':
            if (this.countryCodeResidence.code === 'CA') return 'province';
            return 'state';

          case 'stateList':
            if (this.countryCodeResidence.code === 'CA') return this.provinceList;
            return this.statesList;

          case 'hasStates':
            if (this.countryCodeResidence.code === 'US') return true;
            return false;
        }
      },

      fuseSearch(options, search) {
        return options.filter((state) => (state.label.toLowerCase().startsWith(search.toLowerCase())) )
      },

      // if user changes no-of-travlers, all data in age is erased
      checkTravNo() {
        // console.log("Traveler No: ", this.travelerNO)
        // console.log(this.age)
        // console.log(this.age.filter(Boolean))
        if(this.age.length > (this.travelerNO - 1)){
          // console.log("Decreasing no of travelers...")
          // console.log("age.length", this.age.length)
          this.age = this.age.splice(0, +this.travelerNO)
          // console.log("updated age arr: ", this.age)
        }
        // this.age = []
      },

      setName(name){
        this.residentRegion = null;
      },

      submitChanges() {
      // Updating results now...
      var startDate     = dayjs(this.calendarData1.selectedDate).format('YYYY-MM-DD')
      var endDate       = dayjs(this.calendarData2.selectedDate).format('YYYY-MM-DD')
      var depositDate   = dayjs(this.calendarData3.selectedDate).format('YYYY-MM-DD')
      // var regionCode = null
      // regionCode = this.residentRegion  
      
      
      var ageArray = this.age.map((item) => {
        var ageInt = parseInt(item);
        return { age: ageInt };
      });

      if(this.paymentLeft == 'true' || this.paymentLeft === true){
        // console.log("payment is set to true: payment remaining")
        var isTrueSet = true
      }
      else if(this.paymentLeft == 'false' || this.paymentLeft === false){
        // console.log("payment is set to false: no payment remaining")
        var isTrueSet = false
      }
   
      this.$store.commit('setTripDetails', {
        departureDate: startDate,
        returnDate: endDate,
        destinationCountryCode : this.countryCode.code,
        residentCountryCode: this.countryCodeResidence.code,
        citizenCountryCode : this.countryCodeCitizenship.code,
        residentRegionCode : this.residentRegion,
        travelers: ageArray,
        depositDate  : depositDate,
        tripCost : +this.unFormatedAmount,
        hasPaymentsLeft: isTrueSet,
        sortBy:"SALES_DESC",
        affiliateReferralCode: AFFILIATE_ID,
      });

      this.getQoutes().then(res => {
          this.$router.push({ path: `/quotes/${this.$store.state.quoteNo.quote}` });
          // console.log("path must change...", this.$store.state.quoteNo.quote)
      })

      }
    }
    
}
</script>

<style scoped>
  .modal-body h2 {
    font-family: 'BasierCircle-Regular', sans-serif;
    font-size: 22px;
    font-weight: 600;
    color: rgba(26, 26, 81, 1);
  }

  .modal-body h3 {
    font-family: 'BasierCircle-Regular', sans-serif;
    font-size: 18px;
    font-weight: 600;
    color: rgba(26, 26, 81, 1);
  }

  .manual_select_class {
    border: 2px solid rgba(26, 26, 81, 0.07);
    border-radius: 10px;
    padding: 15px 15px;
    height: 60px;

    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: rgba(26, 26, 81, 1);

    /* background: rgba(250, 250, 255, 1) url("../assets/img/search_icon.svg") right
      0.75rem center/30px 18px no-repeat; */
    background-color: rgba(250, 250, 255, 1);
  }

  .manual_select_class:focus,
  .manual_select_class:active,
  .manual_select_class:hover {
    box-shadow: none !important;
    border: 2px solid rgba(26, 26, 81, 0.07);
  }

  .manual_input_class_2 {
    border: 2px solid rgba(26, 26, 81, 0.07);
    border-radius: 10px;
    padding: 15px 15px;
    height: 60px;

    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: rgba(26, 26, 81, 1);

    background-color: rgba(250, 250, 255, 1);
  }

  .manual_input_class_2:focus,
  .manual_input_class_2:active,
  .manual_input_class_2:hover {
    box-shadow: none !important;
    border: 2px solid rgba(26, 26, 81, 0.07);
  }
  
  .modal-body hr {
    border: 1px solid rgba(89, 89, 89, 0.5);
    width: 100%;
    background-color: rgba(89, 89, 89, 0.5);
  } 

  #searchParameters button {
    background-color: #fff;
    border-radius: 25px;
    border: 1px solid rgba(110, 110, 223, 1);
    padding: 10px 25px;
    cursor: pointer;

    font-family: 'DM Sans', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: rgba(110, 110, 223, 1);
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 100%; */
}

#searchParameters button:hover,
#searchParameters button:active,
#searchParameters button:focus {
  opacity: 0.8;
}
  
  @media only screen and (max-width: 600px){
    .sizingEditSearch {width: 100%;}
    #searchParameters button {width: 100%;}
  }
  @media only screen and (min-width: 600px){
    .sizingEditSearch {width: 100%;}
    #searchParameters button {width: 100%;}
  }
  @media only screen and (min-width: 992px){
    .sizingEditSearch {width: 45%;}
    #searchParameters button {width: 25%;}
  }


</style>



