<template>
  <div>
      
      <div class="modal fade"  :id="code" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-scrollable modal-xl">
          <div class="modal-content">
            <!-- ================== Modal Header ==================== -->
            <div class="modal-header">
                            
              <div class="w-100">
                  <div class="d-flex justify-content-between w-100">
                      <img :src="provider.mediumLogoUrl" alt="company logo" />
                      <button type="button" class="close" aria-label="Close" @click="ModalOpen(code)">
                        <span aria-hidden="true">&times;</span>
                      </button>
                  </div>
                  <div class="my-3">
                      <h5 class="modal-title" id="exampleModalLabel">{{ provider.name }}</h5>
                      <h6>{{ productName }}</h6>
                      <h6 class="my-3">{{ benefitName }}</h6>
                  </div>
                  
              </div>
            </div>
            <!-- ================== Modal Body ==================== -->
            <div class="modal-body">
                <!-- Section: 1 -->
                <div class="mt-3 mb-5">
                  <h2>{{ benefitGroupName }}</h2>
                  <div class="modal_subBody d-flex flex-wrap flex-lg-nowrap justify-content-between">
                    <p>{{ benefitName }}</p>
                    <p>{{ coverageText }}</p>
                  </div>
                </div>
                <!-- Section: 2 -->
                <div class="my-5">
                  <h2>What is {{ benefitName }}?</h2>
                  <!-- Looping here to generate data from $store.Benefits -->
                  <div v-for="(item, index) in benefitsGroup" :key="index" v-if="item.name == benefitGroupName ">
                    <!-- Internal loop of Benebifts  -->
                    <div v-for="(subItem, idx) in item.benefits" :key="idx" v-if="subItem.name == benefitName ">
                        <!-- {{ item.name : subItem.name }} -->
                        <!-- {{ subItem.description }} -->
                        <p v-html="subItem.description"></p>
                    </div>
                  </div>
                </div>
                <!-- Section: 3 -->
                <div class="my-5">
                  <h2>What I am covered for?</h2>
                  <div>
                    <p v-html="coverageLongText"></p>
                  </div>
                </div>
                <!-- Section: 4 -->
                <div class="my-5">
                  <h2>Definitions and Exclusions</h2>
                  <a :href="certificateUrl" target="_blank" download="proposed_file_name" class="spc_certificate">Click here for full Definitions and Exclusions</a>
                </div>
            </div>
            <!-- ================== Modal Footer ==================== -->
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary btn_close" @click="ModalOpen(code)" >Close</button>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import HeaderTop from './HeaderTop.vue'
export default {
  name: 'MoreDetailsModal',
  components : { HeaderTop, },
  props: [
    'productName',
    'provider',
    'coverages',
    'coverageText',
    'coverageLongText',
    'productCode',
    'randomNo',
    'code',
    'purchaseUrl',
    'benefitName',
    'benefitGroupName',
    'certificateUrl',
    'ModalVal'
  ],
  data() {
    return {
      benefitsGroup : this.$store.state.benefits.benefitGroups,
    }
  },

  methods : {
    ModalOpen(f) {
      var data = document.getElementById(f)
      $('#'+this.ModalVal).modal('show')
      $('#'+f).modal('hide')
    },
  }
}
</script>

<style scoped>
    .modal-body h2 {
      font-family: 'BasierCircle-Regular', sans-serif !important;
      font-size: 20px;
      font-weight: 600;
      color: rgba(26, 26, 81, 1);
      padding-bottom: 5px;
      margin-bottom: 15px;
      border-bottom: 2px solid rgb(218, 218, 218) !important;
    }

    .modal-header h5 {
      font-family: 'BasierCircle-Regular', sans-serif;
      font-size: 22px;
      font-weight: 700;
      color: rgba(89, 89, 89, 1);
      text-align: center;
      width: 100%;
    }

    .modal-header h6 {
      font-family: 'BasierCircle-Regular', sans-serif;
      font-size: 20px;
      font-weight: 700;
      color: rgba(89, 89, 89, 1);
      text-align: center;
      width: 100%;
    }

    .btn_close {
      background-color: #fff;
      border-radius: 25px;
      border: 1px solid rgba(110, 110, 223, 1);
      padding: 10px 25px;
      cursor: pointer;

      font-family: 'DM Sans', sans-serif;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      color: rgba(110, 110, 223, 1);
      
      display: flex;
      align-items: center;
      justify-content: center;
     
    }

    .btn_close:hover,
    .btn_close:active,
    .btn_close:focus {
      opacity: 0.8;
    }

    .spc_certificate {
      color: #4a25fae8;
      text-decoration: underline !important;
    }

    @media only screen and (max-width: 600px){
      .modal-body h2 {font-size: 16px;}
      .modal_subBody p:nth-child(even) {width: 100%;}
      .modal_subBody p:nth-child(odd) {width: 100%;}
    }
    @media only screen and (min-width: 600px){
      .modal-body h2 {font-size: 16px;}
      .modal_subBody p:nth-child(even) {width: 75%;}
      .modal_subBody p:nth-child(odd) {width: 25%;}
    }
    @media only screen and (min-width: 992px){
      .modal-body h2 {font-size: 20px;}
      .modal_subBody p:nth-child(even) {width: 75%;}
      .modal_subBody p:nth-child(odd) {width: 25%;}
    }
</style>