<template>
  <div>
    <HeaderTop></HeaderTop>
    <Background>
      <div class="container">
        <!------------------ Back Button: Just in Case Needed ----------------------->
        <!-- <div class="d-flex justify-content-start align-items-center w-100 my-4">
          <router-link :to="{ name: 'TripDetails6' }">
            <ButtonMain variant="back">
              <div class="d-flex flex-nowrap align-items-center" style="gap:5px">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 2c5.52 0 10 4.48 10 10s-4.48 10-10 10S2 17.52 2 12 6.48 2 12 2zm0 9V8l-4 4 4 4v-3h4v-2h-4z" fill="rgba(23,217,18,1)"/></svg>
                  <span class="back_btn">Back</span>
              </div>
            </ButtonMain>
          </router-link>
        </div> -->

        <!------------------- Subheader ------------------------>
        <div v-if="this.$store.state.quotes.data">
          <div v-if="this.$store.state.quotes.data.createSingleTripQuote">
            <Subheader
              :total="
                this.$store.state.quotes.data.createSingleTripQuote
                  .productQuotes.length
              "
            ></Subheader>
          </div>

          <div v-if="this.$store.state.quotes.data.quote">
            <Subheader
              :total="this.$store.state.quotes.data.quote.productQuotes.length"
            ></Subheader>
          </div>
        </div>
        <div v-else>
          <Subheader :total="null"></Subheader>
        </div>

        <!-- Corona Special Filter -->
        <div>
          <CoronaFilter></CoronaFilter>
        </div>

        <!-- Main Body -->
        <div class="row">
          <!--========================== Left Side : Filter Box ========================== -->
          <div class="col-12 col-lg-4">
            <FilterSearch></FilterSearch>
          </div>

          <!--========================== Right Side : Offer Card ==========================-->
          <div class="col-12 col-lg-8">
            <!-- While data is loading -->
            <div v-if="$store.state.loadingQuotes">
              <Container
                :margin="containerStyle.margin"
                :borderRadius="containerStyle.borderRadius"
                :padding="containerStyle.padding"
              >
                <h2 class="loading_title">Loading...123</h2>
              </Container>
            </div>
            <!-- When data is completely loaded -->
            <div v-else>
              <!-- If there is no error in input and cards are ready to be displayed -->
              <div v-if="$store.state.errors == null">
                <!-- Data fetched organically from user input -->
                <div v-if="$store.state.quotes.data.createSingleTripQuote">
                  <!-- If there are cards to display -->
                  <div
                    v-if="
                      $store.state.quotes.data.createSingleTripQuote
                        .productQuotes.length >= 0
                    "
                  >
                    <div
                      v-for="(card, index) in this.$store.state.quotes.data
                        .createSingleTripQuote.productQuotes"
                      :key="index"
                    >
                      <OfferCard
                        v-bind="card"
                        :id="card.productCode"
                        :randomNo="Math.random().toString().split('.').join('')"
                        :compareStatus="true"
                        :unselectStatus="false"
                        class="offerCard"
                        @cardClicked="clickedCard"
                        @cardUNClicked="removeCard"
                        ref="offerCard"
                      />
                    </div>
                  </div>
                  <!-- If there are no cards to display -->
                  <div
                    v-if="
                      $store.state.quotes.data.createSingleTripQuote
                        .productQuotes.length == 0
                    "
                  >
                    <Container
                      :margin="containerStyle.margin"
                      :borderRadius="containerStyle.borderRadius"
                      :padding="containerStyle.padding"
                    >
                      <p class="error_msg">
                        Unfortunately, based on your trip information of filter
                        selections, there are no policies available with the
                        coverage you are looking for. Please edit your search or
                        remove one or more of your filter selections to view
                        available policies.
                      </p>
                    </Container>
                  </div>
                </div>
                <!-- Data fetched via quote no -->
                <div v-if="this.$store.state.quotes.data.quote">
                  <!-- If there are cards to display -->
                  <div
                    v-if="
                      this.$store.state.quotes.data.quote.productQuotes
                        .length >= 0
                    "
                  >
                    <div
                      v-for="(card, index) in this.$store.state.quotes.data
                        .quote.productQuotes"
                      :key="index"
                    >
                      <OfferCard
                        v-bind="card"
                        :id="card.productCode"
                        :randomNo="Math.random().toString().split('.').join('')"
                        :compareStatus="true"
                        :unselectStatus="false"
                        class="offerCard"
                        @cardClicked="clickedCard"
                        @cardUNClicked="removeCard"
                        ref="offerCard"
                      />
                    </div>
                  </div>
                  <!-- If there are no cards to display -->
                  <div
                    v-if="
                      this.$store.state.quotes.data.quote.productQuotes
                        .length == 0
                    "
                  >
                    <Container
                      :margin="containerStyle.margin"
                      :borderRadius="containerStyle.borderRadius"
                      :padding="containerStyle.padding"
                    >
                      <p class="error_msg">
                        Unfortunately, based on your trip information of filter
                        selections, there are no policies available with the
                        coverage you are looking for. Please edit your search or
                        remove one or more of your filter selections to view
                        available policies.
                      </p>
                    </Container>
                  </div>
                </div>
              </div>

              <!-- If there is error in input -->
              <div v-if="$store.state.errors != null">
                <Container
                  :margin="containerStyle.margin"
                  :borderRadius="containerStyle.borderRadius"
                  :padding="containerStyle.padding"
                >
                  <h2 class="error_heading">Error fetching quotes</h2>
                  <ul style="margin-bottom: 2rem">
                    <li
                      class="error_msg text-danger mb-1"
                      v-for="(item, idx) in $store.state.errors"
                      :key="idx"
                    >
                      {{ item.message }}
                    </li>
                  </ul>
                  <div id="editSearch" class="d-flex justify-content-center">
                    <button data-toggle="modal" data-target="#searchInput">
                      Edit Search
                    </button>
                  </div>
                </Container>
              </div>
            </div>
          </div>
        </div>

        <!---------- Black Box Selection----------->
        <div v-show="selectionBox" class="stickySelectionBox">
          <div class="selection_box">
            <p>Select up to 3 policies to compare</p>
            <div class="d-flex my-3 my-lg-0" style="gap: 10px">
              <div v-for="(total, index) in selectedCardsCompare" :key="index">
                <img
                  class="tickMark"
                  src="../assets/img/tick_mark.svg"
                  alt="selected tick mark"
                />
              </div>
            </div>
            <!-- Buttons div -->
            <div class="d-flex flex-wrap my-3 my-lg-0" style="gap: 15px">
              <div>
                <ButtonMain
                  style="width: 100%"
                  variant="compare"
                  @btnClicked="toComparePage"
                >
                  Compare
                </ButtonMain>
              </div>
              <div>
                <ButtonMain
                  style="width: 100%"
                  variant="compare"
                  @btnClicked="clearCompare"
                >
                  Clear All
                </ButtonMain>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Background>
  </div>
</template>

<script>
import HeaderTop from "../components/HeaderTop.vue";
import Background from "../components/Background.vue";
import ButtonMain from "../components/ButtonMain.vue";
import Subheader from "../components/Subheader.vue";
import CoronaFilter from "../components/CoronaFilter.vue";
import FilterSearch from "../components/FilterSearch.vue";
import OfferCard from "../components/OfferCard.vue";
import Container from "../components/Container.vue";
import { mapActions } from "vuex";
import { mapGetters } from "vuex";
import { mapMutations } from "vuex";

export default {
  components: {
    HeaderTop,
    Background,
    ButtonMain,
    Subheader,
    OfferCard,
    FilterSearch,
    Container,
    CoronaFilter,
  },

  data() {
    return {
      containerStyle: {
        margin: "20px auto",
        borderRadius: "20px",
        padding: "1.5rem",
      },
      selectionBox: false, // boolean logic to display/hide comparasion selection box
      counter: 0, // counter to track no of cards selected for comparison
      selectedCardIndex: [], // variable used in selecting cards for comparison
      offerCardData: [],
      sortedResults: [], // variable to track price sortings
      selectedCardsCompare: [], // variable to store selected cards ids for compare page
      btnDisabled: true,
      filterList: [], // unique filter list to generate filters block
    };
  },

  mounted() {
    // Cards are pushed back to local array from Store-------
    this.$store.state.cardsForCompare.forEach((item) => {
      this.selectedCardsCompare.push(item);
    });

    // Now bottom block must appear and highlight cards now-----------
    if (this.selectedCardsCompare.length > 0) {
      // console.log("local Array", this.selectedCardsCompare)
      this.selectionBox = true;
    }

    // checking if data is fetched from user-input or via params provided in url
    if (this.$store.state.quoteNo) {
      // console.log("data loaded from user-input")
      return;
    } else {
      // console.log("data loaded based on params provided")
      if (this.$route.params) {
        this.$store.commit("setQuoteNo", { quote: `${this.$route.params.id}` });
        // console.log(this.$store.state.tripDetails)
        this.getQoutesAgain();
      }
    }
  },

  computed: {
    ...mapGetters(
      {
        // filtersList: "readFilters",
        stepsCompleted: "stepsCompleted",
      },
      ["comparedQuotes", "comparedQuotesByNo"]
    ),
  },

  methods: {
    ...mapActions(["getQoutes", "getCardsForCompare", "getQoutesAgain"]),

    // method to display black box of selected items and select cards for comparison
    clickedCard(e) {
      this.selectionBox = true;
      // console.log("value from click cardzzz: ", e)
      if (e != undefined) {
        this.selectedCardsCompare.push(e);
        this.getCardsForCompare(this.selectedCardsCompare);
      }

      this.selectionBox = true;

      if (this.selectedCardsCompare.length <= 3) {
        const card = document.querySelectorAll(".offerCard");

        card.forEach((item) => {
          // console.log(item)
          // console.log(item.id)
          // if (item.id == e) {
          //   console.log("am i in?")
          //   if (item.firstElementChild.firstElementChild.style.border == '') {
          //     // console.log("changing border...")
          //     item.firstElementChild.firstElementChild.classList.add(
          //       'selectedBlueBox'
          //     );
          //     item.firstElementChild.firstElementChild.style.border =
          //       '3px solid rgba(110, 110, 223, 1)';
          //     this.counter += 1;
          //     // console.log("counter value: ", this.counter)
          //     this.selectedCardIndex.push(e);
          //     // console.log(this.selectedCardIndex)
          //   } else if (
          //     item.firstElementChild.firstElementChild.classList.contains(
          //       'selectedBlueBox'
          //     )
          //   ) {
          //     // console.log("changing back border...")
          //     item.firstElementChild.firstElementChild.classList.remove(
          //       'selectedBlueBox'
          //     );
          //     item.firstElementChild.firstElementChild.style.border = '';
          //     this.counter -= 1;
          //     // console.log("counter value: ", this.counter)
          //     this.selectedCardIndex = this.selectedCardIndex.filter(
          //       (number) => {
          //         return number != e;
          //       }
          //     );
          //     // console.log(this.selectedCardIndex)
          //   }
          //   // item.firstElementChild.firstElementChild.classList.toggle("selectedBlueBox")
          // }
        });
      } else {
        alert("can not select more than 3 options...");
      }

      if (this.selectedCardsCompare.length == 0) {
        this.selectionBox = false;
      }
    },
    // method to unclick cards selected for comparison
    removeCard(e) {
      // console.log("remove card: ", e)
      this.selectedCardsCompare = this.selectedCardsCompare.filter(
        (element) => {
          return element != e;
        }
      );
      this.getCardsForCompare(this.selectedCardsCompare);
      const card = document.querySelectorAll(".offerCard");
      card.forEach((item) => {
        // if (item.id == e) {
        //   if (item.firstElementChild.firstElementChild.style.border == '') {
        //     // console.log("changing border...")
        //     item.firstElementChild.firstElementChild.classList.add(
        //       'selectedBlueBox'
        //     );
        //     item.firstElementChild.firstElementChild.style.border =
        //       '3px solid rgba(110, 110, 223, 1)';
        //     this.counter += 1;
        //     // console.log("counter value: ", this.counter)
        //     this.selectedCardIndex.push(e);
        //     // console.log(this.selectedCardIndex)
        //   } else if (
        //     item.firstElementChild.firstElementChild.classList.contains(
        //       'selectedBlueBox'
        //     )
        //   ) {
        //     // console.log("changing back border...")
        //     item.firstElementChild.firstElementChild.classList.remove(
        //       'selectedBlueBox'
        //     );
        //     item.firstElementChild.firstElementChild.style.border = '';
        //     this.counter -= 1;
        //     // console.log("counter value: ", this.counter)
        //     this.selectedCardIndex = this.selectedCardIndex.filter((number) => {
        //       return number != e;
        //     });
        //     // console.log(this.selectedCardIndex)
        //   }
        //   // item.firstElementChild.firstElementChild.classList.toggle("selectedBlueBox")
        // }
      });
    },

    // method to push selected card to state(cardsForCompare) and take to compare page
    toComparePage() {
      // this.getCardsForCompare(this.selectedCardsCompare)
      this.$router.push({ name: "Compare" });
    },

    clearCompare() {
      this.selectedCardsCompare = [];
      this.getCardsForCompare(this.selectedCardsCompare);
      this.selectionBox = false;
      // this.getQoutes();
      // console.log(this.$refs.offerCard)
      this.$refs.offerCard.forEach((card) => {
        card.compare = true;
        card.unselect = false;
      });
    },
  },
};
</script>

<style scoped>
.back_btn {
  font-family: "BasierCircle-Regular", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: rgba(26, 26, 81, 1);
}
.back_btn:hover,
back_btn:focus,
back_btn:active {
  font-family: "BasierCircle-Regular", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: rgba(26, 26, 81, 1);
}

.subheading-title h1 {
  font-family: "BasierCircle-Regular", sans-serif;
  font-weight: 600;
  color: rgba(26, 26, 81, 1);
}

@font-face {
  font-family: "BasierCircle-Regular";
  src: url("../assets/fonts/basiercircle-regular.otf") format("woff2"),
    url("../assets/fonts/BasierCircle-Regular.ttf") format("woff"),
    url("../assets/fonts/BasierCircle-Regular.woff") format("otf"),
    url("../assets/fonts/BasierCircle-Regular.woff2") format("ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "BasierCircle-Regular";
  src: url("../assets/fonts/BasierCircle-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

.left-side-heading h1 {
  font-family: "BasierCircle-Regular", sans-serif;
  font-weight: 600;
  color: rgba(26, 26, 81, 1);
}

.stickySelectionBox {
  position: sticky;
  bottom: 10px;
  z-index: 100;
}

.selection_box {
  background-color: #313131;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "BasierCircle-Regular", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  padding: 25px;
  flex-wrap: wrap;
}

.selection_box p {
  margin-bottom: 0;
  font-style: italic;
}

.selectedBlueBox {
  border: 3px solid rgba(110, 110, 223, 1) !important;
  background-color: teal;
}

.tickMark {
  width: 26px;
  height: 26px;
}

.error_heading {
  font-family: "BasierCircle-Regular", sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: rgba(26, 26, 81, 1);
}

#editSearch button {
  background-color: rgba(110, 110, 223, 1);
  border-radius: 10px;
  border: none;
  padding: 10px 25px;
  cursor: pointer;
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: rgba(255, 255, 255, 1);
}

#editSearch button:hover,
#editSearch button:active,
#editSearch button:focus {
  opacity: 0.8;
}

.loading_title {
  font-family: "BasierCircle-Regular", sans-serif;
  font-size: 20px;
  font-weight: 700;
  color: rgba(26, 26, 81, 1);
  width: 100%;
  text-align: start;
}
</style>
