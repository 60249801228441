import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

const TOTAL_STEPS = 6;

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    countries: [],
    regions: [],
    tripDetails: {},
    quotes: [],
    errors: [],
    selectedCardID: [],
    cardsForCompare: [],
    loadingQuotes: false,
    filters: [],
    sportsFilters : [],
    stepsCount: 0,
    benefits: [],
    quoteNo:''
  },
  getters: {
    mappedCountries(state) {
      return state.countries.map(s => ({ label: s.name, code: s.iso3166 }));
    },

    mappedStates(state) {
      return state.regions
        .filter(s => s.countryName == 'United States')
        .map(s => ({ label: s.name, code: s.abbreviation }));
    },

    mappedProvince(state) {
      return state.regions
        .filter(s => s.countryName == 'Canada')
        .map(s => ({ label: s.name, code: s.abbreviation }));
    },

    readTripDetails(state) {
      return state.tripDetails;
    },

    readTripDetailsAgain(state) {
      return state.tripDetails;
    },

    readQuoteNo(state) {
      return state.quoteNo;
    },

    readFilters(state) {
      return state.filters;
    },

    readSportsFilters(state) {
      return state.sportsFilters;
    },

    readBenefits(state) {
      return state.benefits;
    },

    stepsCompleted(state) {
      return state.stepsCount >= TOTAL_STEPS;
    },

    comparedQuotes({ quotes, cardsForCompare }) {
      if(quotes.data.createSingleTripQuote){
        return quotes.data.createSingleTripQuote.productQuotes.filter(q =>
          cardsForCompare.includes(q.productCode)
        );
      }
      if(quotes.data.quote){
        return quotes.data.quote.productQuotes.filter(q =>
          cardsForCompare.includes(q.productCode)
        );
      }
    },
  },

  mutations: {
    setCountries(state, payload) {
      state.countries = payload;
    },

    setRegions(state, payload) {
      state.regions = payload;
    },

    setTripDetails(state, payload) {
      state.tripDetails = {
        ...state.tripDetails,
        ...payload,
      };
    },

    setQuoteNo(state, payload) {
      state.quoteNo = payload;
    },

    setTripDetailsAgain(state, payload) {
      state.tripDetails = payload;
    },

    setErrors(state, payload) {
      state.errors = payload;
    },

    setOffers(state, payload) {
      state.quotes = payload;
    },

    setSelectedCardID(state, payload) {
      state.selectedCardID = payload;
    },

    setCardsForCompare(state, payload) {
      state.cardsForCompare = payload;
    },

    toggleLoading(state, payload) {
      state.loadingQuotes = payload;
    },

    getFilters(state, payload) {
      state.filters = payload;
    },

    getSportsFilters(state, payload) {
      state.sportsFilters = payload;
    },

    getBenefits(state, payload) {
      state.benefits = payload;
    },

    incrementStep(state) {
      state.stepsCount++;
    },

    removeCompareQuote(state, payload) {
      state.cardsForCompare = state.cardsForCompare.filter(c => !payload.includes(c));
    }
  },

  actions: {
    async getCountries({ commit }) {
      const res = await axios('/api/countries');
      const {
        data: { countries, regions },
      } = res.data;

      commit('setCountries', countries);
      commit('setRegions', regions);
    },

    async getFilters({ commit }) {
      const res = await axios('/api/filters');
      commit('getFilters', res.data.data);
    },

    async getSports({ commit }) {
      const res = await axios('/api/sports');
      commit('getSportsFilters', res.data.data);
    },

    async getBenefits({ commit }) {
      const res = await axios('/api/benefits');
      // console.log(res.data)
      commit('getBenefits', res.data.data);
    },

    async getQoutes({ commit, getters }) {
      try {
        var payload = {
          quote: getters.readTripDetails,
        };
        // console.log(payload)
        commit('toggleLoading', true);
        commit('setErrors', null);

        const req = await axios({
          url: '/api/qoutes',
          method: 'post',
          data: payload,
        });
        // console.log(req.data);
        commit('toggleLoading', false);
        commit('setOffers', req.data);
        commit('setQuoteNo', {quote: req.data.data.createSingleTripQuote.quoteNumber});
      } catch (err) {
        var error = err;
        // console.log(error.response); .data.createSingleTripQuote.quoteNumber
        commit('toggleLoading', false);
        commit('setErrors', error.response.data.errors);
      }
    },

    async getQoutesAgain({ commit, getters, state }) {
      try {

        commit('toggleLoading', true);
        commit('setErrors', null);
        commit('setTripDetailsAgain', null);

        const req = await axios({
          url: '/api/qoutes2',
          method: 'post',
          data: state.quoteNo,
        });
        // console.log(req.data.data.quote.tripDetails);
        commit('setErrors', null);
        
        commit('setOffers', req.data);
        commit('setTripDetails', req.data.data.quote.tripDetails);
        commit('toggleLoading', false);
        
      } catch (err) {
        var error = err;
        // console.log(error.response);
        commit('toggleLoading', false);
        commit('setErrors', error.response.data.errors);
        // commit('setErrors', null);
      }
    },

    getSelectedCardID({ commit }, payload) {
      commit('setSelectedCardID', payload);
    },

    getCardsForCompare({ commit }, payload) {
      commit('setCardsForCompare', payload);
    },
  },
});