<template>
  <div>
    <HeaderTop></HeaderTop>
    <section class="view-size">
      <!-- Pogress Bar -->
      <div>
        <ProgressBar width="75"></ProgressBar>
      </div>

      <!-- Central Block -->
      <div class="container central_block">
        <!-- Trip Cancellation Coverage Options -->
        <h2 class="d-flex align-items-center" style="gap: 5px">
          Would you like trip cancellation coverage?
          <img
            src="../assets/img/question-fill.svg"
            data-toggle="tooltip"
            data-placement="top"
            title="Please click Yes If you would like Trip Cancellation coverage. This benefit provides reimbursment for prepaid and non-refundable trip payments if a trip is cancelled for a covered season."
          />
        </h2>

        <div class="grid_container_2">
          <div
            class="btn-group btn-group-toggle grid_radioButtons grid_radioButtons_Text"
            data-toggle="buttons"
          >
            <label
              class="btn btn-secondary radio_btn radio_btn_txt picked"
              id="btn_yes"
            >
              <input
                type="radio"
                name="tripCancel"
                id="option1"
                value="yes"
                v-model="radioOptPicked"
                @click="divShow = true"
              />
              Yes
            </label>
            <label
              class="btn btn-secondary radio_btn radio_btn_txt"
              id="btn_no"
            >
              <input
                type="radio"
                name="tripCancel"
                id="option2"
                value="no"
                v-model="radioOptPicked"
                @click="divShow = false"
              />
              No
            </label>
          </div>
        </div>

        <!-- Amount of Trip Container -->
        <div v-if="radioOptPicked == 'yes'">
          <h2 class="d-flex align-items-center" style="gap: 5px">
            What is the total cost of your trip?
            <img
              src="../assets/img/question-fill.svg"
              data-toggle="tooltip"
              data-placement="top"
              title="Please enter your trip cost. You may insure all, some, or none of your prepaid and non-refundable trip expenses."
            />
          </h2>

          <form>
            <div class="form-row">
              <div class="col-12 col-sm-5">
                <div class="error" v-if="!$v.unFormatedAmount.required">
                  Field is required
                </div>
                <!-- <div class="error" v-if="!$v.unFormatedAmount.minValue">Must be atleast {{$v.unFormatedAmount.$params.minValue.min}}.</div> -->
                <input
                  type="text"
                  class="form-control manual_input_class_2"
                  placeholder=""
                  v-model="AmountTyped"
                  @input="amountEntered"
                  onkeypress="return /\d/.test(String.fromCharCode(((event||window.event).which||(event||window.event).which)));"
                />
              </div>
            </div>
          </form>
        </div>

        <!-- Navigation Panel -->
        <div
          class="d-flex flex-nowrap justify-content-between align-items-center mt-5 mb-3"
        >
          <ButtonMain variant="outline2" @btnClicked="prevStep()">
            Back
          </ButtonMain>

          <ButtonMain :variant="continueBtnStatus" @btnClicked="nextStep()">
            Continue
          </ButtonMain>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import HeaderTop from "../components/HeaderTop.vue";
import ProgressBar from "../components/ProgressBar.vue";
import ButtonMain from "../components/ButtonMain.vue";
import ButtonRadioText from "../components/ButtonRadioText.vue";
import { mapState } from "vuex";
import {
  required,
  between,
  minValue,
  requiredIf,
} from "vuelidate/lib/validators";

export default {
  components: { HeaderTop, ProgressBar, ButtonMain, ButtonRadioText },
  data() {
    const tripDetails = this.$store.state.tripDetails;

    return {
      radioOptPicked: "",
      AmountTyped: tripDetails.tripCost || null, // Getting user-input data
      unFormatedAmount: tripDetails.tripCost || null, //amount without comma-seperator
      userActivated: false,
      amt: [],
      btnDeactivate: true,
      continueBtnStatus: "",
    };
  },

  validations: {
    unFormatedAmount: {
      required: requiredIf(function () {
        return this.userActivated;
      }),
      minValue: minValue(100),
      between: between(100, 50000),
    },
  },

  // sorted rending hierchy problem
  beforeMount() {
    this.$nextTick(function () {
      if (this.AmountTyped) {
        this.AmountTyped = this.AmountTyped.toString()
          .replace(/\D/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    });
  },

  computed: {
    ...mapState(["tripDetails"]),
  },
  watch: {
    radioOptPicked(option) {
      if (option === "no") {
        this.AmountTyped = null;
        this.continueBtnStatus = "primary";
      }
      if (option === "yes") {
        // this.AmountTyped = null
        this.continueBtnStatus = "disabled";
        if (this.AmountTyped != null) {
          this.continueBtnStatus = "primary";
        }
      }
    },

    // function to add seprator in numbers
    AmountTyped(newValue) {
      if (newValue) {
        const result = newValue
          .replace(/\D/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.AmountTyped = result;
        this.unFormatedAmount = this.AmountTyped.replace(/\,/g, "");
        // Vue.nextTick(() => this.price = result);
      } else {
        this.unFormatedAmount = null;
      }
    },
  },
  mounted() {
    if (this.$store.state.tripDetails.tripCost > 0) {
      this.AmountTyped = this.tripDetails.tripCost;
      this.radioOptPicked = "yes";
      this.continueBtnStatus = "primary";
      // console.log("in 1st branch...")
      var btnGetAgain = document.getElementById("btn_yes");
      btnGetAgain.classList.add("active");
    } else if (this.$store.state.tripDetails.tripCost == 0) {
      this.radioOptPicked = "no";
      // console.log("in 3rd branch...")
      var btnGetAgain = document.getElementById("btn_no");
      btnGetAgain.classList.add("active");
    } else if (!this.$store.state.tripDetails.tripCost) {
      this.radioOptPicked = "yes";
      // console.log("in 2nd branch...")
      var btnGetAgain = document.getElementById("btn_yes");
      btnGetAgain.classList.add("active");
    }

    // this.radioOptPicked = 'yes';
    // var optionPicked = document.querySelector('.picked');
    // optionPicked.classList.add('active');

    // console.log(this.$store.state.tripDetails.tripCost)
    if (this.$store.state.tripDetails.tripCost) {
      this.btnDeactivate = false;
    }
    // this.continueBtnStatus = this.$store.state.tripDetails.tripCost ? "primary" : "disabled";
  },

  methods: {
    nextStep() {
      // this.$refs.datesErr = '';
      // if (
      //   !(
      //     (this.radioOptPicked == 'yes' && this.AmountTyped != '') ||
      //     this.radioOptPicked == 'no'
      //   )
      // ) {
      //   this.$refs.datesErr = 'Please select all fields';
      //   return;
      // }

      if (this.radioOptPicked == "yes") {
        // console.log("amount must be entered and user can not move forward")
        this.userActivated = true;
        // this.continueBtnStatus = 'disabled'
        // if(this.AmountTyped){

        // }
        let ammt = +this.unFormatedAmount;
        if (ammt >= 1) {
          this.continueBtnStatus = "primary";

          const totalAmount = this.AmountTyped
            ? parseInt(this.unFormatedAmount)
            : 0;
          const totalTravelers = this.$store.state.tripDetails.travelers.length;
          const amountPerTravler = parseInt(totalAmount / totalTravelers);
          this.$store.commit("setTripDetails", {
            tripCost: amountPerTravler,
          });

          this.$store.commit("incrementStep");

          this.$router.push({ name: "TripDetails6" });
        } else if (ammt == 0) {
          return;
        }
      } else if (this.radioOptPicked == "no") {
        // console.log("user can move forward without entering amount")

        const amount = this.AmountTyped ? parseInt(this.unFormatedAmount) : 0;
        this.$store.commit("setTripDetails", {
          tripCost: amount,
        });

        this.$store.commit("incrementStep");

        this.$router.push({ name: "TripDetails6" });
      }
    },

    prevStep() {
      if (this.radioOptPicked == "yes") {
        this.userActivated = true;

        let ammt = +this.unFormatedAmount;
        if (ammt >= 1) {
          this.continueBtnStatus = "primary";

          const amount = this.AmountTyped ? parseInt(this.unFormatedAmount) : 0;
          this.$store.commit("setTripDetails", {
            tripCost: amount,
          });

          this.$router.push({ name: "TripDetails4" });
        } else if (ammt == 0) {
          this.$router.push({ name: "TripDetails4" });
        }
      } else if (this.radioOptPicked == "no") {
        const amount = this.AmountTyped ? parseInt(this.unFormatedAmount) : 0;
        this.$store.commit("setTripDetails", {
          tripCost: amount,
        });

        this.$router.push({ name: "TripDetails4" });
      }
      // this.$router.push({ name: 'TripDetails4' });
    },

    amountEntered() {
      this.userActivated = true;
      let ammt = +this.AmountTyped;
      // console.log(ammt)
      if (ammt >= 1) {
        this.btnDeactivate = false;
        this.continueBtnStatus = "primary";
      } else if (ammt == 0) {
        this.btnDeactivate = true;
        this.continueBtnStatus = "disabled";
      }
    },
  },
};

// :isDisabled="!((this.radioOptPicked == 'yes' && !this.btnDeactivate) || this.radioOptPicked == 'no')"
</script>

<style scoped>
.view-size {
  width: 100%;
  min-height: 100vh;
  background-color: rgba(234, 234, 252, 1);
  padding: 1% 0;
}
.central_block {
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 24px;
  padding: 3%; /*this property was commented: Reason?*/
  margin: 50px auto;
}

@media only screen and (max-width: 600px) {
  .central_block {
    max-width: 92%;
  }
}

@media only screen and (min-width: 600px) {
  .central_block {
    max-width: 65%;
  }
}

.central_block h2 {
  font-family: "Inter", sans-serif;
  /* font-size: 24px; */
  font-weight: 600;
  line-height: 30px;
  color: rgba(26, 26, 81, 1);
  padding-top: 2%;
  padding-bottom: 3%;
}

.grid_container_2 {
  width: 100%;
  height: 100px;
}
.grid_radioButtons {
  display: grid;
  /* grid-template-columns: repeat(5, auto); */
  /* grid-row-gap: 7%; */
}

.radio_btn {
  font-family: "BasierCircle-Regular", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 60px;
  width: 55px;
  height: 55px;
  color: rgba(26, 26, 81, 1);
  background-color: #fafaff;
  border: 2px solid rgba(26, 26, 81, 0.07);
  border-radius: 15px 15px 15px 15px !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.radio_btn:hover {
  color: #fff !important;
  background-color: rgba(110, 110, 223, 1) !important;
  border-color: rgba(110, 110, 223, 1) !important;
  box-shadow: none;
}
.radio_btn.active {
  color: #fff !important;
  background-color: rgba(110, 110, 223, 1) !important;
  border-color: rgba(110, 110, 223, 1) !important;
  box-shadow: none;
}
/* .radio_btn:active {
  color: #fff !important;
  background-color: rgba(110, 110, 223, 1) !important;
  border-color: rgba(110, 110, 223, 1) !important;
  box-shadow: none !important;
}
.radio_btn:target {
  color: #fff !important;
  background-color: rgba(110, 110, 223, 1) !important;
  border-color: rgba(110, 110, 223, 1) !important;
  box-shadow: none !important;
} */
/* .btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff !important;
  background-color: rgba(110, 110, 223, 1) !important;
  border-color: rgba(110, 110, 223, 1) !important;
  box-shadow: none !important;
}
.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  color: #fff !important;
  background-color: rgba(110, 110, 223, 1) !important;
  border-color: rgba(110, 110, 223, 1) !important;
  box-shadow: none !important;
}
.btn-secondary:not(:disabled):not(.disabled).active[data-v-5d5aaeb2],
.btn-secondary[data-v-5d5aaeb2]:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle[data-v-5d5aaeb2] {
  color: rgba(26, 26, 81, 1);
  background-color: #fafaff;
  border: 2px solid rgba(26, 26, 81, 0.07);
  box-shadow: none !important;
} */
/* .radio_btn:not(:disabled):not(.disabled).active:focus, .radio_btn:not(:disabled):not(.disabled):active:focus, .show > .radio_btn.dropdown-toggle:focus {
        box-shadow: 0 0 0 .2rem rgba(130,138,145,.5);
    }
    .radio_btn:not(:disabled):not(.disabled).active, .radio_btn:not(:disabled):not(.disabled):active, .show > .radio_btn.dropdown-toggle {
        color: #fff;
        background-color: rgba(110, 110, 223, 1);
        border-color: rgba(110, 110, 223, 1);
        box-shadow: none;
    } */

.radio_btn_txt {
  /* max-width: 125px;
        width: 125px; */
  padding: 10px 20px;
  min-width: 125px;
}

.manual_input_class_2 {
  border: 2px solid rgba(26, 26, 81, 0.07);
  border-radius: 10px;
  padding: 10px 10px 10px 40px;
  height: 55px;

  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  color: rgba(26, 26, 81, 1);

  background: rgba(250, 250, 255, 1) url("../assets/img/cost_dollar_icon.svg")
    left 0.75rem center/30px 18px no-repeat;
}

.manual_input_class_2:focus,
.manual_input_class_2:active,
.manual_input_class_2:hover {
  box-shadow: none !important;
  border: 2px solid rgba(26, 26, 81, 0.07);
  background-color: rgba(250, 250, 255, 1) !important;
}

@media only screen and (max-width: 600px) {
  .central_block {
    padding: 4% 6%;
  }
  .central_block h2 {
    font-size: 18px;
  }
  .form-group {
    margin-bottom: 2rem;
  }
  .form-group label {
    font-size: 16px;
  }
  .grid_container {
    width: 100%;
    height: 230px;
  }
  .grid_radioButtons {
    grid-template-columns: repeat(4, auto);
    grid-row-gap: 10%;
    grid-column-gap: 5%;
  }
  .radio_btn {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
  }
  .grid_radioButtons_Text {
    grid-template-columns: repeat(2, 120px) !important;
  } /* value changed from 135 to 120*/
  .grid_radioButtons_Text2 {
    grid-template-columns: repeat(1, 250px) !important;
    grid-row-gap: 10% !important;
  }
  .radio_btn_txt2 {
    max-width: 210px;
    width: 210px !important;
  }
  .manual_input_class {
    font-size: 16px;
  }
  .manual_input_class_3 {
    font-size: 16px;
  }
  .manual_select_class {
    font-size: 16px;
  }
}

@media only screen and (min-width: 600px) {
  .central_block {
    padding: 4% 4%;
  }
  .central_block h2 {
    font-size: 24px;
  }
  .form-group {
    margin-bottom: 3.5rem;
  }
  .form-group label {
    font-size: 24px;
  }
  .grid_container {
    width: 75%;
    height: 180px;
  }
  .grid_radioButtons {
    grid-template-columns: repeat(5, 50px);
    grid-row-gap: 25%;
    grid-column-gap: 2%;
  }
  .radio_btn {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
  }
  .grid_radioButtons_Text {
    grid-template-columns: repeat(2, 130px) !important;
  } /* value changed from 145 to 130*/
  .grid_radioButtons_Text2 {
    grid-template-columns: repeat(1, 250px) !important;
    grid-row-gap: 10% !important;
  }
  .radio_btn_txt2 {
    max-width: 210px;
    width: 210px !important;
  }
  .manual_input_class {
    font-size: 18px;
  }
  .manual_input_class_3 {
    font-size: 18px;
  }
  .manual_select_class {
    font-size: 18px;
  }
}

@media only screen and (min-width: 768px) {
  .grid_radioButtons_Text2 {
    grid-template-columns: repeat(1, 250px) !important;
    grid-row-gap: 10% !important;
  }
  .radio_btn_txt2 {
    max-width: 230px;
    width: 230px !important;
  }
}

@media only screen and (min-width: 992px) {
  .grid_radioButtons_Text2 {
    grid-template-columns: repeat(2, 250px) !important;
    grid-row-gap: 0% !important;
  }
  .radio_btn_txt2 {
    max-width: 230px;
    width: 230px !important;
  }
}

@media only screen and (min-width: 1200px) {
}
</style>
