<template>
  <div>
    <label class="btn btn-secondary radio_btn radio_btn_txt2">
      <input type="radio" name="options" id="option1" /> {{ btnText }}
    </label>
  </div>
</template>

<script>
export default {
  props: ['btnText'],
};
</script>

<style scoped>
.radio_btn {
  font-family: 'BasierCircle-Regular', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 60px;
  width: 55px;
  height: 55px;
  color: rgba(26, 26, 81, 1);
  background-color: #fafaff;
  border: 2px solid rgba(26, 26, 81, 0.07);
  border-radius: 15px 15px 15px 15px !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.radio_btn:hover {
  color: #fff;
  background-color: rgba(110, 110, 223, 1);
  border-color: rgba(110, 110, 223, 1);
  box-shadow: none;
}
.radio_btn:active {
  color: #fff;
  background-color: rgba(110, 110, 223, 1);
  border-color: rgba(110, 110, 223, 1);
  box-shadow: none;
}
.radio_btn:not(:disabled):not(.disabled).active:focus,
.radio_btn:not(:disabled):not(.disabled):active:focus,
.show > .radio_btn.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.radio_btn:not(:disabled):not(.disabled).active,
.radio_btn:not(:disabled):not(.disabled):active,
.show > .radio_btn.dropdown-toggle {
  color: #fff;
  background-color: rgba(110, 110, 223, 1);
  border-color: rgba(110, 110, 223, 1);
  box-shadow: none;
}

.radio_btn_txt2 {
  /* max-width: 230px;
        width: 230px !important; */
  padding: 10px 20px !important;
}
@media only screen and (max-width: 600px) {
  .radio_btn {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
  }
  .radio_btn_txt2 {
    max-width: 210px;
    width: 210px !important;
  }
}

@media only screen and (min-width: 600px) {
  .radio_btn {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
  }
  .radio_btn_txt2 {
    max-width: 210px;
    width: 210px !important;
  }
}

@media only screen and (min-width: 768px) {
  .radio_btn_txt2 {
    max-width: 230px;
    width: 230px !important;
  }
}

@media only screen and (min-width: 992px) {
  .radio_btn_txt2 {
    max-width: 230px;
    width: 230px !important;
  }
}

@media only screen and (min-width: 1200px) {
}
</style>