<template>
  <div>
    <div class="container" :style="customStyle">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Container',
  props: ['margin', 'borderRadius', 'padding'],
  data() {
    return {
      customStyle: {
        margin: this.margin,
        borderRadius: this.borderRadius,
        padding: this.padding,
      },
    };
  },
};
</script>

<style scoped>
.container {
  background-color: #fff;
  border: 1px solid #fff;
  /* border-radius: 24px; */
  /* padding: 1.5rem; */
  /* margin: 20px auto;   changed from 50px to 20px */
}
</style>