import { render, staticRenderFns } from "./Quotes2.vue?vue&type=template&id=503f59b8&scoped=true&"
import script from "./Quotes2.vue?vue&type=script&lang=js&"
export * from "./Quotes2.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "503f59b8",
  null
  
)

export default component.exports