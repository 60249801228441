<template>
  <div v-show=filterShow>
    <Container :margin="containerStyle.margin" :borderRadius="containerStyle.borderRadius" :padding="containerStyle.padding">
        <div class="d-flex flex-nowrap justify-content-between" style="gap: 5px">
          <p class="message">
            We understand travelers are concerned about the Covid-19 pandemic. Cancellation and medical benefits may still be available for travelers who contract the virus or are quarantined. For other Covid-related concerns, such as border closures or travel restrictions, we recommend Cancel For Any Reason. If you are searching for a policy for Covid-19, please apply the Coronavirus Pandemic search filter to show policies that may provide coverage.
          </p>
          <button class="close" @click="closeFilter"><i class="fas fa-times"></i></button>
        </div>
        <button class="filter_button" @click="applyFilter">Apply the Coronavirus Pandemic search filter</button>
    </Container>
  </div>
</template>

<script>
import Container from '../components/Container.vue';
import ButtonMain from '../components/ButtonMain.vue';
import { mapActions } from 'vuex';

export default {
  name: 'CoronaFilter',
  components: {
    Container,
    ButtonMain,
  },
  data() {
    return {
      containerStyle: {
        margin: '20px auto',
        borderRadius: '20px',
        padding: '1.0rem 1.5rem',
      },
      filterShow: true,
    };
  },

  methods: {
    ...mapActions(["getQoutes"]),

    applyFilter() {
      var arrFilter = this.$store.state.tripDetails.filters.filter((filterItem) => {
        return filterItem.code != 'coronavirus_pandemic'
      })
      arrFilter.push({ code:'coronavirus_pandemic' })

      this.$store.commit('setTripDetails', {
          filters: arrFilter,
      });
      this.getQoutes().then(res => {
        this.$router.push({ path: `/quotes/${this.$store.state.quoteNo.quote}` });
      })
    },

    closeFilter() {
      this.filterShow = false
    },
  },

}
</script>

<style lang="scss" scoped>
.message {
  font-family: 'BasierCircle-Regular', sans-serif;
  font-size: 15px;
  font-weight: 300;
  color: rgba(26, 26, 81, 1);
  text-align: left;
  padding-bottom: 10px;
}

.filter_button {
  background-color: #fff;
  border-radius: 25px;
  border: 1px solid rgba(110, 110, 223, 1);
  padding: 10px 25px;
  cursor: pointer;

  font-family: 'DM Sans', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: rgba(110, 110, 223, 1);
  // width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  // width: 100%;
}

.filter_button:hover,
.filter_button:active,
.filter_button:focus {
  opacity: 0.8;
}

.close {
  background: none;
  outline: none;
  border: none;
  height: 25px;
}

.close i {
  font-weight: 600;
  color: rgba(26, 26, 81, 1);
}
</style>