<template>
  <div>
    
    <!-- Search Parameters being displayed in Subheader -->
    <div id="searchParameters" class="d-inline d-lg-flex flex-wrap justify-content-between align-items-center" style="gap: 15px;">
        <h2 class="my-4">My Search</h2>
        <hr class="d-none d-lg-flex">
        <div class="my-3">
            <h4>Traveler Age</h4>
            <p v-for="(item, index) in (this.$store.state.tripDetails.travelers)" :key="index">
                <span v-if="index==($store.state.tripDetails.travelers.length-1)">{{ item.age }} </span>
                <span v-else>{{ item.age }}, </span>
            </p>
        </div>
        <hr class="d-none d-lg-flex">
        <div class="my-3">
             <h4>Travel Dates</h4>
              <p v-if="this.$store.state.tripDetails.returnDate">
                  {{ this.deptDate }} - {{ this.returnDate }}
              </p>
        </div>
        <hr class="d-none d-lg-flex">
        <div class="my-3">
              <h4>Trip Cost</h4>
              <p>$ {{ (this.$store.state.tripDetails.tripCost * $store.state.tripDetails.travelers.length ).toLocaleString() }}</p>
        </div>
        <hr class="d-none d-lg-flex">
        <div class="my-3">
              <h4>Deposit Date</h4>
              <p>{{ this.depositDate }}</p>
        </div>

        <div class="d-flex flex-column ml-0 ml-lg-3 my-4" style="gap:5px">
          <!-- !!! Disabled for now, use later: DO NOT DELETE -->
            <div class="d-flex justify-content-center">
                <button class="emailResults " data-toggle="modal" data-target="#searchEmail">
                      Email Search Results
                </button>
            </div>
            <div class="d-flex justify-content-center">
                <button  data-toggle="modal" data-target="#searchInput">Edit Search</button>
            </div>
            <!-- For screens < 992px -->
            <!-- <div class="d-flex d-lg-none flex-wrap justify-content-end align-items-center" style="gap: 9px">
                <ButtonMain variant="filter">
                  <img
                    class="filter-icon"
                    src="../assets/img/SVGs/filter_icon.svg"
                  />
                  Filter
                </ButtonMain>
            </div> -->
        </div>
    </div>
    <!-- Modal for Edit Search Criteria -->
    <ModalSearchCriteria></ModalSearchCriteria>

  </div>
</template>

<script>

import Container from '../components/Container.vue';
import ButtonMain from '../components/ButtonMain.vue';
import { Calendar, DatePicker } from 'v-calendar';
import { FunctionalCalendar } from 'vue-functional-calendar';
import vSelect from 'vue-select';
import ModalSearchCriteria from '../components/ModalSearchCriteria.vue'


export default {
  name: 'SearchCriteria',
 
  components: {
    Container,
    ButtonMain,
    Calendar,
    DatePicker,
    FunctionalCalendar,
    vSelect,
    ModalSearchCriteria,
  },
  data() {
    const tripDetails = this.$store.state.tripDetails;

    return {
      deptDate    : dayjs(tripDetails.departureDate).format('MM/DD/YYYY'),
      returnDate  : dayjs(tripDetails.returnDate).format('MM/DD/YYYY'),
      depositDate : dayjs(tripDetails.depositDate).format('MM/DD/YYYY'),

     
    }
  },

  updated() {
    this.$nextTick(function () {
    this.deptDate     = dayjs(this.$store.state.tripDetails.departureDate).format('MM/DD/YYYY'),
    this.returnDate   = dayjs(this.$store.state.tripDetails.returnDate).format('MM/DD/YYYY'),
    this.depositDate  = dayjs(this.$store.state.tripDetails.depositDate).format('MM/DD/YYYY')
    })
  },
}
</script>

<style scoped>

.subheading-title h1 {
  font-family: 'BasierCircle-Regular', sans-serif;
  font-weight: 600;
  color: rgba(26, 26, 81, 1);
}
#searchParameters {
  /* border: 1px solid rgba(110, 110, 223, 1); */
  border-radius: 25px;
  padding: 5px 20px;
}
#searchParameters h2 {
  font-family: 'BasierCircle-Regular', sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: rgba(26, 26, 81, 1);
  margin-bottom: 0;
}
#searchParameters h4 {
  font-family: 'BasierCircle-Regular', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: rgba(26, 26, 81, 1);
  margin-bottom: 0;
}
#searchParameters p, #searchParameters span {
  font-family: 'BasierCircle-Regular', sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: rgba(26, 26, 81, 1);
  margin-bottom: 0;
  display: inline;
}
#searchParameters hr {
  border: 1px solid grey;
  height: 48px;
}
#searchParameters button {
  background-color: #fff;
  border-radius: 25px;
  border: 1px solid rgba(110, 110, 223, 1);
  padding: 10px 25px;
  cursor: pointer;

  font-family: 'DM Sans', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: rgba(110, 110, 223, 1);
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

#searchParameters button:hover,
#searchParameters button:active,
#searchParameters button:focus {
  opacity: 0.8;
}

.emailResults {
  font-family: 'BasierCircle-Regular', sans-serif;
  font-size: 16px;
  font-weight: 500;
  min-width: max-content;
  padding: 5px 20px;
  border-radius: 50px;
  width: 100%;

  color: rgba(110, 110, 223, 1);
  background-color: rgba(234, 234, 252, 1);
  border: 1px solid rgba(234, 234, 252, 1);

  display: flex;
  align-items: center;
  justify-content: center;
}
.emailResults:hover,
.emailResults:active,
.emailResults:focus {
  color: rgba(110, 110, 223, 1);
  background-color: rgba(234, 234, 252, 0.75);
  border: 1px solid rgba(234, 234, 252, 1);
  box-shadow: none !important;
}
</style>