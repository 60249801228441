<template>
  <div>
    <HeaderTop></HeaderTop>
    <section class="view-size">
      <div>
        <ProgressBar width="15"></ProgressBar>
      </div>
      <div class="central_block">
        <div class="container">
          <h2 class="d-flex align-items-center" style="gap: 5px">
            What are your trip dates?
            <img
              src="../assets/img/question-fill.svg"
              data-toggle="tooltip"
              data-placement="top"
              title="Please enter starting date of your trip and last date of your trip. Starting date should be the date you leave your home. Last date should be the date you return home."
            />
          </h2>

          <div style="max-width: 850px" class="d-none d-lg-flex">
            <functional-calendar
              :is-date-range="true"
              :is-multiple="true"
              :calendars-count="2"
              :date-format="'mm/dd/yyyy'"
              :sundayStart="true"
              v-model="calendarData"
              @choseDay="noDays"
              :disabledDates="['beforeToday']"
            >
            </functional-calendar>
          </div>

          <!-- Errors displaying for larger screen -->
          <!-- <div>
            <div class="error" v-if="!$v.startDate.required">Please enter starting date of your trip</div>
            <div class="error" v-if="!$v.endDate.required">Please enter ending date of your trip</div>
          </div> -->
          <div>
            <div class="error" v-if="!$v.calendarData.dateRange.start.required">
              Please enter starting date of your trip
            </div>
            <div class="error" v-if="!$v.calendarData.dateRange.end.required">
              Please enter ending date of your trip
            </div>
          </div>

          <!-- Calenders for smaller screen: less than 992px -->

          <div
            class="d-flex d-lg-none flex-wrap justify-content-between align-items-center my-4"
          >
            <div class="py-4 sizingEditSearch">
              <functional-calendar
                :is-modal="true"
                :is-date-picker="true"
                v-model="calendarData1"
                placeholder="Edit Departure Date"
                :sundayStart="true"
                :date-format="'mm/dd/yyyy'"
                :disabledDates="['beforeToday']"
                :isAutoCloseable="true"
                @choseDay="noDays"
              >
              </functional-calendar>
            </div>
            <div class="py-4 sizingEditSearch">
              <functional-calendar
                :is-modal="true"
                :is-date-picker="true"
                v-model="calendarData2"
                placeholder="Edit Return Date"
                :sundayStart="true"
                :date-format="'mm/dd/yyyy'"
                :disabledDates="['beforeToday']"
                :isAutoCloseable="true"
                @choseDay="noDays"
              >
              </functional-calendar>
            </div>
          </div>

          <div>
            <div class="error" v-if="!$v.calendarData1.selectedDate.required">
              Please enter starting date of your trip
            </div>
            <div class="error" v-if="!$v.calendarData2.selectedDate.required">
              Please enter ending date of your trip
            </div>
          </div>

          <!-- Displaying Selected Dates -->
          <div
            class="summary my-3 d-none d-lg-block"
            v-if="calendarData['dateRange']['start']"
          >
            <div
              class="d-flex justify-content-start align-items-center"
              style="gap: 10px"
            >
              <h4><em>Trip Dates: </em></h4>
              <p>{{ calendarData["dateRange"]["start"] }}</p>
              <p v-if="calendarData['dateRange']['start']">-</p>
              <p>{{ calendarData["dateRange"]["end"] }}</p>
            </div>
          </div>

          <!-- Displaying Selected Dates: small screen -->
          <div
            class="summary-sm my-3 d-block d-lg-none"
            v-if="calendarData1.selectedDate"
          >
            <div class="d-block" style="gap: 10px">
              <h4><em>Trip Dates: </em></h4>
              <p>Departure Date: {{ calendarData1.selectedDate }}</p>
              <p>Return Date: {{ calendarData2.selectedDate }}</p>
            </div>
          </div>

          <!-- Navigation Buttons -->
          <div
            class="d-flex flex-nowrap justify-content-between align-items-center mt-5 mb-3"
          >
            <ButtonMain variant="outline2" @btnClicked="prevStep()">
              Back
            </ButtonMain>

            <ButtonMain :variant="continueBtnStatus" @btnClicked="nextStep()">
              Continue
            </ButtonMain>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import HeaderTop from "../components/HeaderTop.vue";
import ProgressBar from "../components/ProgressBar.vue";
import ButtonMain from "../components/ButtonMain.vue";
import { FunctionalCalendar } from "vue-functional-calendar";
import VRangeSelector from "vuelendar/components/vl-range-selector";
import VDaySelector from "vuelendar/components/vl-day-selector";
import { required, maxLength, requiredIf } from "vuelidate/lib/validators";

export default {
  components: {
    HeaderTop,
    ProgressBar,
    ButtonMain,
    FunctionalCalendar,
    VRangeSelector,
    VDaySelector,
  },
  data() {
    const tripDetails = this.$store.state.tripDetails;
    const startDate = tripDetails.departureDate || "";
    const endDate = tripDetails.returnDate || "";

    const startDateSplit = startDate ? startDate.split("-") : [];
    const endDateSplit = endDate ? endDate.split("-") : [];

    const dateRange = {
      start: startDate
        ? `${startDateSplit[1]}/${startDateSplit[2]}/${startDateSplit[0]}`
        : "",
      end: endDate
        ? `${endDateSplit[1]}/${endDateSplit[2]}/${endDateSplit[0]}`
        : "",
    };

    return {
      calendarData: {
        dateRange,
      },
      calendarData1: {},
      calendarData2: {},
      startDate: null, // starting date formatted
      endDate: null, // ending date formatted
      userActivated: false,
      userActivated2: false,
      continueBtnStatus: "",
    };
  },

  validations: {
    calendarData: {
      dateRange: {
        start: {
          required: requiredIf(function () {
            return this.userActivated;
          }),
        },
        end: {
          required: requiredIf(function () {
            return this.userActivated;
          }),
        },
      },
    },
    calendarData1: {
      selectedDate: {
        required: requiredIf(function () {
          return this.userActivated2;
        }),
      },
    },
    calendarData2: {
      selectedDate: {
        required: requiredIf(function () {
          return this.userActivated2;
        }),
      },
    },
  },

  mounted() {
    var element = document.querySelector(".vfc-popover-container");
    element.classList.add("extraClass");

    this.continueBtnStatus = this.$store.state.tripDetails.returnDate
      ? "primary"
      : "disabled";

    // ------Populating startDate and endDate on loading...
    if (this.calendarData["dateRange"]["start"]) {
      // console.log("in the up loop")
      this.startDate = dayjs(this.calendarData.dateRange.start).format(
        "YYYY-MM-DD"
      );
      this.endDate = dayjs(this.calendarData.dateRange.end).format(
        "YYYY-MM-DD"
      );
    } else if (this.calendarData1.selectedDate) {
      // console.log("in the down loop")
      this.startDate = dayjs(this.calendarData1.selectedDate).format(
        "YYYY-MM-DD"
      );
      this.endDate = dayjs(this.calendarData2.selectedDate).format(
        "YYYY-MM-DD"
      );
    }

    // const tripDetails = this.$store.state.tripDetails;
    // const startDate = tripDetails.departureDate || '';
    // const endDate = tripDetails.returnDate || '';
    // const startDateSplit = startDate ? startDate.split('-') : [];
    // const endDateSplit = endDate ? endDate.split('-') : [];
    // this.calendarData1 = {
    //   selectedDate: startDate ? `${startDateSplit[1]}/${startDateSplit[2]}/${startDateSplit[0]}` : '',
    // };

    // this.calendarData2 = {
    //   selectedDate: endDate ? `${endDateSplit[1]}/${endDateSplit[2]}/${endDateSplit[0]}` : '',
    // };
  },

  methods: {
    noDays(e) {
      if (window.innerWidth >= 992) {
        // convert days format for data from large screen calender
        if (this.calendarData["dateRange"]["start"]) {
          this.startDate = dayjs(this.calendarData.dateRange.start).format(
            "YYYY-MM-DD"
          );
          this.endDate = dayjs(this.calendarData.dateRange.end).format(
            "YYYY-MM-DD"
          );
        }

        // if-check for calenders displayed on large screen:
        if (this.calendarData["dateRange"]["end"] != "") {
          this.continueBtnStatus = "primary";
        } else {
          this.continueBtnStatus = "disabled";
        }
      } else {
        // convert days format for data from small screen calender
        if (this.calendarData1.selectedDate) {
          this.startDate = dayjs(this.calendarData1.selectedDate).format(
            "YYYY-MM-DD"
          );
          this.endDate = dayjs(this.calendarData2.selectedDate).format(
            "YYYY-MM-DD"
          );
        }

        // if-check for calenders displayed on small screen:
        if (this.calendarData2.selectedDate != null) {
          this.continueBtnStatus = "primary";
        } else {
          this.continueBtnStatus = "disabled";
        }
      }
    },

    nextStep() {
      // for proceeding for screen size large
      if (window.innerWidth >= 992) {
        this.userActivated = true;
        this.userActivated2 = false;

        if (this.calendarData["dateRange"]["end"] == "") {
          return;
        } else {
          this.$store.commit("setTripDetails", {
            departureDate: this.startDate,
            returnDate: this.endDate,
            // quoteType: 'SINGLE_TRIP',
          });

          this.$store.commit("incrementStep");

          this.$router.push({ name: "TripDetails3" });
        }
      }
      // proceeding for screen size small
      else {
        this.userActivated2 = true;
        this.userActivated = false;

        if (this.calendarData2.selectedDate == null) {
          return;
        } else {
          this.$store.commit("setTripDetails", {
            departureDate: this.startDate,
            returnDate: this.endDate,
            // quoteType: 'SINGLE_TRIP',
          });

          this.$store.commit("incrementStep");

          this.$router.push({ name: "TripDetails3" });
        }
      }
    },

    prevStep() {
      this.$store.commit("setTripDetails", {
        departureDate: this.startDate,
        returnDate: this.endDate,
        // quoteType: 'SINGLE_TRIP',
      });
      this.$router.push({ name: "TripDetails1" });
    },
  },
};
// :isDisabled="!(this.calendarData['dateRange']['end'] || calendarData2.selectedDate)"
</script>

<style>
/* src="vuelendar/scss/vuelendar.scss" lang="scss" */
.view-size {
  width: 100%;
  min-height: 100vh;
  background-color: rgba(234, 234, 252, 1);
  padding: 1% 0;
}
.central_block {
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 24px;
  padding: 3%; /*this property was commented: Reason?*/
  margin: 50px auto;
}

.central_block p {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;

  color: rgba(26, 26, 81, 1);
  cursor: pointer;
}

.summary h4 {
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 22px;
  color: rgba(26, 26, 81, 1);
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 0;
}

.summary p {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  color: rgba(26, 26, 81, 1);
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 0;
}

.summary-sm h4 {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  color: rgba(26, 26, 81, 1);
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 0;
}

.summary-sm p {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: rgba(26, 26, 81, 1);
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 0;
}

@media only screen and (max-width: 600px) {
  .central_block {
    max-width: 92%;
  }
}

@media only screen and (min-width: 600px) {
  .central_block {
    max-width: 65%;
  }
}

.central_block h2 {
  font-family: "Inter", sans-serif;
  /* font-size: 24px; */
  font-weight: 600;
  line-height: 30px;
  color: rgba(26, 26, 81, 1);
  padding-top: 2%;
  padding-bottom: 3%;
  cursor: pointer;
}

.manual_select_class {
  border: 2px solid rgba(26, 26, 81, 0.07);
  border-radius: 10px;
  padding: 15px 15px;
  height: 60px;

  font-family: "Inter", sans-serif;
  /* font-size: 18px; */
  font-weight: 400;
  line-height: 24px;
  color: rgba(26, 26, 81, 1);

  background: rgba(250, 250, 255, 1) url("../assets/img/search_icon.svg") right
    0.75rem center/30px 18px no-repeat;
}

.manual_select_class:focus,
.manual_select_class:active,
.manual_select_class:hover {
  box-shadow: none !important;
  border: 2px solid rgba(26, 26, 81, 0.07);
}

@media only screen and (max-width: 600px) {
  .central_block {
    padding: 4% 6%;
  }
  .central_block h2 {
    font-size: 18px;
  }
  .form-group {
    margin-bottom: 2rem;
  }
  .age_div {
    margin-bottom: 1.5rem;
  }
  .form-group label {
    font-size: 16px;
  }
  .grid_container {
    width: 100%;
    height: 230px;
  }
  .grid_radioButtons {
    grid-template-columns: repeat(4, auto);
    grid-row-gap: 10%;
    grid-column-gap: 5%;
  }
  .radio_btn {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
  }
  .grid_radioButtons_Text {
    grid-template-columns: repeat(2, 135px) !important;
  }
  .grid_radioButtons_Text2 {
    grid-template-columns: repeat(1, 250px) !important;
    grid-row-gap: 10% !important;
  }
  .radio_btn_txt2 {
    max-width: 210px;
    width: 210px !important;
  }
  .manual_input_class {
    font-size: 16px;
  }
  .manual_input_class_3 {
    font-size: 16px;
  }
  .manual_select_class {
    font-size: 16px;
  }
}

@media only screen and (min-width: 600px) {
  .central_block {
    padding: 4% 4%;
  }
  .central_block h2 {
    font-size: 24px;
  }
  .form-group {
    margin-bottom: 3.5rem;
  }
  .age_div {
    margin-bottom: 2rem;
  }
  .form-group label {
    font-size: 24px;
  }
  .grid_container {
    width: 75%;
    height: 180px;
  }
  .grid_radioButtons {
    grid-template-columns: repeat(5, 50px);
    grid-row-gap: 25%;
    grid-column-gap: 7%;
  }
  .radio_btn {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
  }
  .grid_radioButtons_Text {
    grid-template-columns: repeat(2, 146px) !important;
  }
  .grid_radioButtons_Text2 {
    grid-template-columns: repeat(1, 250px) !important;
    grid-row-gap: 10% !important;
  }
  .radio_btn_txt2 {
    max-width: 210px;
    width: 210px !important;
  }
  .manual_input_class {
    font-size: 18px;
  }
  .manual_input_class_3 {
    font-size: 18px;
  }
  .manual_select_class {
    font-size: 18px;
  }
}

@media only screen and (min-width: 768px) {
  .grid_radioButtons_Text2 {
    grid-template-columns: repeat(1, 250px) !important;
    grid-row-gap: 10% !important;
  }
  .radio_btn_txt2 {
    max-width: 230px;
    width: 230px !important;
  }
}

@media only screen and (min-width: 992px) {
  .grid_radioButtons_Text2 {
    grid-template-columns: repeat(2, 250px) !important;
    grid-row-gap: 0% !important;
  }
  .radio_btn_txt2 {
    max-width: 230px;
    width: 230px !important;
  }
}

@media only screen and (min-width: 1200px) {
}
/* .tooltip > .tooltip-inner {
        background: green !important;
    } */

.vfc-main-container {
  box-shadow: none !important;
}
.vfc-calendars {
  justify-content: space-between;
  gap: 10px;
}
.vfc-calendar {
  border: 1px solid rgba(232, 232, 232, 1);
  border-radius: 20px;
  max-width: 400px;
}
.vfc-top-date {
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 700;
}
.vfc-dayNames span {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: rgba(51, 204, 155, 1);
}
.vfc-week .vfc-day span.vfc-span-day.vfc-today {
  background-color: rgb(195, 195, 255);
  color: #ffffff;
  border-radius: 8px;
}
.vfc-week .vfc-day span.vfc-span-day.vfc-hover:hover,
.vfc-week .vfc-day span.vfc-span-day.vfc-hovered {
  background-color: rgba(110, 110, 223, 0.7);
  z-index: 100;
  border-radius: 8px;
}
.vfc-week .vfc-day span.vfc-span-day.vfc-marked {
  background-color: rgba(110, 110, 223, 0.01); /*tab color*/
}
.vfc-week .vfc-day span.vfc-span-day.vfc-marked::before {
  background-color: rgba(110, 110, 223, 0.6); /*scroll color */
}
.vfc-week .vfc-day .vfc-base-start,
.vfc-week .vfc-day .vfc-base-end {
  background: rgba(110, 110, 223, 0.6); /* should be same as scroll color*/
}
.vfc-week .vfc-day span.vfc-span-day.vfc-marked.vfc-borderd,
.vfc-week .vfc-day span.vfc-span-day.vfc-marked.vfc-start-marked,
.vfc-week .vfc-day span.vfc-span-day.vfc-marked.vfc-end-marked {
  color: #ffffff;
  background-color: rgba(110, 110, 223, 1);
}

.btn_filter {
  background-color: #fff;
  border-radius: 50px;
  border: 1px solid rgba(110, 110, 223, 1);
  padding: 5px 20px;
  cursor: pointer;

  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  /* line-height: 24px; */
  color: rgba(110, 110, 223, 1);
  width: 100%;
  min-width: max-content;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.btn_filter:hover,
.btn_filter:active,
.btn_filter:focus {
  opacity: 0.8;
}

.extraClass {
  width: 100%;
}
</style>
