<template>
  <div>
    <Container
      :margin="containerStyle.margin"
      :borderRadius="containerStyle.borderRadius"
      :padding="containerStyle.padding"
    >
      <div class="checkboxes-left d-flex flex-column align-items-start">
        <!-- Heading: Sorting -->
        <div class="left-side-heading">
          <h1>Sort Results</h1>
        </div>
        <div>
          <!-- Ascending title sorting -->
          <div class="custom-control custom-radio py-2">
            <input
              type="radio"
              id="customRadio1"
              value="01"
              name="customRadio"
              class="custom-control-input custom_input"
              v-model="radioChk"
            />
            <label
              class="custom-control-label custom_label"
              for="customRadio1"
              @click="topSellers"
              >Top Sellers</label
            >
          </div>
          <!-- Descending title sorting -->
          <div class="custom-control custom-radio py-2">
            <input
              type="radio"
              id="customRadio2"
              value="02"
              name="customRadio"
              class="custom-control-input custom_input"
              v-model="radioChk"
            />
            <label
              class="custom-control-label custom_label"
              for="customRadio2"
              @click="insuranceProvider"
              >Insurance Provider</label
            >
          </div>
          <div class="custom-control custom-radio py-2">
            <input
              type="radio"
              id="customRadio3"
              value="03"
              name="customRadio"
              class="custom-control-input custom_input"
              v-model="radioChk"
            />
            <label
              class="custom-control-label custom_label"
              for="customRadio3"
              @click="sortPriceUp"
              >Price Low to High</label
            >
          </div>
          <div class="custom-control custom-radio py-2">
            <input
              type="radio"
              id="customRadio4"
              value="04"
              name="customRadio"
              class="custom-control-input custom_input"
              v-model="radioChk"
            />
            <label
              class="custom-control-label custom_label"
              for="customRadio4"
              @click="sortPriceDown"
              >Price High to Low</label
            >
          </div>
        </div>

        <hr class="partition my-4" />

        <!-- Heading: Filters -->
        <div class="left-side-heading">
          <h1>Filter Search</h1>
        </div>

        <div class="accordion" id="accordionExample">
          <!-- Card: Filters -->
          <div
            class="card"
            v-for="(item, index) in this.$store.state.filters.filterGroups"
            :key="index"
          >
            <div class="card_title_box" id="headingOne">
              <h2 class="mb-0">
                <button
                  class="btn btn-link btn-block text-left filters_card_btn_text"
                  type="button"
                  data-toggle="collapse"
                  :data-target="
                    '#filters' + item.name.replace(' ', '').replace(' ', '')
                  "
                  :aria-expanded="
                    item.name == 'Popular Filters' ? 'true' : 'false'
                  "
                  aria-controls="collapseOne"
                >
                  {{ item.name }}
                </button>
              </h2>
            </div>

            <div
              :id="'filters' + item.name.replace(' ', '').replace(' ', '')"
              class="collapse"
              :class="item.name == 'Popular Filters' ? 'show' : ''"
              aria-labelledby="headingOne"
              data-parent="#accordionExample"
            >
              <div class="card-body">
                <div
                  class="py-2"
                  v-for="(filter, idx) in item.filters"
                  :key="idx"
                >
                  <div v-if="filter.options == null">
                    <!-- if filter is sports&activities -->
                    <div v-if="filter.code == 'sports_and_activities'">
                      <div class="d-flex flex-nowrap" style="gap: 15px">
                        <div>
                          <input
                            type="checkbox"
                            class="custom-control-input custom_input"
                            :id="`${filter.code}`"
                            :value="`${filter.code}`"
                            v-model="checkedTags"
                            @change="tagz(filter.code)"
                          />
                          <label
                            class="custom-control-label custom_label"
                            :for="`${filter.code}`"
                          >
                            {{ filter.name }}
                          </label>
                        </div>
                        <div>
                          <img
                            src="../assets/img/question-fill.svg"
                            data-toggle="tooltip"
                            data-placement="auto"
                            data-html="true"
                            data-trigger="click hover"
                            data-fallbackPlacement="flip"
                            :title="filter.tooltip"
                            data-boundary="viewport"
                            class="tooltipSize"
                          />
                        </div>
                      </div>

                      <!-- Block to activate sports filters subcategiores: currently inactive -->
                      <div
                        v-if="true"
                        class="my-2 sportFilter"
                        v-show="checkedTags.includes(filter.code)"
                      >
                        <!-- Dropdown to collect sport subcategory  -->
                        <div class="mt-3 mb-5">
                          <treeselect
                            class="ts"
                            :options="sportsArr"
                            v-model="sports_filters_cat"
                            :multiple="true"
                          >
                            <div slot="value-label" slot-scope="{ node }">
                              {{ node.raw.customLabel }}
                            </div>
                          </treeselect>
                        </div>

                        <!-- Checkbox to collect type of sports -->
                        <div
                          v-if="sports_filters_cat.length > 0"
                          class="w-75 ml-auto mr-auto"
                        >
                          <div class="custom-control custom-checkbox-sp mt-3">
                            <input
                              type="checkbox"
                              id="customCheck101"
                              value="AMATEUR_SPORT_LEVEL"
                              class="custom-control-input"
                              v-model="sportsType"
                            />
                            <label
                              class="custom-control-label"
                              for="customCheck101"
                              >Amateur athletic event or competition</label
                            >
                          </div>
                          <div class="custom-control custom-checkbox-sp mb-3">
                            <input
                              type="checkbox"
                              id="customCheck102"
                              value="PROFESSIONAL_SPORT_LEVEL"
                              class="custom-control-input"
                              v-model="sportsType"
                            />
                            <label
                              class="custom-control-label"
                              for="customCheck102"
                              >Professional athletic event or competition</label
                            >
                          </div>

                          <div>
                            <button
                              class="applySelection"
                              @click="tagz(filter.code)"
                            >
                              Apply Selections
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Any other filter without any dropdown options -->
                    <div v-else class="d-flex" style="gap: 15px">
                      <div>
                        <input
                          type="checkbox"
                          class="custom-control-input custom_input"
                          :id="`${filter.code}`"
                          :value="`${filter.code}`"
                          v-model="checkedTags"
                          @change="tagz(filter.code)"
                        />
                        <label
                          class="custom-control-label custom_label"
                          :for="`${filter.code}`"
                        >
                          {{ filter.name }}
                        </label>
                      </div>
                      <div>
                        <img
                          src="../assets/img/question-fill.svg"
                          data-toggle="tooltip"
                          data-placement="auto"
                          data-html="true"
                          data-trigger="click hover"
                          data-fallbackPlacement="flip"
                          :title="filter.tooltip"
                          data-boundary="viewport"
                          class="tooltipSize"
                        />
                      </div>
                    </div>
                  </div>
                  <!-- Filters with dropdown options -->
                  <div v-if="filter.options != null">
                    <div class="d-flex flex-nowrap" style="gap: 15px">
                      <div>
                        <input
                          type="checkbox"
                          class="custom-control-input custom_input"
                          :id="`${filter.code}`"
                          :value="`${filter.code}`"
                          v-model="checkedTags"
                          @change="tagz(filter.code)"
                        />
                        <label
                          class="custom-control-label custom_label"
                          :for="`${filter.code}`"
                        >
                          {{ filter.name }}
                        </label>
                      </div>
                      <div>
                        <img
                          src="../assets/img/question-fill.svg"
                          data-toggle="tooltip"
                          data-placement="auto"
                          data-html="true"
                          data-trigger="click hover"
                          data-fallbackPlacement="flip"
                          :title="filter.tooltip"
                          data-boundary="viewport"
                          class="tooltipSize"
                        />
                      </div>
                    </div>
                    <!-- dropdown div -->
                    <div
                      class="my-2"
                      v-show="
                        filter.options != null &&
                        checkedTags.includes(filter.code)
                      "
                    >
                      <select
                        :id="`${filter.code}`"
                        class="custom-select"
                        @change="amount(filter.code, $event.target.value)"
                      >
                        <option selected>
                          Select Amount for {{ filter.name }}
                        </option>
                        <option
                          :value="`${option.amount}`"
                          v-for="(option, index) in filter.options"
                          :key="index"
                        >
                          {{ option.label }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr class="partition mt-5" />

        <!----- Clear All Filters -------->
        <div class="w-100 my-4">
          <ButtonMain variant="outline" @btnClicked="clearFilter">
            Clear Filters
          </ButtonMain>
        </div>
      </div>
    </Container>
  </div>
</template>

<script>
import Container from "../components/Container.vue";
import ButtonMain from "../components/ButtonMain.vue";
import { mapActions } from "vuex";
import { mapState } from "vuex";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  name: "FilterSearch",
  components: { Container, ButtonMain, vSelect, Treeselect },
  data() {
    return {
      containerStyle: {
        margin: "20px auto",
        borderRadius: "20px",
        padding: "1.5rem",
      },

      value: [],
      checkedTags: [], // v-model to store checked tags of checkboxes of filters
      cost: [],
      radioChk: "01", // v-model for sorting panel
      results: [], // variable being used in sorting titles
      sortedResults: [], // variable to track price sortings
      filterz: [],
      sports_filters_cat: [], // additional categories stored in array for sports filters
      sportsType: [], // addtional type of sports filter array
      sportsArr: [], // array to display select categories of sports type
    };
  },

  mounted() {
    // this.checkedTags = [];

    // this.$store.commit('setTripDetails', {
    //     filters: [],
    //     sortBy:"SALES_DESC"
    // });
    this.checkedTags = this.$store.state.tripDetails.filters.map((filter) => {
      return filter.code;
    });

    //populating sportsArr from this.sportsFilters.sports (vuex store) -for subcategories of sports
    if (this.sportsFilters.sports) {
      this.sportsFilters.sports.forEach((i) => {
        this.sportsArr.push({
          id: i.id.toString(),
          label: `${i.name}`,
          customLabel: `${i.name}`,
        });
      });
    }
  },

  computed: {
    ...mapState(["sportsFilters", "quotes"]),
    count() {
      // console.log(this.$store.state.tripDetails.filters.length)
      if (this.$store.state.tripDetails.filters) {
        return this.$store.state.tripDetails.filters.map((filterItem) => {
          return filterItem.code;
        });
      }
      return;
    },

    //filter to generate on loadup
    sportsFiltersz() {
      if (this.sportsFilters.sports) {
        this.sportsFilters.sports.forEach((i) => {
          this.sportsArr.push({
            id: i.id.toString(),
            label: `${i.name}`,
            customLabel: `${i.name}`,
          });
        });
      }
    },
  },
  watch: {
    count(newCount, oldCount) {
      this.checkedTags = newCount;
      // console.log(`${newCount}`)
    },

    "$store.state.quotes": function () {
      // console.log(this.$store.state.quotes.data.quote.tripDetails.filters)
      var arrSavedFilters = [];
      this.$store.state.quotes.data.quote.tripDetails.filters.forEach(
        (filter) => {
          if (filter.selectedAmount != null) {
            arrSavedFilters.push({
              code: filter.code,
              amount: +filter.selectedAmount,
            });
          } else {
            arrSavedFilters.push({ code: filter.code });
          }
        }
      );
      this.$store.commit("setTripDetails", {
        filters: arrSavedFilters,
      });
      // console.log("arr to commit", arrSavedFilters)
      this.checkedTags =
        this.$store.state.quotes.data.quote.tripDetails.filters.map(
          (filter) => {
            return filter.code;
          }
        );
    },

    "$store.state.tripDetails": function () {
      this.checkedTags = this.$store.state.tripDetails.filters.map((filter) => {
        return filter.code;
      });
    },
  },

  methods: {
    ...mapActions(["getQoutes"]),

    topSellers() {
      // console.log("filter: top Sellers")
      this.$store.commit("setTripDetails", {
        sortBy: "SALES_DESC",
      });

      this.getQoutes();
    },

    insuranceProvider() {
      this.$store.commit("setTripDetails", {
        sortBy: "CARRIER",
      });
      this.getQoutes();
    },

    sortPriceUp() {
      //sorting by api
      this.$store.commit("setTripDetails", {
        sortBy: "PRICE_ASC",
      });
      this.getQoutes();
    },

    sortPriceDown() {
      //sorting by api
      this.$store.commit("setTripDetails", {
        sortBy: "PRICE_DESC",
      });
      this.getQoutes();

      // sorting if data is fetched from createSingleQuote
      // if(this.$store.state.quotes.data.createSingleTripQuote){
      //   this.sortedResults = this.$store.state.quotes.data.createSingleTripQuote.productQuotes.sort((a, b) => {
      //     let fa = a.premium,
      //       fb = b.premium;
      //     if (fa < fb) {
      //       return -1;
      //     }
      //     if (fa > fb) {
      //       return 1;
      //     }
      //     return 0;
      //   })
      //   .reverse();
      // }
      // //sorting if data is fetched from only quote
      // if(this.$store.state.quotes.data.quote){
      //   this.sortedResults = this.$store.state.quotes.data.quote.productQuotes.sort((a, b) => {
      //     let fa = a.premium,
      //       fb = b.premium;
      //     if (fa < fb) {
      //       return -1;
      //     }
      //     if (fa > fb) {
      //       return 1;
      //     }
      //     return 0;
      //   })
      //   .reverse();
      // }
    },

    // method to select and unselect filters and display results with filters: currently inactive but needed
    searchTags2(e, f) {
      var arrFilter = {};
      var finalFilter = [];
      if (
        e == "emergency_medical" ||
        e == "medical_evac" ||
        e == "star_rating" ||
        e == "medical_deductible" ||
        e == "travel_delay" ||
        e == "life_accidental_death" ||
        e == "life_air_flight" ||
        e == "life_common_carrier"
      ) {
        //block to excute if 'IF' condition satisfied
        // console.log(e, f)
        arrFilter = { code: e, amount: +f };
        this.finalFilters.push(arrFilter);

        // console.log(this.finalFilters)
        this.finalFilters.forEach((objItem) => {
          if (objItem.code == e) {
            // console.log("in filter - repetition : ", e ,f)
            objItem.amount = +f;
            // console.log(objItem)
          }
        });
      } else {
        arrFilter = { code: e };
        this.finalFilters.push(arrFilter);
      }

      this.$store.commit("setTripDetails", {
        filters: this.finalFilters,
      });

      this.getQoutes();
    },

    tagz(code) {
      if (
        code == "emergency_medical" ||
        code == "medical_evac" ||
        code == "star_rating" ||
        code == "medical_deductible" ||
        code == "travel_delay" ||
        code == "life_accidental_death" ||
        code == "life_air_flight" ||
        code == "life_common_carrier"
      ) {
        if (this.checkedTags.includes(code)) {
          return;
        } else {
          var arrFilter = this.$store.state.tripDetails.filters.filter(
            (filterItem) => {
              return filterItem.code != code;
            }
          );
          this.$store.commit("setTripDetails", {
            filters: arrFilter,
          });
          this.getQoutes().then((res) => {
            this.$router.push({
              path: `/quotes/${this.$store.state.quoteNo.quote}`,
            });
          });
        }
      } else {
        var arrFilter = this.checkedTags.map((tag) => {
          if (tag == "emergency_medical") {
            return { code: tag, amount: +10000 };
          }
          if (tag == "medical_evac") {
            return { code: tag, amount: +50000 };
          }
          if (tag == "star_rating") {
            return { code: tag, amount: +1 };
          }
          if (tag == "medical_deductible") {
            return { code: tag, amount: +2500 };
          }
          if (tag == "travel_delay") {
            return { code: tag, amount: +200 };
          }
          if (tag == "life_accidental_death") {
            return { code: tag, amount: +10000 };
          }
          if (tag == "life_air_flight") {
            return { code: tag, amount: +50000 };
          }
          if (tag == "life_common_carrier") {
            return { code: tag, amount: +25000 };
          }
          if (tag == "sports_and_activities") {
            if (this.sports_filters_cat.length == 0) {
              return { code: tag };
            } else {
              if (this.sportsType.length == 0) {
                return {
                  code: tag,
                  criteria: [
                    {
                      itemType: "SPORT_IDS",
                      value: this.sports_filters_cat,
                    },
                  ],
                };
              } else if (this.sportsType.length == 1) {
                return {
                  code: tag,
                  criteria: [
                    {
                      itemType: "SPORT_IDS",
                      value: this.sports_filters_cat,
                    },
                    {
                      itemType: this.sportsType[0].toString(),
                      value: this.sports_filters_cat[0],
                    },
                  ],
                };
              } else if (this.sportsType.length == 2) {
                return {
                  code: tag,
                  criteria: [
                    {
                      itemType: "SPORT_IDS",
                      value: this.sports_filters_cat,
                    },
                    {
                      itemType: this.sportsType[0].toString(),
                      value: this.sports_filters_cat[0],
                    },
                    {
                      itemType: this.sportsType[1].toString(),
                      value: this.sports_filters_cat[1],
                    },
                  ],
                };
              }

              // return { code: tag,
              //         "criteria": [{
              //             "itemType": this.sportsType.toString(),
              //             "value": this.sports_filters_cat
              //           }]
              // }
            }
          } else {
            return { code: tag };
          }
        });

        this.$store.commit("setTripDetails", {
          filters: arrFilter,
        });
        this.getQoutes().then((res) => {
          this.$router.push({
            path: `/quotes/${this.$store.state.quoteNo.quote}`,
          });
        });
      }
    },

    amount(x, y) {
      // @change="searchTags2(option.amount)"
      // console.log(x,y)

      var arrFilter = this.$store.state.tripDetails.filters.filter(
        (filterItem) => {
          return filterItem.code != x;
        }
      );
      arrFilter.push({ code: x, amount: +y });

      this.$store.commit("setTripDetails", {
        filters: arrFilter,
      });
      this.getQoutes().then((res) => {
        this.$router.push({
          path: `/quotes/${this.$store.state.quoteNo.quote}`,
        });
      });
    },

    // method to clear any selected filters and display results without any filters
    clearFilter() {
      this.checkedTags = [];
      this.radioChk = "01";
      this.finalFilters = [];
      this.sportsType = [];
      this.sports_filters_cat = [];
      this.$store.commit("setTripDetails", {
        filters: [],
        sortBy: "SALES_DESC",
      });
      this.getQoutes().then((res) => {
        this.$router.push({
          path: `/quotes/${this.$store.state.quoteNo.quote}`,
        });
      });
    },

    // :title="filter.tooltip"
  },
};
</script>

<style scoped>
@font-face {
  font-family: "BasierCircle-Regular";
  src: url("../assets/fonts/basiercircle-regular.otf") format("woff2"),
    url("../assets/fonts/BasierCircle-Regular.ttf") format("woff"),
    url("../assets/fonts/BasierCircle-Regular.woff") format("otf"),
    url("../assets/fonts/BasierCircle-Regular.woff2") format("ttf");
  font-weight: normal;
  font-style: normal;
}
.checkboxes-left {
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 20px;
  /* max-width: 1170px; */
  margin-top: 0px;
  height: auto;
}
.left-side-heading {
  font-family: "BasierCircle-Regular", sans-serif;
  color: rgba(26, 26, 81, 1);
}
.chbx hr {
  border: 2px solid rgba(250, 250, 255, 1);
  width: 100%;
}
.partition {
  border: 1px solid rgba(110, 110, 223, 1);
  width: 100%;
}
.custom-checkbox .custom-control-label::before {
  border-radius: 1.25rem;
}
.checkbox-box {
  padding-top: 10px;
  padding-bottom: 10px;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}
.custom_input:checked {
  color: #fff;
  border-color: rgba(51, 204, 155, 1);
  background-color: rgba(51, 204, 155, 1);
  box-shadow: none !important;
}
.custom_input:focus {
  color: #fff;
  border-color: rgba(51, 204, 155, 1);
  background-color: rgba(51, 204, 155, 1);
  box-shadow: none !important;
}
.custom_label {
  font-family: "BasierCircle-Regular", sans-serif;
  /* font-size: 16px; */
  font-weight: 400;
  color: rgba(26, 26, 81, 1);
  padding-left: 15px;
}
.custom_label::before {
  position: absolute;
  top: 0.25rem;
  left: -1rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: rgba(51, 204, 155, 1) solid 1px;
}
.custom-control-input:checked ~ .custom_label::before {
  color: #fff;
  border-color: rgba(51, 204, 155, 1);
  background-color: rgba(51, 204, 155, 1);
  box-shadow: none;
}
.custom_label::after {
  background-image: none !important;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
  background-image: none;
}

.card_title_box {
  padding: 0.75rem 0rem;
  margin-bottom: 0;
  background-color: none !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.filters_card_btn_text {
  font-family: "BasierCircle-Regular", sans-serif;
  /* font-size: 18px; */
  font-weight: 700;
  color: rgba(26, 26, 81, 1);
  padding-left: 0px;
}
.filters_card_btn_text:hover,
.card_btn_text:focus,
.card_btn_text:active {
  text-decoration: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.btn:hover,
.btn:focus,
.btn:active {
  text-decoration: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.filters_card_btn_text {
  position: relative;
}

.filters_card_btn_text::after {
  content: "\f";
  /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z'/%3E%3C/svg%3E"); */
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z'/%3E%3C/svg%3E");
  color: #333;
  top: 7px;
  right: 0px;
  position: absolute;
  /* font-family: "FontAwesome"; */
  width: 25px;
}

.filters_card_btn_text[aria-expanded="true"]::after {
  content: "\f";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 10.828l-4.95 4.95-1.414-1.414L12 8l6.364 6.364-1.414 1.414z'/%3E%3C/svg%3E");
  width: 25px;
}

.accordion {
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .filters_card_btn_text {
    font-size: 16px;
  }
  .custom_label {
    font-size: 14px;
  }
}
@media only screen and (min-width: 600px) {
  .filters_card_btn_text {
    font-size: 16px;
  }
  .custom_label {
    font-size: 14px;
  }
}
@media only screen and (min-width: 992px) {
  .filters_card_btn_text {
    font-size: 18px;
  }
  .custom_label {
    font-size: 16px;
  }
}
.tooltipSize {
  width: 14px;
  height: 14px;
}

.applySelection {
  background-color: #fff;
  border-radius: 25px;
  border: 1px solid rgba(110, 110, 223, 1);
  padding: 10px 25px;
  cursor: pointer;
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: rgba(110, 110, 223, 1);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.applySelection:hover {
  background-color: rgba(110, 110, 223, 1);
  color: #fff;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0%;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: rgba(51, 204, 155, 1);
  background-color: rgba(51, 204, 155, 1);
}

.ts .vue-treeselect__multi-value-item {
  background: #e3f2fd !important;
  border: 1px solid transparent;
  color: #43e503 !important;
  font-size: 12px;
}
</style>
