<template>
  <div>
    <button
      :class="`btn_${variant}`"
      @click="$emit('btnClicked')"
      :disabled="isDisabled"
    >
      <slot></slot>
    </button>
  </div>
</template>

<script>
export default {
  name: 'Button',
  // props: {
  //     type: String,
  //     required: true,
  // }
  props: ['variant', 'isDisabled'],
};
</script>

<style scoped>
@font-face {
  font-family: 'BasierCircle-Regular';
  src: url('../assets/fonts/basiercircle-regular.otf') format('woff2'),
    url('../assets/fonts/BasierCircle-Regular.ttf') format('woff'),
    url('../assets/fonts/BasierCircle-Regular.woff') format('otf'),
    url('../assets/fonts/BasierCircle-Regular.woff2') format('ttf');
  font-weight: normal;
  font-style: normal;
}
a,
a:hover {
  text-decoration: none !important;
}

.btn_primary {
  background-color: rgba(110, 110, 223, 1);
  border-radius: 25px;
  border: none;
  padding: 10px 25px;
  cursor: pointer;

  font-family: 'DM Sans', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: rgba(255, 255, 255, 1);
  width: 100%;
}

.btn_primary:hover,
.btn_primary:active,
.btn_primary:focus {
  opacity: 0.8;
}

.btn_outline {
  background-color: #fff;
  border-radius: 25px;
  border: 1px solid rgba(110, 110, 223, 1);
  padding: 10px 5px;
  cursor: pointer;

  font-family: 'DM Sans', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: rgba(110, 110, 223, 1);
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}
.btn_outline:hover,
.btn_outline:active,
.btn_outline:focus {
  opacity: 0.8;
  text-decoration: none !important;
}

.btn_outline2 {
  background-color: #fff;
  border-radius: 25px;
  border: 1px solid rgba(110, 110, 223, 1);
  padding: 10px 30px;
  cursor: pointer;

  font-family: 'DM Sans', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: rgba(110, 110, 223, 1);
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}
.btn_outline2:hover,
.btn_outline2:active,
.btn_outline2:focus {
  opacity: 0.8;
}

.btn_secondary {
  font-family: 'BasierCircle-Regular', sans-serif;
  font-size: 16px;
  font-weight: 500;
  min-width: max-content;
  padding: 5px 20px;
  border-radius: 50px;
  width: 100%;

  color: rgba(110, 110, 223, 1);
  background-color: rgba(234, 234, 252, 1);
  border: 1px solid rgba(234, 234, 252, 1);

  display: flex;
  align-items: center;
  justify-content: center;
}
.btn_secondary:hover,
.btn_secondary:active,
.btn_secondary:focus {
  color: rgba(110, 110, 223, 1);
  background-color: rgba(234, 234, 252, 0.75);
  border: 1px solid rgba(234, 234, 252, 1);
  box-shadow: none !important;
}

.btn_secondary2 {
  font-family: 'BasierCircle-Regular', sans-serif;
  font-size: 16px;
  font-weight: 500;
  min-width: max-content;
  padding: 5px 20px;
  border-radius: 50px;

  color: rgba(110, 110, 223, 1);
  background-color: rgba(234, 234, 252, 1);
  border: 1px solid rgba(234, 234, 252, 1);

  display: flex;
  align-items: center;
  gap: 10px;
}
.btn_secondary2:hover,
.btn_secondary2:active,
.btn_secondary2:focus {
  color: rgba(110, 110, 223, 1);
  background-color: rgba(234, 234, 252, 0.75);
  border: 1px solid rgba(234, 234, 252, 1);
  box-shadow: none !important;
}

.btn_noOutline1 {
  font-family: 'BasierCircle-Regular', sans-serif;
  font-size: 16px;
  font-weight: 500;
  min-width: max-content;
  padding: 5px 20px;
  border-radius: 50px;

  color: rgba(110, 110, 223, 1);
  background-color: inherit;
  border: none;
}
.btn_noOutline1:hover,
.btn_noOutline1:active,
.btn_noOutline1:focus {
  color: rgba(110, 110, 223, 0.75);
  background: none !important;
  box-shadow: none !important;
}

.btn_noOutline2 {
  font-family: 'BasierCircle-Regular', sans-serif;
  font-size: 15px;
  font-weight: 400;
  min-width: max-content;
  padding: 6px 0px;

  color: rgba(26, 26, 81, 1);
  background-color: inherit;
  border: none;
}
.btn_noOutline2:hover,
.btn_noOutline2:active,
.btn_noOutline2:focus {
  color: rgba(26, 26, 81, 1);
  background-color: inherit;
  border: none;
  box-shadow: none !important;
}

.btn_compare {
  font-family: 'BasierCircle-Regular', sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: rgba(51, 204, 155, 1);
  min-width: max-content;

  border: none;
  border-radius: 50px;
  background-color: rgba(51, 204, 155, 0.25);
  padding: 5px 15px;
  width: 100%;
}
.btn_compare:hover,
.btn_compare:active,
.btn_compare:focus {
  color: rgba(51, 204, 155, 1);
  border: none;
  background-color: rgba(51, 204, 155, 0.15);
  box-shadow: none !important;
}

.btn_compareUnselect {
  font-family: 'BasierCircle-Regular', sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: rgba(51, 204, 155, 1);
  min-width: max-content;
  border: 2px solid rgba(51, 204, 155, 1);
  border-radius: 50px;
  /* background-color: rgba(51, 204, 155, 0.25); */
  background-color: #fff;
  padding: 5px 15px;
  width: 100%;
}
.btn_compareUnselect:hover,
.btn_compareUnselect:active,
.btn_compareUnselect:focus {
  color: rgba(51, 204, 155, 0.6);
  border: 2px solid rgba(51, 204, 155, 1);
  background-color:#fff;
  box-shadow: none !important;
}

.btn_filter {
  background-color: #fff;
  border-radius: 50px;
  border: 1px solid rgba(110, 110, 223, 1);
  padding: 5px 20px;
  cursor: pointer;

  font-family: 'DM Sans', sans-serif;
  font-size: 16px;
  font-weight: 400;
  /* line-height: 24px; */
  color: rgba(110, 110, 223, 1);
  width: 100%;
  min-width: max-content;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.btn_filter:hover,
.btn_filter:active,
.btn_filter:focus {
  opacity: 0.8;
}

.btn_back {
  font-family: 'BasierCircle-Regular', sans-serif;
  font-size: 16px;
  font-weight: 500;
  min-width: max-content;
  padding: 5px 20px;
  border-radius: 50px;

  color: rgba(26, 26, 81, 1);
  background-color: rgba(234, 234, 252, 1);
  border: none;
}
.btn_back :hover,
.btn_back :active,
.btn_back :focus {
  color: rgba(110, 110, 223, 0.75);
  background: none !important;
  box-shadow: none !important;
}

.btn_disabled {
  background-color: rgb(171, 171, 175);
  border-radius: 25px;
  border: none;
  padding: 10px 25px;
  cursor: pointer;

  font-family: 'DM Sans', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: rgba(255, 255, 255, 1);
  width: 100%;
}

.btn_disabled:hover,
.btn_disabled:active,
.btn_disabled:focus {
  opacity: 1;
}

button:disabled,
button[disabled] {
  background-color: rgb(171, 171, 175);
  border-radius: 25px;
  border: none;
  padding: 10px 25px;
  cursor: pointer;

  font-family: 'DM Sans', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: rgba(255, 255, 255, 1);
  width: 100%;
}
button:disabled:hover,
button[disabled]:hover {
  opacity: 0.8;
  background-color: rgb(171, 171, 175) !important;
  color: rgba(255, 255, 255, 1) !important;
}
</style>