<template>
  <div>
    <HeaderTop></HeaderTop>
    <Background>
      <div class="container">
        <!-- Back Button -->
        <div class="d-flex justify-content-start align-items-center w-100 my-4">
          <ButtonMain variant="back" @btnClicked="prevPage">
            <img src="../assets/img/SVGs/back_to_search_icon_btn.svg" />
          </ButtonMain>
        </div>

        <section>
          <div
            class="compare_page white_box_container mx-auto px-4 table-responsive"
          >
            <table class="table table-responsive">
              <!-- Header with Logos and Titles -->
              <thead>
                <tr>
                  <th class="d-table-cell"></th>
                  <th
                    class="compare-col bg-white"
                    v-for="quote in comparedQuotes"
                    :key="quote.productCode"
                    style="border-bottom: 2px solid #dee2e6"
                  >
                    <div class="header_offer">
                      <div
                        class="d-flex justify-content-center align-items-center my-3"
                        style="height: 35px"
                      >
                        <img
                          style="width: 130px"
                          :src="quote.provider.mediumLogoUrl"
                        />
                      </div>
                      <div style="min-height: 55px">
                        <h2>
                          {{ quote.provider.name }}: {{ quote.productName }}
                        </h2>
                      </div>
                      <div class="my-4">
                        <h3>
                          ${{
                            quote.premium
                              .toFixed(2)
                              .toLocaleString()
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }}
                        </h3>
                      </div>
                      <div
                        class="certificate d-flex justify-content-center pt-4 pb-2"
                      >
                        <a
                          :href="quote.certificateUrl"
                          target="_blank"
                          download="proposed_file_name"
                          class="spc_certificate"
                          >View Certificate</a
                        >
                      </div>
                      <!-- <div>
                        <p>3% of total trip cost</p>
                      </div> -->
                      <div class="my-2 d-flex justify-content-center">
                        <a
                          :href="quote.purchaseUrl + `&aid=${aid}`"
                          target="_blank"
                          class="btn btn-outline-primary btn-custom-primary py-2 w-50"
                        >
                          Buy
                        </a>
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <!-- Rating row -->
                <tr>
                  <td class="d-table-cell">
                    <div
                      class="btn_offer_compare_left btn_compare_left_cat_heading d-flex flex-wrap justify-content-start align-items-center"
                      style="gap: 10px; min-height: 24px; background: none"
                    >
                      <!-- <img src="../assets/img/SVGs/green_exclaimation.svg" /> -->
                      <p class="font-weight-bold">Rating</p>
                    </div>
                  </td>
                  <td
                    style="vertical-align: middle"
                    v-for="quote in comparedQuotes"
                    :key="quote.productCode"
                  >
                    <div class="d-flex flex-nowrap justify-content-center">
                      <img
                        src="../assets/img/SVGs/rating_green_star.svg"
                        v-for="n in getRating(
                          quote.provider.averageOverallRating
                        )"
                        :key="n"
                      />
                    </div>
                    <div
                      class="btn_offer_compare btn_fix"
                      style="background: none; border: none"
                    >
                      <p class="reviewCount">
                        Total Reviews: {{ quote.provider.reviewsCount }}
                      </p>
                    </div>
                  </td>
                </tr>
                <!-- Benifit Group Row -->
                <tr v-for="benefit in benefits" :key="benefit.id">
                  <td class="d-table-cell">
                    <div
                      class="btn_offer_compare_left btn_fix btn_compare_left_cat_heading d-flex flex-wrap justify-content-start align-items-center"
                      style="gap: 10px; min-height: 24px; background: none"
                    >
                      <!-- <img
                        src="../assets/img/SVGs/green_exclaimation.svg"
                        v-if="!benefit.parent"
                      /> -->
                      <p
                        :class="[!benefit.parent && 'font-weight-bold']"
                        :style="[benefit.parent && { marginLeft: '20px' }]"
                      >
                        {{ benefit.name }}
                      </p>
                    </div>
                  </td>
                  <td
                    v-for="quote in comparedQuotes"
                    :key="quote.productCode"
                    :set="
                      (coverage = getCoverage(
                        quote.productCode,
                        getGroupName(benefit.parent),
                        benefit.name
                      ))
                    "
                  >
                    <template v-for="(item, index) in quote.coverages">
                      <Portal to="compare-modals" :key="index">
                        <div v-if="item.benefitName == benefit.name">
                          <MoreDetailsModal
                            :productName="quote.productName"
                            :provider="quote.provider"
                            :coverageText="item.coverageText"
                            :coverageLongText="item.coverageLongText"
                            :benefitName="item.benefitName"
                            :benefitGroupName="item.benefitGroupName"
                            :certificateUrl="quote.certificateUrl"
                            :code="
                              'exampleModal' +
                              `${benefit.id}` +
                              `${quote.productCode}`
                            "
                          >
                          </MoreDetailsModal>
                        </div>
                      </Portal>
                    </template>
                    <!-- Block for displaying Coverage Text -->
                    <div
                      class="btn_offer_compare btn_fix"
                      style="background: none; border: none"
                    >
                      <div
                        class="btn_compare_left_cat d-flex justify-content-start align-items-center"
                        style="gap: 10px; min-height: 24px"
                      >
                        <div>
                          <template v-if="!benefit.parent">
                            <p class="text-left">&nbsp;</p>
                          </template>
                          <template v-else>
                            <!-- <img
                              v-if="coverage && coverage.isCovered"
                              src="../assets/img/SVGs/compare_tick_mark.svg"
                            /> -->
                            <!-- <img
                              v-else
                              src="../assets/img/SVGs/compare_cross_mark.svg"
                            /> -->
                            <div
                              id="'#exampleModal'+benefit.id+quote.productCode"
                              class="coverageText text-left text-block"
                            >
                              {{
                                coverage ? coverage.coverageText : "No Coverage"
                              }}
                            </div>
                            <!-- <div class="text-left text-block" v-html="change(coverage)"></div> -->

                            <!-- Button for Modal -->
                            <div v-if="coverage">
                              <button
                                type="button"
                                class="btn btn-link modal_btn my-1"
                                data-toggle="modal"
                                :data-target="
                                  '#exampleModal' +
                                  benefit.id +
                                  quote.productCode
                                "
                              >
                                More Details
                              </button>
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="d-none d-lg-table-cell"></td>
                  <td
                    v-for="quote in comparedQuotes"
                    :key="quote.productCode"
                    class="buttons_borders"
                  >
                    <!-- Ending Buttons -->

                    <div class="my-2">
                      <button
                        type="button"
                        class="btn btn-outline-primary btn-custom-outline py-2"
                        @click="removeCompareQuote(quote.productCode)"
                      >
                        Remove
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
      </div>
    </Background>
    <PortalTarget name="compare-modals" multiple />
  </div>
</template>

<script>
import HeaderTop from "../components/HeaderTop.vue";
import Background from "../components/Background.vue";
import ButtonMain from "../components/ButtonMain.vue";
import { mapGetters, mapMutations, mapState } from "vuex";
import { AFFILIATE_ID } from "../app.config";
import MoreDetailsModal from "../components/MoreDetailsModal.vue";
import { Portal, PortalTarget } from "portal-vue";

export default {
  components: {
    HeaderTop,
    Background,
    ButtonMain,
    MoreDetailsModal,
    Portal,
    PortalTarget,
  },
  data() {
    return {
      aid: AFFILIATE_ID,
      benefits: [],
      windowSize: screen.width,
    };
  },

  computed: {
    ...mapState(["cardsForCompare"]),
    ...mapGetters(["comparedQuotes"]),
  },

  updated() {
    this.$nextTick(function () {
      this.inspect();
    });
  },

  async mounted() {
    // console.log(this.comparedQuotes)
    const names = this.comparedQuotes[0].coverages.map(
      (c) => c.benefitGroupName
    );

    const benefitGroups = {};
    const groupNames = [...new Set(names)];

    this.comparedQuotes.forEach((quote) => {
      for (const groupName of groupNames) {
        const benifits = benefitGroups[groupName] || [];

        benefitGroups[groupName] = [
          ...benifits,
          ...this.getCoverages(quote.productCode, groupName).map(
            (c) => c.benefitName
          ),
        ];

        benefitGroups[groupName] = [...new Set(benefitGroups[groupName])];
      }
    });

    for (const groupName in benefitGroups) {
      if (Object.hasOwnProperty.call(benefitGroups, groupName)) {
        const id = this.getId();

        this.benefits.push({
          id,
          name: groupName,
          parent: null,
        });

        const benefits = benefitGroups[groupName];

        for (const benefit of benefits) {
          this.benefits.push({
            id: this.getId(),
            name: benefit,
            parent: id,
          });
        }
      }
    }

    // cdn plugin to apply sticky header only on larger screens
    if (this.windowSize <= 991) {
      let elements = document.querySelectorAll("table");
      stickyThead.apply(elements, "destroy");
    } else if (this.windowSize >= 992) {
      let elements = document.querySelectorAll("table");
      stickyThead.apply(elements);
    }

    // setTimeout(() => {
    //   $('table').floatThead({
    //       // position: 'absolute',
    //       // scrollContainer: true
    //   });
    // }, 500);
  },

  methods: {
    inspect() {
      var text = document.querySelectorAll(".coverageText");

      text.forEach((item) => {
        // console.log(item)
        const myArr = item.innerHTML.split("\n");
        item.innerHTML = [];

        for (var i = 0; i < myArr.length; i++) {
          item.innerHTML += "<p>" + myArr[i] + "</p>";
        }
      });
    },

    ...mapMutations(["removeCompareQuote"]),
    getRating(rating) {
      return Math.round(rating);
    },
    getCoverages(productCode, groupName) {
      const quote = this.comparedQuotes.find(
        (q) => q.productCode === productCode
      );
      if (!quote) return [];

      return quote.coverages.filter((c) => c.benefitGroupName === groupName);
    },
    getCoverage(productCode, groupName, benefitName) {
      const quote = this.comparedQuotes.find(
        (q) => q.productCode === productCode
      );
      if (!quote) return {};

      return quote.coverages.find(
        (c) => c.benefitGroupName === groupName && c.benefitName === benefitName
      );
    },
    getGroupName(parentId) {
      return this.benefits.find((b) => b.id === parentId)?.name;
    },
    prevPage() {
      // this.removeCompareQuote(this.cardsForCompare);
      // this.$router.push({ name: 'Quotes4' });
      this.$router.push({ path: `/quotes/${this.$store.state.quoteNo.quote}` });
    },
    getId() {
      return +Math.random().toString().split(".").join("");
    },
  },
};
</script>

<style>
.subheading-title h1 {
  font-family: "BasierCircle-Regular", sans-serif;
  font-weight: 600;
  color: rgba(26, 26, 81, 1);
}

@font-face {
  font-family: "BasierCircle-Regular";
  src: url("../assets/fonts/basiercircle-regular.otf") format("woff2"),
    url("../assets/fonts/BasierCircle-Regular.ttf") format("woff"),
    url("../assets/fonts/BasierCircle-Regular.woff") format("otf"),
    url("../assets/fonts/BasierCircle-Regular.woff2") format("ttf"),
    url("../assets/fonts/BasierCircle-Bold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
.checkboxes-left {
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 5px;
  /* max-width: 1170px; */
  margin-top: 0px;
  height: 850px;
}
.left-side-heading {
  font-family: "BasierCircle-Regular", sans-serif;
  color: rgba(26, 26, 81, 1);
}
hr {
  border: 2px solid rgba(250, 250, 255, 1);
  /* width: 100%; */
}
.custom-checkbox .custom-control-label::before {
  border-radius: 0rem;
}
.checkbox-box {
  padding-top: 10px;
  padding-bottom: 10px;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}
.custom_input:checked {
  color: #fff;
  border-color: rgba(51, 204, 155, 1);
  background-color: rgba(51, 204, 155, 1);
  box-shadow: none !important;
}
.custom_input:focus {
  color: #fff;
  border-color: rgba(51, 204, 155, 1);
  background-color: rgba(51, 204, 155, 1);
  box-shadow: none !important;
}
.custom_label {
  font-family: "BasierCircle-Regular", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: rgba(26, 26, 81, 1);
}
.custom_label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: rgba(51, 204, 155, 1) solid 1px;
}
.custom-control-input:checked ~ .custom_label::before {
  color: #fff;
  border-color: rgba(51, 204, 155, 1);
  background-color: rgba(51, 204, 155, 1);
  box-shadow: none;
}
/* .custom_label::after {
  background-image:none !important;
} */

.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::before {
  border-color: #00ff34;
  background-color: #fff;
}
.left-side-heading h1 {
  font-family: "BasierCircle-Regular", sans-serif;
  font-weight: bold;
  color: rgba(26, 26, 81, 1);
}
tr:nth-child(even) {
  background-color: rgb(235, 235, 247);
}
tr:nth-child(odd) {
  background-color: #fff;
}
.btn_fix:hover,
.btn_fix:focus,
.btn_fix:active {
  color: rgba(26, 26, 81, 0.7) !important;
  border: 1px solid none !important;
  background-color: none !important;
  background: none;
}

.fontIncrease {
  font-size: 12px !important;
}
.table {
  font-family: "BasierCircle-Regular", sans-serif;
  border-collapse: separate;
  border-spacing: 15px 0px;
}

.modal_btn {
  font-size: 11px !important;
  text-decoration: underline;
  font-weight: 400;
  line-height: 16px;
  color: rgba(26, 26, 81, 1);
  padding-left: 0px;
}
.modal_btn:hover,
.modal_btn:active,
.modal_btn:focus {
  box-shadow: none;
}

.text-block {
  /* white-space: pre-wrap; */
  white-space: pre-line;
  /* word-break: break-all; */
  word-wrap: break-word;
  line-height: 22px;
}

.text-block p {
  line-height: 22px;
}

.reviewCount {
  font-family: "BasierCircle-Regular", sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: rgba(26, 26, 81, 1);
  padding-top: 10px;
  text-align: center;
}

/* .table-responsive {
  overflow: initial;
} */

td,
th {
  text-align: left;
  /* padding: 8px; */
  max-width: 350px;
  border-top: none;
}

.table td,
.table th {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  vertical-align: top;
  border-top: none;
  position: sticky;
  /* top: 0;
    z-index: 1000; */
}

.certificate a {
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: rgba(110, 110, 223, 1);
}
</style>
